(function () {
    'use strict';
    angular
        .module('supplyApp.common')
        .factory('Income', Income);

    Income.$inject = ['$resource', 'DateUtils'];

    function Income($resource, DateUtils) {
        var resourceUrl = 'api/incomes/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {method: 'GET'},
            'update': {method: 'PUT'},
            'save': {
                url: 'api/incomes',
                method: 'POST'
            },
            'getTotalIncomes': {
                url: 'api/incomes/totals/:year/:month',
                method: 'GET',
                isArray: true
            },
            'getSchoolIncomes': {
                url: 'api/incomes/totals/:schoolId/:year/:month',
                method: 'GET',
                isArray: true
            },
            'getIncomeWithDetails': {
                url: 'api/incomes/detail/:id',
                method: 'GET'
            }
        });
    }
})();
