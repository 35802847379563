(function() {
    'use strict';
    angular
        .module('supplyApp.common')
        .factory('LessonLog', LessonLog);

    LessonLog.$inject = ['$resource'];

    function LessonLog ($resource) {
        var resourceUrl =  'api/lesson-logs/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'all': { method: 'GET', isArray: true},
            'get': { method: 'GET' },
            'grades': {
                url: '/api/lesson-grades/:id',
                method: 'GET' },
            'updateGrades': {
                url: '/api/lesson-grades/:id',
                method:'PUT',
                params: {
                    id: '@id',
                }
            },
            'saveGrades': {
                url: '/api/lesson-grades/',
                method:'POST',
            },
            'initLog': {
                url: '/api/lesson-grades/lesson/:id/init',
                method: 'PUT',
                params: {
                    id: '@id',
                }
            }
        });
    }
})();
