(function() {
    'use strict';

    angular
        .module('supplyApp.common')
        .factory('GAService', GAService);

    GAService.$inject = ['$window'];

    function GAService ($window) {
        var initialized = !angular.isUndefined($window.gtag);

        return {
            trackPage: function(page_path) {
                if (initialized) {
                    $window.gtag('event', 'page_view', { page_path: page_path });
                }
            },
            trackEvent: function(event_name, parameters) {
                if (initialized) {
                    $window.gtag('event', event_name, parameters);
                }
            },
            grandConsent: function() {
                if (initialized) {
                    $window.gtag('consent', 'update', {
                        'ad_storage': 'granted',
                        'ad_user_data': 'granted',
                        'ad_personalization': 'granted',
                        'analytics_storage': 'granted'
                    });
                }
            },
        };
    }
    
})();
