(function() {
  "use strict";

  angular.module("supplyApp").directive("foodType", foodTypeDirective);

  function foodTypeDirective() {
    return {
      restrict: "E",
      replace: true,
      templateUrl: "app/ui/food-type/food-type.directive.html",
      scope: {
        foodType: "=",
        modifiable: "<",
        delete: "&"
      }
    };
  }
})();
