(function () {
    "use strict";

    angular
        .module("supplyApp")
        .controller("LessonClazzTableController", LessonClazzTableController);

    LessonClazzTableController.$inject = ["$scope", "$state", "$stateParams", "Dialogs", "Principal", "AlertService", "School", "Lesson", "previousState"];

    function LessonClazzTableController($scope, $state, $stateParams, Dialogs, Principal, AlertService, School, Lesson, previousState) {
        var vm = this;

        vm.lessonRows = [];
        vm.busy = false;
        vm.dirty = false;
        //vm.search = pagingParams.search ? JSON.parse(pagingParams.search) : {}
        vm.previousState = previousState;
        vm.currentSchoolId = null;

        vm.doSearch = doSearch;
        vm.resetSearch = resetSearch;

        init();

        $scope.$on('authenticationSuccess', function () {
            init();
        });

        $scope.$watch('vm.currentSchoolId', function(newVal) {
            if (newVal) {
                doSearch();
            }
        });

        function init() {
            vm.canManage = Principal.hasAnyAuthority(['ROLE_SCH_ADMIN', 'ROLE_ADMIN']);
            vm.isRegionUser = Principal.hasAnyAuthority(['ROLE_BASE_ACC','ROLE_BASE_ADMIN','ROLE_ADMIN']);
            if (vm.isRegionUser) {
                vm.currentSchoolId = $stateParams.schoolId ? parseInt($stateParams.schoolId) : null;
                vm.schools = School.all();
            } else {
                vm.currentSchoolId = Principal.current().school.id;
            }
        }

        function doSearch() {
            vm.busy = true;
            Lesson.clazzMap({schoolId: vm.currentSchoolId},
                onSuccess,
                onError
            );
        }

        function resetSearch() {
            doSearch();
        }

        function onSuccess(data, headers) {
            vm.lessonRows = data;
            vm.clazzes = [];
            if (vm.lessonRows && vm.lessonRows.length > 0) {
                angular.forEach(vm.lessonRows, function (row) {
                    angular.forEach(row.lessons, function (lesson) {
                        lesson.origin = lesson.checked;
                    });
                });
                angular.forEach(vm.lessonRows[0].lessons, function (l) {
                    vm.clazzes.push(l.name);
                });
            }
            vm.busy = false;
        }

        function onError(error) {
            AlertService.error(error.data.message);
            vm.busy = false;
        }

        vm.checkItem = function(lesson) {
            lesson.checked = !lesson.checked;
            vm.dirty = isDirty(vm.lessonRows);
        };

        vm.save = function() {
            Dialogs.warn("Измененные данные будут сохранены").then(function () {
                Lesson.updateClazzMap(filterModified(vm.lessonRows), function () {
                    $state.go('lesson-clazz-table', {schoolId: vm.currentSchoolId}, { reload: true });
                }, function (error) {
                    AlertService.error("Ошибка при сохранении данных: " + error.data.message);
                });
            });
        };

        function filterModified(lessonRows) {
            var result = [];
            angular.forEach(vm.lessonRows, function (row) {
                angular.forEach(row.lessons, function (lesson) {
                    if (lesson.origin !== lesson.checked) {
                        lesson.teacherId = row.teacher.teacherId;
                        lesson.subjectId = row.teacher.subjectId;
                        result.push(lesson);
                    }
                });
            });
            return result;
        }

        function isDirty(lessonRows) {
            var dirty = angular.forEach(vm.lessonRows, function (row) {
                angular.forEach(row.lessons, function (lesson) {
                    if (lesson.origin !== lesson.checked) {
                        return false;
                    }
                });
            });
            return dirty;
        }

    }
})();
