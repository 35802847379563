(function () {
    'use strict';

    angular
        .module('supplyApp.common')
        .service('CostCenterSettings', CostCenterSettings);

    CostCenterSettings.$inject = ['$resource'];

    function CostCenterSettings($resource) {
        var resourceUrl = 'api/settings/cost-center/:id';

        return $resource(resourceUrl, {}, {
            'getSettings': {
                method: 'GET',
                url: resourceUrl + '/:confKey'
            },
            'update': {
                method: 'PUT',
                params: {
                    id: '@id',
                }
            },
        });
    }
})();
