(function () {
    'use strict';

    angular
        .module('supplyApp')
        .controller('PupilController', PupilController);

    PupilController.$inject = ['$timeout', '$scope', '$rootScope', 'Modal', 'Nav', 'Pupil', 'School', 'Principal', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams'];

    function PupilController($timeout, $scope, $rootScope, Modal, Nav, Pupil, School, Principal, ParseLinks, AlertService, paginationConstants, pagingParams) {
        var vm = this;

        vm.pagingParams = pagingParams;

        vm.search = pagingParams.search ? JSON.parse(pagingParams.search) : {};
        vm.showFilter = !!(vm.search.name || vm.search.guid || vm.search.clazzes || vm.search.schoolId || vm.search.retired);

        vm.doSearch = doSearch;
        vm.resetSearch = resetSearch;

        $scope.$on('authenticationSuccess', function () {
            init();
        });

        init();

        function init() {
            vm.showSchool = Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_BASE_ACC']);
            vm.schools = [];
            doLoad();
        }

        function doLoad() {
            vm.searching = true;

            Pupil.query({
                page: pagingParams.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort(),
                search: vm.search
            }, onSuccess, onError);

            if (vm.showSchool) {
                vm.schools = School.all();
            }

        }

        function doSearch() {
            Nav.search(pagingParams, vm.search);
        }

        function resetSearch() {
            vm.search = {};
            vm.searching = false;
            doSearch();
        }

        function sort() {
            var result = [pagingParams.predicate + ',' + (pagingParams.ascending ? 'asc' : 'desc')];
            if (pagingParams.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.pupils = data;
            vm.page = pagingParams.page;
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }


        var unsubscribe = $rootScope.$on('supplyApp:pupilCreate', function(event, result) {
            $timeout(function (){
                Modal.pupilAdded(result);
            });
        });

        $scope.$on('$destroy', unsubscribe);
    }
})();
