(function () {
    'use strict';

    angular
        .module('supplyApp')
        .controller('AtdSchoolReportController', AtdSchoolReportController);

    AtdSchoolReportController.$inject = ['$scope', '$state', 'Principal', 'AlertService', 'AtdReportConfig', 'School', 'Report'];

    function AtdSchoolReportController($scope, $state, Principal, AlertService, AtdReportConfig, School, Report) {
        var vm = this;

        vm.userSchool = null;
        vm.selectSchool = false;
        vm.filter = {};
        vm.filterConfig = AtdReportConfig.filterConfig;
        vm.filterStr = function() {
            return encodeURIComponent(angular.toJson(vm.filter));
        };

        vm.search = search;

        init();

        function init() {
            vm.filter = AtdReportConfig.period;
            vm.schoolId = AtdReportConfig.school;
        }

        Principal.identity().then(function(account) {
            if (account.school) {
                School.get({id : account.school.id}, function(school) {
                    vm.userSchool = school;
                    AtdReportConfig.school = school.id;
                    vm.schoolId = school.id;
                });
            } else {
                vm.selectSchool = true;
                vm.schools = School.all();
            }
        });

        function search() {
            if (!vm.schoolId || (vm.filter.period === 'custom' && !vm.filter.fromDate)) {
                return;
            }
            Report.attendance({
                id: vm.schoolId,
                search: vm.filter,
            }, onSuccess, onError);
            function onSuccess(data, headers) {
                vm.report = data;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        vm.resetSearch = function() {
            vm.report = null;
            AtdReportConfig.reset();
            init();
        };

        $scope.$watch('vm.schoolId', function (newValue, oldValue) {
            if (newValue && newValue !== oldValue) {
                AtdReportConfig.school = newValue;
                search();
            } else {
                vm.report = null;
            }
        });
    }

})();
