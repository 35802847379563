(function () {
    'use strict';

    angular
        .module('supplyApp')
        .controller('ExportController', ExportController);

    ExportController.$inject = ['$scope', '$state', 'Export202SettingsService', 'AlertService', 'account', 'Offload'];

    function ExportController($scope, $state, Export202SettingsService, AlertService, account, Offload) {
        var vm = this;
        vm.selectedRegion = account.region;
        vm.openSettingsModal = Export202SettingsService.openModal;

        vm.download202 = function() {
            if (!vm.selectedRegion) return;
            window.location.href = 'api/offload/download/all/' + vm.selectedRegion.id;
        };

        vm.downloadNew202 = function() {
            if (!vm.selectedRegion) return;
            window.location.href = 'api/offload/download/new/' + vm.selectedRegion.id;
        };

        vm.offload202 = function() {
            if (!vm.selectedRegion) return;
            Offload.offloadAll({regionId:vm.selectedRegion.id}, onSuccess, onError);
        };

        vm.offloadNew202 = function() {
            if (!vm.selectedRegion) return;
            Offload.offloadNew({regionId:vm.selectedRegion.id}, onSuccess, onError);
        };

        function onSuccess() {
            AlertService.success("выполнено");
        }
        function onError() {
            AlertService.error("что-то пошло не так");
        }

    }

})();
