(function() {
    "use strict";

    angular
        .module("supplyApp")
        .directive("foodCost", costDirective);

    function costDirective() {
      return {
        restrict: "E",
        replace: true,
        templateUrl: "app/ui/food-type/cost.directive.html",
        scope: {
          typeCost: "=",
          modifiable: "<"
        },
        link: link
      };

      function link(scope) {
      }
    }
  })();
