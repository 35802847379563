(function() {
    'use strict';
    angular
        .module('supplyApp.common')
        .factory('Mng', Mng);

    Mng.$inject = ['$resource'];

    function Mng ($resource) {
        var resourceUrl =  'api/mng';

        return $resource(resourceUrl, {}, {
            'promote': {
                url: resourceUrl + '/region/:id/promote',
                method: 'PUT',
                params: {
                    id: '@id'
                }
            },
            'buildSchoolFinReport': {
                url: resourceUrl + '/school/:id/fin-report',
                method: 'PUT',
                params: {
                    id: '@id'
                }
            },
            'buildSchoolFinReportForAll': {
                url: resourceUrl + '/school/fin-reports',
                method: 'PUT'
            }
        });
    }
})();
