(function () {
    'use strict';

    angular
        .module('supplyApp.common')
        .directive('customDatePicker', datePicker);

    function datePicker() {
        return {
            restrict: 'E',
            scope: {
                placeholder: '@',
                dateModel: '=model',
                dateFormat: '@format',
                period: '='
            },
            templateUrl: "app/common/components/form/date-picker.html",
            link: linkFunk
        };

        function linkFunk(scope, element, attrs) {
            scope.isOpen = false;

            scope.openCalendar = function (e) {
                e.preventDefault();
                e.stopPropagation();

                scope.period = "custom";
                this.isOpen = true;
            };
        }
    };
})();
