(function() {
  "use strict";

  angular
    .module("supplyApp")
    .config(stateConfig);

  stateConfig.$inject = ["$stateProvider"];

  function stateConfig($stateProvider) {
    $stateProvider.state("foodTypes", {
      parent: "app",
      url: "/foodTypes",
      data: {
        authorities: [],
        pageTitle: "Типы питания"
      },
      views: {
        "content@": {
          templateUrl: "app/ui/food-type/food-types.html",
          controller: "FoodTypeController",
          controllerAs: "vm"
        }
      },
      resolve: {
        currentUser: ['Principal', function(Principal) {
            return Principal.identity();
        }]
      }
    })
    .state('foodTypes.delete', {
        parent: 'foodTypes',
        url: '/{id}/delete',
        data: {
            authorities: ['ROLE_SCH_ADMIN', 'ROLE_BASE_ADMIN', 'ROLE_ADMIN']
        },
        onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
            $uibModal.open({
                templateUrl: 'app/ui/food-type/delete-dialog.html',
                controller: 'FoodTypeDeleteController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: ['FoodType', function (FoodType) {
                        return FoodType.get({id: $stateParams.id});
                    }]
                }
            }).result.then(function () {
                $state.go('foodTypes', null, {reload: true});
            }, function () {
                $state.go('^');
            });
        }]
    });
  }
})();
