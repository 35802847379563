(function() {
    'use strict';
    angular
        .module('supplyApp.common')
        .factory('ExpensesLog', ExpensesLog);

    ExpensesLog.$inject = ['$resource', 'DateUtils'];

    function ExpensesLog ($resource, DateUtils) {
        var resourceUrl = "api/expenses/:id";

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'save': { method: "POST" },
            'update': { method: "PUT" },
            'joinPupils': {
                method: 'GET',
                isArray: true,
                url: 'api/expenses/joinPupils'
            }
        });
    }
})();
