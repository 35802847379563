(function() {
    'use strict';

    angular
        .module('supplyApp.common')
        .controller('UserPassgenController', UserPassgenController);

    UserPassgenController.$inject = ['$stateParams', 'User', 'PassGen', '$uibModalInstance', 'entity'];

    function UserPassgenController($stateParams, User, PassGen, $uibModalInstance, entity) {
        var vm = this;

        vm.changePassword = changePassword;
        vm.refresh = refresh;
        vm.user = entity;
        vm.error = null;
        vm.success = null;
        vm.password = null;

        vm.refresh();

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        function refresh() {
            vm.password = PassGen.next(5);
        }

        function changePassword () {
            if (vm.password !== null) {
                User.changePassword({login:vm.user.login}, vm.password, function () {
                    vm.error = null;
                    vm.success = 'OK';
                    $uibModalInstance.close(true);
                }, function (errorMsg) {
                    vm.success = null;
                    vm.error = 'ERROR';
                });
            }
        }
	}
})();
