(function () {
    'use strict';

    angular
        .module('supplyApp.common')
        .directive('toggleButton', toggleButton);

    function toggleButton() {
        return {
            restrict: 'AE',
            scope: {
                toggleClass: '@',
                targetSelector: '@',
                flipElement: '@',
                flipClass: '@'
            },
            link: function (scope, elem) {
                elem.bind('click', function () {
                    if (scope.flipElement) {
                        var element = $(scope.flipElement);
                        if (element.hasClass(scope.flipClass)) {
                            element.removeClass(scope.flipClass);
                        } else {
                            element.addClass(scope.flipClass);
                        }
                    }

                    $(scope.targetSelector).each(function () {
                        var element = $(this);
                        if (element.hasClass(scope.toggleClass)) {
                            element.removeClass(scope.toggleClass);
                        } else {
                            element.addClass(scope.toggleClass);
                        }
                    })
                });
            }
        }
    }
})();