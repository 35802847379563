(function() {
    'use strict';

    angular
        .module('supplyApp')
        .controller('LessonTeacherDialogController', LessonTeacherDialogController);

    LessonTeacherDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'Principal', 'TeacherLesson', 'School', 'entity'];

    function LessonTeacherDialogController($timeout, $scope, $stateParams, $uibModalInstance, Principal, TeacherLesson, School, entity) {
        var vm= this;

        vm.loading = false;
        vm.entity = entity;
        vm.clear = clear;
        vm.save = save;

        vm.schools = [];
        vm.subjects = [];
        vm.teachers = [];
        vm.editing = (entity.id !== null);

        init();

        function init() {
            vm.canSelectSchool = Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_BASE_ADMIN']);
            var userSchool = Principal.current().school;
            if (!vm.editing) {
                if (vm.canSelectSchool) {
                    vm.currentSchool = null;
                    vm.schools = School.all();
                } else {
                    vm.schools = [userSchool];
                }
                vm.currentSchool = userSchool;
            } else {
                vm.currentSchool = entity.teacher.school;
                vm.schools = [entity.teacher.school];
            }
        }

        $scope.$watch('vm.currentSchool', function(newVal) {
            if (newVal) {
                vm.loading = true;
                vm.teachers = School.teachers({id:newVal.id});
                vm.subjects = School.subjects({id:newVal.id});
                vm.loading = false;
            }
        });

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.loading = true;
            if (vm.entity.id !== null) {
                TeacherLesson.update({id:vm.entity.id}, vm.entity, onSaveSuccess, onSaveError);
            } else {
                TeacherLesson.save(vm.entity, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('supplyApp:lessonTeacherUpdate', result);
            $uibModalInstance.close(result);
            vm.loading = false;
        }

        function onSaveError () {
            vm.loading = false;
        }


    }
})();
