(function () {
    'use strict';

    angular
        .module('supplyApp')
        .service('СlazzDetailService', СlazzDetailService);

    СlazzDetailService.$inject = ['Modal', 'Dialogs'];

    function СlazzDetailService(Modal, Dialogs) {
        var service = {
            openPupilImportModal: openPupilImportModal,
            openClazzRetireModal: openClazzRetireModal,
        };
        return service;

        function openPupilImportModal(clazz) {
            return Modal.open(
                'app/ui/entity/clazz/pupils-import-modal.html',
                'ClazzPupilsImportController',
                clazz
            );
        }

        function openClazzRetireModal(clazz) {
            if (!clazz.retired) {
                return Dialogs.warn("Класс, а также все ученики этого класса, \
                    будут отмечены как выпускники и больше не будут доступны в спиках классов/учеников.");
            } else {
                return Dialogs.confirm("Класс, а также все ученики этого класса, \
                    будут возвращены из архива и снова доступны в списках классов/учеников.");
            }
        }

    }

})();
