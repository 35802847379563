(function() {
    'use strict';

    angular
        .module('supplyApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('atd-school-report', {
            parent: 'report',
            url: '/atd/s',
            data: {
                authorities: ['ROLE_SCH_USER','ROLE_SCH_ADMIN','ROLE_SCH_ACC','ROLE_BASE_ADMIN','ROLE_BASE_ACC','ROLE_ADMIN'],
                pageTitle: 'Отчет по питанию'
            },
            views: {
                'content@': {
                    templateUrl: 'app/ui/report/attendance/atd-report-school.html',
                    controller: 'AtdSchoolReportController',
                    controllerAs: 'vm'
                },
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                }
            },
            resolve: {
            }
        });
    }
})();
