(function () {
    'use strict';

    angular
        .module('supplyApp')
        .service('Export202SettingsService', Export202SettingsService);

    Export202SettingsService.$inject = ['$uibModal', 'RegionSettings'];

    function Export202SettingsService($uibModal, RegionSettings) {
        var service = {
            openModal: openModal,
            getErip202Config: getErip202Config,
            update: update,
            remove: remove
        };

        var modalInstance = null;

        return service;

        function getErip202Config(regionId, onSuccess, onError) {
            RegionSettings.getRegionSettings({confKey:'ERIP_202_CONFIG',value: regionId}, onSuccess, onError);
        }

        function update(settingsData, onSuccess, onError) {
            RegionSettings.update(prepareSaveModel(settingsData), onSuccess, onError);
        }

        function remove(settingsData, onSuccess, onError) {
            RegionSettings.delete({id: settingsData.id}, onSuccess, onError);
        }

        function prepareSaveModel(settings) {
            var saveModel = angular.copy(settings);
            saveModel.value = angular.toJson(settings.value);
            return saveModel;
        }

        function openModal() {
            modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'app/ui/import/export/export-settings-modal.html',
                controller: 'Export202SettingsController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    account: ['Principal', function (Principal) {
                        return Principal.identity(true);
                    }]
                }
            });
            modalInstance.result.then(resetModal, resetModal);
        }

        function resetModal() {
            modalInstance = null;
        }
    }
})();
