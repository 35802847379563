(function () {
    'use strict';

    angular
        .module('supplyApp')
        .controller('PupilCabinetController', PupilCabinetController);

    PupilCabinetController.$inject = ['$stateParams', '$scope', 'Principal', 'PassGen', '$uibModalInstance', 'Cabinet', 'AlertService', 'entity', 'user'];

    function PupilCabinetController($stateParams, $scope, Principal, PassGen, $uibModalInstance, Cabinet, AlertService, entity, user) {
        var vm = this;

        vm.isNew = false;

        vm.clear = clear;
        vm.save = save;

        init();

        function createNew(entity) {
            vm.isNew = true;
            var pupilUser = {
                id: null, login: null, name: null, email: null,
                activated: true, createdBy: null, createdDate: null,
                lastModifiedBy: null, lastModifiedDate: null, resetDate: null,
                resetKey: null, authorities: ['ROLE_USER']
            };
            pupilUser.school = entity.clazz.school;
            pupilUser.region = entity.clazz.school.region.id;
            pupilUser.name = entity.name;
            pupilUser.login = entity.clazz.school.region.id + "-" + entity.guid;
            pupilUser.password = PassGen.next(5);
            return pupilUser;
        }

        $scope.$on('authenticationSuccess', function () {
            init();
        });

        function init() {
            if (!user || !user.id) {
                vm.user = createNew(entity);
            } else {
                vm.user = user;
            }
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
            $uibModalInstance.close(result);
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function save() {
            vm.isSaving = true;
            if (vm.user.id !== null) {
                Cabinet.update({pupilId:entity.id}, vm.user, onSaveSuccess, onSaveError);
            } else {
                Cabinet.create({pupilId:entity.id}, vm.user, onSaveSuccess, onSaveError);
            }
        }

    }
})();
