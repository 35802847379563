(function() {
    'use strict';

    angular
        .module('supplyApp')
        .controller('AllPaytsController', AllPaytsController);

    AllPaytsController.$inject = ['$scope', '$state', 'Principal', 'Report', 'ReportConfig', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams'];

    function AllPaytsController ($scope, $state, Principal, Report, ReportConfig, ParseLinks, AlertService, paginationConstants, pagingParams) {
        var vm = this;

        vm.pagingParams = pagingParams;

        vm.search = search;
        vm.resetSearch = doReset;
        vm.filter = {};
        vm.prevSearch = null;

        vm.filterConfig = ReportConfig.filterConfig;

        vm.showSchoolLink = Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_BASE_ACC', 'ROLE_BASE_ADMIN']);

        init();

        function init() {
            vm.filter = ReportConfig.getPeriod();
        }

        function search () {
            if (!isValid()) {
                return;
            }
            var rangeErr = ReportConfig.validateRange(vm.filter.period, vm.filter.fromDate, vm.filter.toDate);
            if (rangeErr) {
                AlertService.warning(rangeErr);
                return;
            }
            Report.query({
                page: pagingParams.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort(),
                search: vm.filter,
            }, onSuccess, onError);
            function sort() {
                var result = [pagingParams.predicate + ',' + (pagingParams.ascending ? 'asc' : 'desc')];
                if (pagingParams.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.report = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function isValid() {
            if (vm.filter.period === 'custom' && !vm.filter.fromDate) {
                return false;
            }
            return true;
        }

        function doReset() {
            vm.report = null;
            vm.totalItems = null;
            vm.page = null;
            ReportConfig.reset();
            vm.filter = ReportConfig.getPeriod();
        }
    }
})();
