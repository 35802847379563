(function() {
    'use strict';

    var jhiAlert = {
        template: '<div class="alerts" ng-cloak="">' +
                        '<div uib-alert ng-repeat="alert in $ctrl.alerts" ng-class="\'alert-\' + (alert.type || \'warning\')" close="alert.close($ctrl.alerts)">{{alert.msg}}</div>' +
                  '</div>',
        controller: jhiAlertController
    };

    angular
        .module('supplyApp.common')
        .component('jhiAlert', jhiAlert);

    jhiAlertController.$inject = ['$scope', 'AlertService'];

    function jhiAlertController($scope, AlertService) {
        var vm = this;

        vm.alerts = AlertService.get();
        $scope.$on('$destroy', function () {
            vm.alerts = [];
        });
    }
})();
