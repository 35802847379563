(function() {
    'use strict';

    angular
        .module('supplyApp')
        .controller('CabinetPassgenController', CabinetPassgenController);

    CabinetPassgenController.$inject = ['$stateParams', 'Cabinet', 'PassGen', '$uibModalInstance', 'login', 'pupilId'];

    function CabinetPassgenController($stateParams, Cabinet, PassGen, $uibModalInstance, login, pupilId) {
        var vm = this;

        vm.changePassword = changePassword;
        vm.refresh = refresh;
        vm.user = { login: login};
        vm.error = null;
        vm.success = null;
        vm.password = null;

        vm.refresh();

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        function refresh() {
            vm.password = PassGen.next(5);
        }

        function changePassword () {
            if (vm.password !== null) {
                Cabinet.changePassword({pupilId:pupilId}, vm.password, function () {
                    vm.error = null;
                    vm.success = 'OK';
                    $uibModalInstance.close(true);
                }, function (errorMsg) {
                    vm.success = null;
                    vm.error = 'ERROR';
                });
            }
        }
    }
})();
