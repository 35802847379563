(function () {
    'use strict';

    angular
        .module('supplyApp')
        .controller('SchoolIncomeController', SchoolIncomeController);

    SchoolIncomeController.$inject = ['$scope', '$stateParams', '$state', 'Income', 'SchoolIncomeService', 'IncomeSettings', 'AlertService', 'school', 'settings'];

    function SchoolIncomeController($scope, $stateParams, $state, Income, SchoolIncomeService, IncomeSettings, AlertService, school, settings) {
        var vm = this;

        vm.reportRows = [];
        vm.entryDefs = [];
        vm.school = school;
        vm.dateChanged = dateChanged;
        vm.load = loadAll;
        vm.getEditHRef = getEditHRef;
        vm.date = new Date($stateParams.year, $stateParams.month - 1, 1);
        vm.getRowStyle = getRowStyle;
        vm.needToRender = needToRender;
        vm.openSettingsModal = openSettingsModal;
        vm.needToDisplay = needToDisplay;
        vm.settings = IncomeSettings.parseSettings(settings);
        vm.weekResultRowspan = countWeekResultRowspan();

        vm.datePickerOptions = {
            showWeeks: false,
            minMode: "month"
        };

        loadAll();

        $scope.$on('authenticationSuccess', function () {
            loadAll();
        });


        function openSettingsModal() {
            SchoolIncomeService.openSettingsModal(vm.school.region.id, function (callback) {
                if (callback === "save") {
                    $state.reload();
                }
            });
        }

        function loadAll() {
            Income.getSchoolIncomes({
                schoolId: school.id,
                month: vm.date.getMonth() + 1,
                year: vm.date.getFullYear()
            }, onIncomesLoadSuccess, onError);

            function onIncomesLoadSuccess(data, headers) {
                convertDates(data);
                vm.entryDefs = SchoolIncomeService.getDetailsForRender(data, vm.entryDefs);
                vm.reportRows = data;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function convertDates(data) {
            data.forEach(function (entry) {
                if (entry.date) {
                    entry.date = new Date(entry.date.substring(0, 10));
                }
            });
        }

        function countWeekResultRowspan() {
            return SchoolIncomeService.countWeekResultRowspan(vm.settings);
        }

        function needToDisplay(columnName) {
            return SchoolIncomeService.needToDisplay(columnName, vm.settings);
        }

        function needToRender(detailName) {
            return SchoolIncomeService.needToRender(detailName, vm.entryDefs);
        }

        function getRowStyle(reportRow) {
            return SchoolIncomeService.getRowStyle(reportRow);
        }

        function dateChanged() {
            if (!vm.date) {
                vm.date = new Date();
            }
            $state.go("school-income", {id: school.id, year: vm.date.getFullYear(), month: vm.date.getMonth() + 1});
        }

        function getEditHRef(reportRow) {
            return (reportRow.incomeId !== undefined && reportRow.incomeId !== null) ?
                $state.href("income-edit", {id: reportRow.schoolId, incomeId: reportRow.incomeId}) :
                $state.href("school-income.new", {id: reportRow.schoolId, date: reportRow.date});
        }
    }
})();
