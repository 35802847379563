(function() {
    'use strict';
    angular
        .module('supplyApp.common')
        .factory('ByUser', ByUser);

    ByUser.$inject = ['$resource', 'DateUtils'];

    function ByUser ($resource, DateUtils) {
        var resourceUrl =  'api/byuser/';

        return $resource(resourceUrl, {}, {
            'pupils': {
                url: resourceUrl + 'pupils',
                method: 'GET',
                isArray: true
            },
            'classes': {
                url: resourceUrl + 'classes',
                method: 'GET',
                isArray: true
            },
            'lessons': {
                url: resourceUrl + 'lessons',
                method: 'GET',
                isArray: false
             }
        });
    }
})();
