(function() {
    'use strict';
    angular
        .module('supplyApp.common')
        .factory('ImportHistory', ImportHistory);

    ImportHistory.$inject = ['$resource', 'DateUtils'];

    function ImportHistory ($resource, DateUtils) {
        var resourceUrl =  'api/imports/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': { method: 'GET'},
            'payts': {
                url: 'api/imports/:id/payts',
                method: 'GET',
                isArray: true
            }
        });
    }
})();
