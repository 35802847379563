(function () {
    'use strict';

    angular
        .module('supplyApp')
        .controller('ClazzController', ClazzController);

    ClazzController.$inject = ['$scope', 'Nav', 'ByUser', 'Principal', 'School', 'ParseLinks', 'AlertService', 'PaginationUtil', 'pagingParams'];

    function ClazzController($scope, Nav, ByUser, Principal, School, ParseLinks, AlertService, PaginationUtil, pagingParams) {
        var vm = this;

        vm.pagingParams = pagingParams;

        vm.filter = pagingParams.search ? angular.fromJson(pagingParams.search) : {};
        vm.showFilter = !!(vm.filter.schoolId || vm.filter.name || vm.filter.retired);

        vm.doSearch = doSearch;
        vm.resetSearch = resetSearch;

        $scope.$on('authenticationSuccess', function () {
            init();
        });

        init();

        function init() {
            vm.showSchool = Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_BASE_ACC', 'ROLE_BASE_ADMIN']);
            vm.schools = [];
            doLoad();
        }

        function doLoad() {
            vm.searching = true;

            ByUser.classes({
                page: pagingParams.page - 1,
                size: pagingParams.itemsPerPage,
                sort: PaginationUtil.sort(pagingParams),
                search: vm.filter
            }, onSuccess, onError);

            if (vm.showSchool) {
                vm.schools = School.all();
            }
        }

        function doSearch() {
            Nav.search(pagingParams, vm.filter);
        }

        function resetSearch() {
            vm.filter = {};
            vm.searching = false;
            doSearch();
        }

        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.clazzes = data;
            vm.page = pagingParams.page;
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }
    }
})();
