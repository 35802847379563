(function() {
    'use strict';

    angular
        .module('supplyApp.common')
        .directive('tableFilter', tableFilter);

    function tableFilter () {
        var directive = {
            restrict: 'E',
            transclude: true,
            scope: {
                search: '&onSearch',
                reset: '&onReset'
            },
            link: linkFunc,
            replace: true,
            template: '<div class="sp-table-filter"><form name="searchForm" class="form-horizontal">' +
                  '<div ng-transclude></div>' +
                  '<button class="btn btn-primary" ng-disabled="searchForm.$pristine" ng-click="doSearch($event)"><span>Поиск</span></button>&nbsp;' +
                  '<button type="button" class="btn btn-default" ng-click="doReset()"><span>Очистить</span></button>' +
                '</form></div>',
        };

        return directive;

        function linkFunc (scope, element, attrs) {
            scope.doReset = function() {
                scope.searchForm.$setPristine();
                scope.reset();
            }

            scope.doSearch = function($event) {
                $event.preventDefault();
                scope.search();
            }
        }

    }

})();
