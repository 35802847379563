(function () {
    'use strict';

    angular
        .module('supplyApp')
        .controller('LessonSchoolLessonsController', LessonSchoolLessonsController);

    LessonSchoolLessonsController.$inject = ['$scope', '$state', '$stateParams', 'ParseLinks', 'AlertService', 'Principal', 'pagingParams', 'Lesson', 'School', 'entity'];

    function LessonSchoolLessonsController($scope, $state, $stateParams, ParseLinks, AlertService, Principal, pagingParams, Lesson, School, entity) {
        var vm = this;

        vm.pagingParams = pagingParams;
        vm.subjects = [];
        vm.teachers = [];
        vm.clazzes = [];
        vm.school = entity;

        vm.filter = pagingParams.search ? angular.fromJson(pagingParams.search) : {};
        vm.showFilter = !!(vm.filter.clazzId || vm.filter.subjectId || vm.filter.teacherId);
        vm.filtersLoaded = false;

        vm.doSearch = doSearch;
        vm.resetSearch = resetSearch;

        init();

        $scope.$on('authenticationSuccess', function () {
            init();
        });

        $scope.$watch('vm.showFilter', function(newVal) {
            if (newVal && !vm.filtersLoaded) {
                loadFilters();
            }
        });

        function init() {
            vm.isRegionUser = Principal.hasAnyAuthority(['ROLE_BASE_ACC','ROLE_BASE_ADMIN','ROLE_ADMIN']);
            doSearch();
        }

        function doSearch() {
            School.lessons({
                id: $stateParams.schoolId,
                page: pagingParams.page - 1,
                size: pagingParams.itemsPerPage,
                sort: sort(),
                search: vm.filter
            }, onSuccess, onError);
            vm.searching = true;
        }

        function loadFilters() {
            vm.subjects = School.subjects({id:$stateParams.schoolId});
            vm.teachers = School.teachers({id:$stateParams.schoolId});
            vm.clazzes = School.clazzes({id:$stateParams.schoolId});
            vm.filtersLoaded = true;
        }

        function resetSearch() {
            vm.filter = {};
            doSearch();
        }

        function sort() {
            var result = [pagingParams.predicate + ',' + (pagingParams.ascending ? 'asc' : 'desc')];
            if (pagingParams.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.lessons = data;
            vm.page = pagingParams.page;
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }
    }
})();
