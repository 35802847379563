(function () {
    'use strict';

    angular
        .module("supplyApp")
        .controller("AutoimportsController", AutoimportsController);

    AutoimportsController.$inject = ['$state', 'pagingParams', 'account', 'AutoimportHistory', 'AlertService', 'ParseLinks', 'AutoimportSettingsService'];

    function AutoimportsController($state, pagingParams, account, AutoimportHistory, AlertService, ParseLinks, AutoimportSettingsService) {
        var vm = this;

        vm.region = account.region;
        vm.loadPage = loadPage;
        vm.search = loadAll;
        vm.reset = resetSearch;
        vm.transition = transition;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.filter = pagingParams.search ? JSON.parse(pagingParams.search) : {};
        vm.openSettingsModal = AutoimportSettingsService.openModal;
        vm.itemsPerPage = pagingParams.itemsPerPage;
        vm.loadAll = loadAll;
        vm.busy = false;

        loadAll();

        vm.check210 = function() {
            vm.busy = true;
            AutoimportHistory.check210({regionId: vm.region.id}, onCheckFinish, onCheckFinish);
        };

        vm.check206 = function() {
            vm.busy = true;
            AutoimportHistory.check206({regionId: vm.region.id}, onCheckFinish, onCheckFinish);
        };

        function onCheckFinish() {
            vm.busy = false;
            AlertService.info("Запущено");
            $state.reload();
        }

        function loadAll() {
            vm.busy = true;
            AutoimportHistory.query({
                page: pagingParams.page - 1,
                size: pagingParams.itemsPerPage,
                sort: sort(),
                search: vm.filter
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.busy = false;
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.results = processResults(data);
                vm.page = pagingParams.page;
            }

            function onError(error) {
                vm.busy = false;
                AlertService.error(error.data.message);
            }

            function processResults(data) {
                var today = new Date();
                if (data && data.length) {
                    angular.forEach(data, function (item) {
                        if (today.toDateString() === new Date(item.date).toDateString()) {
                            item.isToday = true;
                        }
                    });
                }
                return data;
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function resetSearch() {
            var reload = vm.page === 1;
            loadPage(1);
            if (reload) {
                loadAll();
            }
        }

        function transition() {
            var search = (!!vm.filter.name) ? JSON.stringify(vm.filter) : null;
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: search
            });
        }
    }
})();
