(function() {
    "use strict";

    angular
        .module("supplyApp.common")
        .service("WeekModel", WeekModel);

    WeekModel.$inject = [];

    /**
    * - firstDay: Date
    * - lastDay: Date
    * - allowGlobal
    * - days: [
    *      name: String,
    *      day: String,
    *      date: Date,
    *      dayOff: boolean,
    *      isToday: boolean,
    *      checked: boolean,
    *      active: boolean,
    */
    function WeekModel() {

        var weekDayNames = ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'];

        var weekData = {
            firstDay : null,
            lastDay : null,
            allowGlobal: false,
            days : [],
            todayDate : new Date(new Date().toLocaleString("en-US", {timeZone: 'Europe/Minsk'})),
            todayDateStr : new Date(new Date().toLocaleString("en-US", {timeZone: 'Europe/Minsk'})).toISOString().split('T')[0],
        };

        var service = {
            from: function() {return weekData.firstDay; },
            to: function() {return weekData.lastDay; },
            days: function() {return weekData.days; },
            check: function(dayNum, val) {
                weekData.days[dayNum].checked = val;
            },
            active: function(dayNum, val) {
                weekData.days[dayNum].active = val;
            },
            isToday: function (dayNum) {
                return weekData.days[dayNum].isToday;
            },
            isTodayDateStr: function(dateStr) {
                return dateStr === weekData.todayDateStr;
            },
            isFuture: function (dayNum) {
                return dayOfYear(weekData.days[dayNum].date) > dayOfYear(weekData.todayDate);
            },
            setAllowGlobal: function(val) {
                weekData.allowGlobal = val;
            },
            allowGlobal: function(val) {
                return weekData.allowGlobal;
            },
            initWeek : initWeek,
            yyyymmdd: yyyymmdd,
            init: init,
        };

        return service;

        function init(fromDate) {
            var todayDate = weekData.todayDate;
            if (!fromDate) {
                fromDate = todayDate;
            }

            initWeek(fromDate);

            weekData.days = [];

            for (var i=0; i < 7; i++) {
                var current = getDay(weekData.firstDay, i);

                weekData.days.push({
                    name: weekDayNames[i],
                    day: current.getDate(),
                    date: current,
                    dayOff: i>4,
                    isToday: isToday(current, todayDate),
                    checked: false,
                    active: false,
                });
            }
            return weekData;
        }

        function initWeek(currentDate) {
            var firstDay = new Date(currentDate);
            firstDay.setDate(currentDate.getDate() - currentDate.getDay() + 1);
            startWith(firstDay);
        }

        function startWith(firstDate) {
            weekData.firstDay = new Date(firstDate);
            weekData.lastDay = new Date(firstDate);
            weekData.lastDay.setDate(firstDate.getDate() + 6);
        }

        function isToday(date, today) {
            return date.toDateString() === today.toDateString();
        }

        function getDay(fromDate, plusDays) {
            var day = angular.copy(fromDate);
            day.setDate(day.getDate() + plusDays);
            return day;
        }

        function yyyymmdd(dateIn) {
            var delimiter = '-';
            var yyyy = dateIn.getFullYear().toString();
            var mm = (dateIn.getMonth()+1).toString(); // getMonth() is zero-based
            var dd  = dateIn.getDate().toString();
            return yyyy + delimiter + (mm[1]?mm:"0"+mm[0]) + delimiter + (dd[1]?dd:"0"+dd[0]); // padding
        }

        function dayOfYear(date) {
            return Math.floor((date - new Date(date.getFullYear(), 0, 0)) / 1000 / 60 / 60 / 24);
        }

    }

})();
