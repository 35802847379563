(function () {
    'use strict';

    angular
        .module("supplyApp")
        .controller("AutoimportDetailsController", AutoimportDetailsController);

    AutoimportDetailsController.$inject = ['$state', 'pagingParams', 'AutoimportHistory', 'AlertService', 'ParseLinks', 'AutoimportSettingsService', 'entity', 'previousState'];

    function AutoimportDetailsController($state, pagingParams, AutoimportHistory, AlertService, ParseLinks, AutoimportSettingsService, entity, previousState) {
        var vm = this;

        vm.previousState = previousState;
        vm.pagingParams = pagingParams;

        vm.autoimport = entity;
        vm.loadPage = loadPage;
        vm.search = loadAll;
        vm.reset = resetSearch;

        vm.filter = pagingParams.search ? JSON.parse(pagingParams.search) : {};
        vm.openSettingsModal = AutoimportSettingsService.openModal;

        loadAll();

        function loadAll() {
            AutoimportHistory.details({
                id: vm.autoimport.id,
                page: pagingParams.page - 1,
                size: pagingParams.itemsPerPage,
                sort: sort(),
                search: vm.filter
            }, onSuccess, onError);

            function sort() {
                var result = [pagingParams.predicate + ',' + (pagingParams.ascending ? 'asc' : 'desc')];
                if (pagingParams.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.results = processResults(data);
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }

            function processResults(data) {
                var today = new Date();
                if (data && data.length) {
                    angular.forEach(data, function (item) {
                        if (today.toDateString() == new Date(item.dateLoaded).toDateString()) {
                            item.isToday = true;
                        }
                    });
                }
                return data;
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function resetSearch() {
            var reload = vm.page === 1;
            loadPage(1);
            if (reload) {
                loadAll();
            }
        }

        function transition() {
            var search = (!!vm.filter.name) ? JSON.stringify(vm.filter) : null;
            $state.transitionTo($state.$current, {
                id: entity.id,
                page: vm.page,
                sort: pagingParams.predicate + ',' + (pagingParams.reverse ? 'asc' : 'desc'),
                search: search,
                prevState: JSON.stringify({
                    name: previousState.name,
                    params: previousState.params
                })
            });
        }
    }
})();
