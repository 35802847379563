(function() {
    'use strict';

    angular
        .module('supplyApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('lesson-mark-table', {
            parent: 'app',
            url: '/lsn',
            views: {
                'content@': {
                    template: '<div class="row"><div class="col-md-12 box">Загрузка...</div></div>',
                    controller: 'LessonRoutingController',
                    controllerAs: 'vm',
                }
            },
            data: {
                authorities: [],
            },
            resolve: {

            }
        })
        .state('lesson-mark-schools', {
            parent: 'lesson-mark-table',
            url: '/s?page&sort?search',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/ui/schedule/lesson-mark/lesson-schools.html',
                    controller: 'LessonSchoolsController',
                    controllerAs: 'vm'
                },
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return PaginationUtil.buildPageParams($stateParams);
                }],
            }
        })
        .state('lesson-mark-school', {
            parent: 'lesson-mark-schools',
            url: '/{schoolId}',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/ui/schedule/lesson-mark/lesson-school-lessons.html',
                    controller: 'LessonSchoolLessonsController',
                    controllerAs: 'vm'
                },
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return PaginationUtil.buildPageParams($stateParams);
                }],
                entity: ['$stateParams', 'School', function($stateParams, School){
                    return School.get({id:$stateParams.schoolId}).$promise;
                }],
            }
        })


        /**
         * Select User-specific classes
         */
        .state('lesson-mark-user-classes', {
            parent: 'lesson-mark-table',
            url: '/u?page&sort&search',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/ui/schedule/lesson-mark/lesson-schedule.html',
                    controller: 'LessonUserClassesController',
                    controllerAs: 'vm'
                },
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return PaginationUtil.buildPageParams($stateParams);
                }],
                previousState: ["$state", '$stateParams', 'PaginationUtil', function ($state, $stateParams, PaginationUtil) {
                    return PaginationUtil.buildPreviousState($state, $stateParams, 'lesson-mark-table');
                }],
            }
        })

        .state('lesson-mark-schedule', {
            parent: 'lesson-mark-table',
            url: '/l/{id}',
            data: {
                authorities: [],
                pageTitle: 'Расписание занятий'
            },
            views: {
                'content@': {
                    templateUrl: 'app/ui/schedule/lesson-mark/lesson-schedule.html',
                    controller: 'LessonScheduleController',
                    controllerAs: 'vm'
                },
            },
            resolve: {
                previousState: ["$state", '$stateParams', 'PaginationUtil', function ($state, $stateParams, PaginationUtil) {
                    return PaginationUtil.buildPreviousState($state, $stateParams, 'lesson-mark-table');
                }],
            }
        })

        .state('lesson-mark-log', {
            parent: 'lesson-mark-schedule',
            url: '/i/{logId}',
            data: {
                authorities: [],
                pageTitle: 'Детали урока'
            },
            views: {
                'content@': {
                    templateUrl: 'app/ui/schedule/lesson-mark/lesson-log-entry.html',
                    controller: 'LessonLogEntryController',
                    controllerAs: 'vm'
                },
            },
            resolve: {
                lessonLog: ['$stateParams', 'LessonLog', function($stateParams, LessonLog){
                    return LessonLog.grades({id:$stateParams.logId}).$promise;
                }],
            }
        })

        .state('lesson-mark-log.detail', {
            parent: 'lesson-mark-schedule',
            url: '/detail/{logId}',
            data: {
                authorities: [],
                pageTitle: 'Детали урока'
            },
            views: {
                'content@': {
                    templateUrl: 'app/ui/schedule/lesson-mark/lesson-log-entry-detail.html',
                    controller: 'LessonLogEntryDetailController',
                    controllerAs: 'vm'
                },
            },
            resolve: {
                entity: ['$stateParams', 'LessonLog', function($stateParams, LessonLog){
                    return LessonLog.get({id:$stateParams.logId}).$promise;
                }],
                previousState: ["$state", '$stateParams', 'PaginationUtil', function ($state, $stateParams, PaginationUtil) {
                    return PaginationUtil.buildPreviousState($state, $stateParams, 'lesson-mark-table');
                }],
            }
        })

        .state('lesson-mark-log.new', {
            parent: 'lesson-mark-schedule',
            url: '/new/{date}',
            data: {
                authorities: [],
                pageTitle: 'Новый урок'
            },
            params: {
                date: {
                    type: 'date',
                    value: null,
                },
            },
            views: {
                'content@': {
                    templateUrl: 'app/ui/schedule/lesson-mark/lesson-log-entry.html',
                    controller: 'LessonLogEntryController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                lessonLog: ['$stateParams', '$state', 'AlertService', 'LessonLog', function ($stateParams, $state, AlertService, LessonLog) {
                    var date = $stateParams.date;
                    var adjustedDate = new Date();
                    adjustedDate.setFullYear(date.getUTCFullYear(), date.getMonth(), date.getDate());
                    return LessonLog.initLog({id:$stateParams.id}, adjustedDate).$promise.catch(function(error) {
                        if (error.status === 409 && error.headers('X-supplyApp-params')) {
                            var entityKey = error.headers('X-supplyApp-params');
                            $state.go('lesson-mark-log', {id:$stateParams.id, logId:entityKey });
                        } else {
                            AlertService.alert("Ошибка");
                            $state.go('^');
                        }
                    });
                }]
            }
        });

    }
})();
