(function() {
    'use strict';

    angular
        .module('supplyApp')
        .controller('PaytsBySchoolsController', PaytsBySchoolsController);

    PaytsBySchoolsController.$inject = ['$scope', '$state', 'Report', 'ReportConfig', 'ParseLinks', 'AlertService'];

    function PaytsBySchoolsController ($scope, $state, Report, ReportConfig, ParseLinks, AlertService) {
         var vm = this;

        vm.search = doReport;
        vm.resetSearch = doReset;
        vm.filterConfig = ReportConfig.filterConfig;

        init();

        function init() {
            vm.filter = ReportConfig.getPeriod();
        }

        function doReport () {
            if (!isValid()) {
                return;
            }
            var rangeErr = ReportConfig.validateRange(vm.filter.period, vm.filter.fromDate, vm.filter.toDate);
            if (rangeErr) {
                AlertService.warning(rangeErr);
                return;
            }
            Report.schools({
                search: vm.filter,
            }, onSuccess, onError);
            function onSuccess(data, headers) {
                vm.report = data;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function isValid() {
            if (vm.filter.period === 'custom' && !vm.filter.fromDate) {
                return false;
            }
            return true;
        }

        function doReset () {
            vm.report = null;
            ReportConfig.reset();
            init();
        }
    }
})();
