(function () {
    'use strict';

    angular
        .module('supplyApp.common')
        .service('CostCenterSettingsService', CostCenterSettingsService);

    CostCenterSettingsService.$inject = ['$uibModal', 'CostCenterSettings'];

    function CostCenterSettingsService($uibModal, CostCenterSettings) {
        var service = {
            getFtpSettings: getFtpSettings,
            update: update
        };

        return service;

        function getFtpSettings(costCenterId, onSuccess, onError) {
            CostCenterSettings.getSettings({ confKey:'FTP_SETTINGS', id:costCenterId}, onSuccess, onError);
        }

        function update(costCenterId, settingsData, onSuccess, onError) {
            if (!settingsData.confKey) {
                settingsData.confKey = 'FTP_SETTINGS';
            }
            CostCenterSettings.update({id: costCenterId}, prepareSaveModel(settingsData), onSuccess, onError);
        }

        function prepareSaveModel(settings) {
            var saveModel = angular.copy(settings);
            saveModel.value = angular.toJson(settings.value);
            return saveModel;
        }

    }
})();
