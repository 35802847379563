(function () {
    'use strict';

    angular
        .module('supplyApp.common')
        .factory('RequestHelper', RequestHelper);

    RequestHelper.$inject = ['PaginationUtil'];

    function RequestHelper(PaginationUtil) {

        var service = {
            sort : PaginationUtil.sort,
        };

        return service;

    }
})();
