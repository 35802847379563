(function() {
    'use strict';

    angular
        .module('supplyApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('report', {
            parent: 'app',
            abstract: true,
            url: '/r',
        })
        .state('stats', {
            parent: 'report',
            url: '/stats',
            data: {
              authorities: ['ROLE_BASE_ACC','ROLE_BASE_ADMIN','ROLE_ADMIN'],
              pageTitle: 'Статистика платежей'
            },
            views: {
                'content@': {
                    templateUrl: 'app/ui/report/stats/stats.html',
                    controller: 'StatsReportController',
                    controllerAs: 'vm'
                },
            }
        });
    }
})();
