(function () {
    'use strict';

    angular
        .module('supplyApp.common')
        .directive('incomesFormRow', incomesFormRow);

    function incomesFormRow() {
        return {
            restrict: 'E',
            scope: {
                rowTitle: '@',
                rowModel: '=',
                formObject: '=',
                tabTitle: '@'
            },
            replace: true,
            templateUrl: 'app/common/components/form/incomes-form-row.html',
            link: function (scope) {
                var oldValues = [];

                scope.$watchGroup(['rowModel.num', 'rowModel.price', 'rowModel.summary'], function (newValues) {
                        if (newValues[0] != null && newValues[1] != null) {
                            if (newValues[0] != oldValues[0] || newValues[1] != oldValues[1]) {
                                scope.rowModel.summary = presize(newValues[0] * newValues[1]);
                                oldValues = newValues.slice(0);
                                oldValues[2] = scope.rowModel.summary;
                            } else if (newValues[2] != oldValues[2] && !!newValues[0]) {
                                scope.rowModel.price = newValues[2] / newValues[0];
                                oldValues = newValues.slice(0);
                                oldValues[1] = scope.rowModel.price;
                            }

                            scope.formObject[scope.tabTitle + scope.rowModel.title + 'summaryInp'].$setTouched();

                            if (scope.rowModel.summary != presize(newValues[0] * newValues[1])) {
                                if (newValues[0] == 0) {
                                    scope.formObject[scope.tabTitle + scope.rowModel.title + 'num'].$setValidity(scope.tabTitle + scope.rowModel.title + 'num', false);
                                } else {
                                    scope.formObject[scope.tabTitle + scope.rowModel.title + 'summaryInp'].$setValidity(scope.tabTitle + scope.rowModel.title + 'summaryInp', false);
                                }
                            } else {
                                scope.formObject[scope.tabTitle + scope.rowModel.title + 'priceInp'].$setValidity(scope.tabTitle + scope.rowModel.title + 'priceInp', true);
                                scope.formObject[scope.tabTitle + scope.rowModel.title + 'summaryInp'].$setValidity(scope.tabTitle + scope.rowModel.title + 'summaryInp', true);
                                scope.formObject[scope.tabTitle + scope.rowModel.title + 'num'].$setValidity(scope.tabTitle + scope.rowModel.title + 'num', true);
                            }

                            if (newValues[0] != 0 || newValues[1] != 0) {
                                scope.formObject[scope.tabTitle + scope.rowModel.title + 'num'].$setValidity(scope.tabTitle + scope.rowModel.title + 'num', newValues[0] != 0);
                                scope.formObject[scope.tabTitle + scope.rowModel.title + 'priceInp'].$setValidity(scope.tabTitle + scope.rowModel.title + 'priceInp', newValues[1] != 0);
                            }

                            if (newValues[0] == 0 && newValues[1] == 0) {
                                scope.formObject[scope.tabTitle + scope.rowModel.title + 'priceInp'].$setUntouched();
                                scope.formObject[scope.tabTitle + scope.rowModel.title + 'summaryInp'].$setUntouched();
                                scope.formObject[scope.tabTitle + scope.rowModel.title + 'num'].$setUntouched();
                            }
                        }

                        function presize(num) {
                            return Math.round(num * 100) / 100;
                        }
                    }
                );
            }
        };
    }
})();
