(function () {
    'use strict';

    angular
        .module('supplyApp')
        .controller('AutoimportHistoryDeleteController', AutoimportHistoryDeleteController);

    AutoimportHistoryDeleteController.$inject = ['$uibModalInstance', 'entity', 'AutoimportHistory'];

    function AutoimportHistoryDeleteController($uibModalInstance, entity, AutoimportHistory) {
        var vm = this;

        vm.import = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete(id) {
            AutoimportHistory.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
