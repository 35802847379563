(function () {
    'use strict';

    angular
        .module('supplyApp')
        .controller('AtdUserClassesController', AtdUserClassesController);

    AtdUserClassesController.$inject = ['$scope', '$state', '$stateParams', 'ParseLinks', 'AlertService', 'pagingParams', 'ByUser'];

    function AtdUserClassesController($scope, $state, $stateParams, ParseLinks, AlertService, pagingParams, ByUser) {
        var vm = this;

        vm.pagingParams = pagingParams;

        vm.filter = pagingParams.search ? JSON.parse(pagingParams.search) : {};
        vm.showFilter = !!(vm.filter.schools || vm.filter.name);

        vm.doSearch = doSearch;
        vm.resetSearch = resetSearch;

        $scope.$on('authenticationSuccess', function () {
            doSearch();
        });

        doSearch();

        function doSearch() {
            ByUser.classes({
                page: pagingParams.page - 1,
                size: pagingParams.itemsPerPage,
                sort: sort(),
                search: vm.filter
            }, onSuccess, onError);
            vm.searching = true;
        }

        function resetSearch() {
            vm.filter = {};
            doSearch();
        }

        function sort() {
            var result = [pagingParams.predicate + ',' + (pagingParams.ascending ? 'asc' : 'desc')];
            if (pagingParams.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.clazzes = data;
            vm.page = pagingParams.page;
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }
    }
})();
