(function() {
  "use strict";

  angular
    .module("supplyApp")
    .controller("FoodTypeController", FoodTypeController);

  FoodTypeController.$inject = ["$scope", "$state", "FoodType", "FoodCost", "Principal", "currentUser", "AlertService", "Region", "FoodTypesService"];

  function FoodTypeController($scope, $state, FoodType, FoodCost, Principal, currentUser, AlertService, Region, FoodTypesService) {
    var vm = this;

    var entityTypes = FoodTypesService.entityTypes;

    vm.addFoodType = addFoodType;
    vm.remove = remove;
    vm.saveTypes = saveTypes;
    vm.saveCosts = saveCosts;
    vm.canModify = canModify;

    vm.foodTypes = [];
    vm.foodTypesCache = [];
    vm.datePickOptions = {
      maxDate: new Date(),
      showWeeks: true
    };

    vm.costFilter = {
      date: new Date()
    };

    init();

    function init() {
      FoodType.query(onTypesLoad, onError);

      function onTypesLoad(data) {
        vm.foodTypes = FoodTypesService.prepareData(data, vm.costFilter.date);
        vm.foodTypesCache = angular.copy(vm.foodTypes);
      }

    }

    function saveTypes(foodTypeForm) {
      doSave(foodTypeForm, entityTypes.FOOD_TYPE, FoodType);
    }

    function saveCosts(costsForm) {
      doSave(costsForm, entityTypes.FOOD_COST, FoodCost);
    }

    function doSave(saveForm, entityType, saveService) {
        vm.busy = true;
        vm.saveForm = saveForm;

        FoodTypesService.saveOrUpdateAll(
          vm.foodTypes,
          entityType,
          vm.foodTypesCache,
          saveService,
          saveComplete,
          onError
        );
    }

    function remove(foodType) {
      if (!foodType.id) {
        var index = vm.foodTypes.indexOf(foodType);
        if (index != -1) {
          vm.foodTypes.splice(index, 1);
        }
      } else {
        $state.go("foodTypes.delete", { id: foodType.id });
      }
    }

    function addFoodType() {
      vm.foodTypes.push({
        name: "",
        active: false,
        region: currentUser.region
      });
    }

    function canModify(foodType) {
      if (currentUser.region) {
        return foodType.region && currentUser.region.id === foodType.region.id;
      }
      return Principal.hasAnyAuthority(["ROLE_ADMIN"]);
    }

    function saveComplete() {
      vm.busy = false;
      init();
      vm.saveForm.$setPristine();
    }

    function onError(error, form) {
      AlertService.error(error.data.message);
    }
  }
})();
