(function () {
    'use strict';

    angular
        .module('supplyApp.common')
        .service("Nav", Nav);

    Nav.$inject = ['$state'];

    function Nav($state) {
        var service = {
            search: search,
        };
        return service;

        function search(pagingParams, filter) {
            pagingParams.search = angular.toJson(filter);
            pagingParams.page = 1;
            $state.transitionTo($state.$current, pagingParams);
        }
    }
})();
