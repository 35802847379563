(function() {
    'use strict';
    angular
        .module('supplyApp.common')
        .factory('Payt', Payt);

    Payt.$inject = ['$resource', 'DateUtils'];

    function Payt ($resource, DateUtils) {
        var resourceUrl =  'api/payts/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': { method: 'GET' },
            'update': { method:'PUT' },
            'reject': { 
                method:'PUT',
                url: 'api/payts/reject/:id',
                params: {
                    id: '@id',
                }
            },
        });
    }
})();
