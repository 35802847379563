(function() {
    'use strict';
    angular
        .module('supplyApp.common')
        .factory('Lesson', Lesson);

    Lesson.$inject = ['$resource'];

    function Lesson ($resource) {
        var resourceUrl =  'api/lessons/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'all': { method: 'GET', isArray: true},
            'get': { method: 'GET' },
            'update': {
                method:'PUT',
                params: {
                    id: '@id',
                }},
            'table': {
                method: 'GET',
                url: resourceUrl + '/table',
                params: {
                    id: '@id',
                }},
            'clazzMap': {
                method: 'GET',
                isArray: true,
                url: 'api/lessons/classes/map/:schoolId',
                params: {
                    schoolId: '@schoolId',
                }},
            'updateClazzMap': {
                method: 'PUT',
                isArray: true,
                url: 'api/lessons/classes/map/'
            },
        });
    }
})();
