(function() {
    'use strict';

    angular
        .module('supplyApp')
        .controller('UserPasswordController', UserPasswordController);

    UserPasswordController.$inject = ['$stateParams', 'User', '$uibModalInstance', 'entity'];

    function UserPasswordController($stateParams, User, $uibModalInstance, entity) {
        var vm = this;

        vm.changePassword = changePassword;
		vm.user = entity;
		vm.clear = clear;
        vm.doNotMatch = null;
        vm.error = null;
        vm.success = null;

		function clear () {
			$uibModalInstance.dismiss('cancel');
        }

        function changePassword () {
            if (vm.password !== vm.confirmPassword) {
                vm.error = null;
                vm.success = null;
                vm.doNotMatch = 'ERROR';
            } else {
                vm.doNotMatch = null;
                User.changePassword({login:vm.user.login}, vm.password, function () {
                    vm.error = null;
                    vm.success = 'OK';
					$uibModalInstance.close(true);
                }, function (errorMsg) {
                    vm.success = null;
                    vm.error = 'ERROR';
                });
            }
        }
	}
})();
