(function () {
    'use strict';

    angular
        .module('supplyApp')
        .controller('UserDialogController', UserDialogController);

    UserDialogController.$inject = ['$stateParams', '$scope', '$location', 'Principal', '$uibModalInstance', 'User', 'School', 'UserRolesService', 'AlertService', 'PassGen', 'entity'];

    function UserDialogController($stateParams, $scope, $location, Principal, $uibModalInstance, User, School, UserRolesService, AlertService, PassGen, entity) {
        var vm = this;

        var canSelectSchool = false;
        vm.authorities = UserRolesService.getAllowedAuthorities();
        vm.authority = entity.authorities?entity.authorities[0]:null;
        vm.clear = clear;
        vm.languages = null;
        vm.save = save;
        vm.user = entity;

        if (!vm.user.id) {
            vm.user.password = PassGen.next(5);
        }

        init();

        $scope.$on('authenticationSuccess', function () {
            init();
        });

        function init() {
            canSelectSchool = Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_BASE_ADMIN']);
            vm.showSchool = canSelectSchool;
            if (canSelectSchool) {
                vm.schools = School.all();
            }
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
            $uibModalInstance.close(result);
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function save() {
            vm.isSaving = true;
            vm.user.authorities = [vm.authority];
            if (vm.user.id !== null) {
                User.update(vm.user, onSaveSuccess, onSaveError);
            } else {
                User.save(vm.user, onSaveSuccess, onSaveError);
            }
        }

        vm.refreshPassword = function() {
            vm.user.password = PassGen.next(5);
        };

        $scope.$watch('vm.authority', function(authority) {
            if (authority === 'ROLE_BASE_ACC' ||
                authority === 'ROLE_BASE_ADMIN') {
                vm.showSchool = false;
            } else {
                vm.showSchool = canSelectSchool;
            }
        });
    }
})();
