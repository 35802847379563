(function () {
    'use strict';

    angular
        .module('supplyApp')
        .controller('AutoimportSettingsController', AutoimportSettingsController);

    AutoimportSettingsController.$inject = ['$scope', 'CostCenterSettingsService', 'AlertService', 'account', '$uibModalInstance'];

    function AutoimportSettingsController($scope, CostCenterSettingsService, AlertService, account, $uibModalInstance) {
        var vm = this;

        vm.save = save;
        vm.cancel = cancel;
        vm.region = account.region;
        vm.costCenter = account.region.costCenter;
        vm.load = load;

        load();

        function load() {
            if (!vm.costCenter || !vm.costCenter.id) {
                AlertService.error("Расчетный центр не назначен для региона " + vm.region.name);
                return;
            }

            CostCenterSettingsService.getFtpSettings(vm.costCenter.id, onSuccess, onError);

            function onSuccess(data) {
                vm.settings = data;
                vm.settings.value = angular.fromJson(data.value);
                vm.settings.value.port = parseInt(vm.settings.value.port);
            }
        }

        function save() {
            CostCenterSettingsService.update(vm.costCenter.id, vm.settings, onSuccess, onError);

            function onSuccess() {
                $uibModalInstance.dismiss('save');
            }
        }

        function onError(error) {
            $uibModalInstance.dismiss('error');
            AlertService.error(error.data.message);
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
