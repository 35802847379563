(function () {
    'use strict';

    angular
        .module("supplyApp.common")
        .factory("AutoimportHistory", AutoimportHistory);

    AutoimportHistory.$inject = ["$resource"];

    function AutoimportHistory($resource) {
        var resourceUrl = 'api/autoimports/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {method: 'GET'},
            'details': {
                url: 'api/autoimports/:id/details',
                method: 'GET',
                isArray: true
            },
            'check210': {
                url: 'api/autoimports/check/210/:regionId',
                method: 'GET'
            },
            'check206': {
                url: 'api/autoimports/check/206/:regionId',
                method: 'GET'
            }
        });
    }
})();
