(function () {
    'use strict';

    angular
        .module('supplyApp')
        .controller('ClazzDetailController', ClazzDetailController);

    ClazzDetailController.$inject = ['$scope', '$state', '$rootScope', '$stateParams', '$timeout', 'Principal', 'previousState', 'pagingParams', 'entity', 'Clazz', 'AlertService', 'СlazzDetailService', 'Modal'];

    function ClazzDetailController($scope, $state, $rootScope, $stateParams, $timeout, Principal, previousState, pagingParams, entity, Clazz, AlertService, СlazzDetailService, Modal) {
        var vm = this;

        vm.clazz = entity;
        vm.previousState = previousState;
        vm.openImportModal = function () {
            СlazzDetailService.openPupilImportModal(vm.clazz).then(loadPupils);
        };

        vm.retireClazz = function() {
            СlazzDetailService.openClazzRetireModal(vm.clazz).then(function() {
                Clazz.retire({id:vm.clazz.id}, function() {
                    $state.go(previousState.name, previousState.params);
                });
            });
        };

        pagingParams.itemsPerPage = 10;
        vm.pagingParams = pagingParams;

        vm.showSchoolLink = Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_BASE_ACC', 'ROLE_BASE_ADMIN']);

        loadPupils();

        $scope.$on('authenticationSuccess', function () {
            loadPupils();
        });

        function loadPupils() {
            Clazz.pupils({
                id: entity.id,
                page: pagingParams.page - 1,
                size: pagingParams.itemsPerPage,
                sort: sort(),
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                //vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.pupils = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function sort() {
            var result = [pagingParams.predicate + ',' + (pagingParams.ascending ? 'asc' : 'desc')];
            if (pagingParams.predicate !== 'guid') {
                result.push('guid');
            }
            return result;
        }

        var unsubscribeClzUpd = $rootScope.$on('supplyApp:clazzUpdate', function (event, result) {
            vm.clazz = result;
        });
        $scope.$on('$destroy', unsubscribeClzUpd);

        var unsubscribePplCrt = $rootScope.$on('supplyApp:pupilCreate', function(event, result) {
            $timeout(function (){
                Modal.pupilAdded(result);
            });
        });
        $scope.$on('$destroy', unsubscribePplCrt);

    }
})();
