(function () {
    'use strict';

    angular
        .module('supplyApp')
        .controller('Export202SettingsController', Export202SettingsController);

    Export202SettingsController.$inject = ['$scope', 'Export202SettingsService', 'account', '$uibModalInstance', 'AlertService'];

    function Export202SettingsController($scope, Export202SettingsService, account, $uibModalInstance, AlertService) {
        var vm = this;

        vm.save = save;
        vm.isBusy = false;
        vm.cancel = cancel;
        vm.region = account.region;
        vm.load = load;
        vm.delete = onDelete;

        load();

        function load() {
            Export202SettingsService.getErip202Config(vm.region.id, onSuccess, onError);

            function onSuccess(data) {
                vm.settings = data;
                vm.settings.value = data.value!==null?JSON.parse(data.value):{};
            }
        }

        function save() {
            vm.isBusy = true;
            Export202SettingsService.update(vm.settings, onSuccess, onError);

            function onSuccess() {
                vm.isBusy = false;
                $uibModalInstance.dismiss('save');
            }
        }

        function onDelete() {
            vm.isBusy = true;
            Export202SettingsService.remove(vm.settings, onSettingsDelete, onError);
            function onSettingsDelete() {
                vm.isBusy = false;
                AlertService.success("Настройки удалены");
                $uibModalInstance.dismiss('cancel');
            }
        }

        function onError(error) {
            vm.isBusy = false;
            $uibModalInstance.dismiss('error');
            AlertService.error(error.data.message);
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
