(function () {
    'use strict';

    angular
        .module('supplyApp.common')
        .directive('spTable', spTable);

    spTable.$inject = ['$state', 'paginationConstants'];

    function spTable($state, paginationConstants) {
        return {
            restrict: 'E',
            transclude: true,
            scope: {
                page: '<',
                totalItems: '<',
                items: '<',
                pagingParams: '<',
                additionalParams: '<',
                prevState: '<',
                filter: '<'
            },
            link: link,
            replace: false,
            template: '<div class="sp-table table-responsive"></div>' +
            '<div class="text-center" ng-show="totalItems>0">' +
            '<jhi-item-count page="page" total="totalItems" items-per-page="itemsPerPage"></jhi-item-count>' +
            '<ul uib-pagination class="pagination-sm" total-items="totalItems" items-per-page="itemsPerPage" ng-model="page" ng-change="transition()"></ul>' +
            '</div>'
        };

        function link(scope, element, attrs, ctrl, transclude) {
            scope.predicate = scope.pagingParams.predicate;
            scope.reverse = scope.pagingParams.ascending;
            scope.transition = transition;
            scope.itemsPerPage = (!!scope.pagingParams.itemsPerPage) ? scope.pagingParams.itemsPerPage : paginationConstants.itemsPerPage;

            function transition() {
                var toParams = {
                    page: scope.page,
                    sort: scope.predicate + ',' + (scope.reverse ? 'asc' : 'desc'),
                };
                if(scope.prevState) {
                    toParams.prevState = JSON.stringify({
                        name: scope.prevState.name,
                        params: scope.prevState.params
                    });
                }
                if ($state.$current.ownParams.id) {
                    toParams.id = $state.params.id;
                }
                if (scope.filter) {
                    toParams.search = JSON.stringify(scope.filter);
                }
                $state.transitionTo($state.$current, toParams);
            }

            transclude(scope, function (clone) {
                element.find(".sp-table").append(clone);
            });
        }
    }
})();
