(function() {
  "use strict";
  angular.module("supplyApp.common").factory("FoodType", FoodType);

  FoodType.$inject = ["$resource", "DateUtils"];

  function FoodType($resource, DateUtils) {
    var resourceUrl = "api/food/types";

    return $resource(resourceUrl, {}, {
        query: { method: "GET", isArray: true },
        get: {
            url: resourceUrl + '/:id',
            method: 'GET'
        },
        save: { method: "POST" },
        update: { method: "PUT" },
        delete: {
            url: resourceUrl + '/:id',
            method: 'DELETE'
        },
        active: {
            url: resourceUrl + '/active/:regionId',
            method: "GET",
            isArray: true
        },
      }
    );
  }
})();
