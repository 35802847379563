(function () {
    'use strict';

    angular
        .module('supplyApp.common')
        .service('RegionSettings', RegionSettings);

    RegionSettings.$inject = ['$resource'];

    function RegionSettings($resource) {
        var resourceUrl = 'api/settings/region/';

        return $resource(resourceUrl + ":id", {}, {
            'getSchoolSettings': {
                method: 'GET',
                url: resourceUrl + 'bySchoolIdAndKey/:confKey/:value'
            },
            'getRegionSettings': {
                method: 'GET',
                url: resourceUrl + 'byRegionAndKey/:confKey/:value'
            },
            'update': {
                method: 'PUT'
            },
        });
    }
})();
