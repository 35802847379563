(function () {
    'use strict';

    angular
        .module('supplyApp')
        .controller('ImportsController', ImportsController);

    ImportsController.$inject = ['$scope', '$state', 'ParseLinks', 'AlertService', 'pagingParams', 'Principal', 'ImportHistory', 'Upload', 'AutoimportSettingsService', 'account'];

    function ImportsController($scope, $state, ParseLinks, AlertService, pagingParams, Principal, ImportHistory, Upload, ImportSettingsService, account) {
        var vm = this;

        vm.region = account.region;
        vm.loadPage = loadPage;
        vm.pagingParams = pagingParams;
        vm.pagingParams.itemsPerPage = 10;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.filter = pagingParams.search ? JSON.parse(pagingParams.search) : {};
        vm.transition = transition;
        vm.itemsPerPage = 10;
        vm.files = null;
        vm.log = '';
        vm.search = loadAll;
        vm.reset = resetSearch;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            ImportHistory.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                search: vm.filter
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.results = processResults(data);
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }

            function processResults(data) {
                var today = new Date();
                if (data && data.length) {
                    angular.forEach(data, function (item) {
                        if (today.toDateString() == new Date(item.dateLoaded).toDateString()) {
                            item.isToday = true;
                        }
                    });
                }
                return data;
            }
        }

        function resetSearch() {
            var reload = vm.page === 1;
            loadPage(1);
            if (reload) {
                loadAll();
            }
        }

        function upload(files) {
            if (!files || files.$error) {
                return;
            }

            angular.forEach(files, function (file) {
                file.msg = '';
                Upload.upload({
                    url: 'api/upload/' + vm.region.id,
                    data: {
                        file: file
                    }
                }).then(function (resp) {
                        loadAll();
                        if (resp.data) {
                            file.status = resp.data.status;
                        }
                    },
                    function (response) {
                        if (response.status > 0) {
                            if (response.status >=500) {
                                file.status= 'ERROR';
                            } else {
                                file.status= 'WARN';
                            }
                            file.msg = response.status + ': ' + response.statusText;
                            
                        }
                    },
                    function (evt) {
                        var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                        file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
                    });
            });
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            var search = (!!vm.filter.name) ? JSON.stringify(vm.filter) : null;
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: search
            });
        }

        $scope.$watch('vm.files', function () {
            upload(vm.files);
        });
    }
})();
