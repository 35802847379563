(function() {
    'use strict';

    angular
        .module('supplyApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('lesson-clazz-table', {
            parent: 'app',
            url: '/lcl?schoolId',
            data: {
                authorities: [],
                pageTitle: 'Расписание занятий'
            },
            params: {
                schoolId: null
            },
            views: {
                'content@': {
                    templateUrl: 'app/ui/schedule/lesson-clazz/lesson-clazz-table.html',
                    controller: 'LessonClazzTableController',
                    controllerAs: 'vm'
                },
            },
            resolve: {
                previousState: ["$state", '$stateParams', 'PaginationUtil', function ($state, $stateParams, PaginationUtil) {
                    return PaginationUtil.buildPreviousState($state, $stateParams, 'lesson-clazz-table');
                }],
            }
        })
        ;

    }
})();
