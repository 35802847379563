(function() {
    'use strict';
    angular
        .module('supplyApp.common')
        .factory('Offload', Offload);

    Offload.$inject = ['$resource'];

    function Offload ($resource) {
        var resourceUrl =  'api/offload';

        return $resource(resourceUrl, {}, {
            'getAll': {
                method: 'GET',
                url: resourceUrl + '/download/all/:regionId',
            },
            'getNew': {
                method: 'GET',
                url: resourceUrl + '/download/new/:regionId',
            },
            'offloadAll': {
                method: 'GET',
                url: resourceUrl + '/all/:regionId',
            },
            'offloadNew': {
                method: 'GET',
                url: resourceUrl + '/new/:regionId',
            },

        });
    }
})();
