(function () {
    'use strict';

    angular
        .module('supplyApp')
        .controller('UsersController', UsersController);

    UsersController.$inject = ['$scope', 'Nav', 'User', 'AlertService', 'ParseLinks', 'UserRolesService', 'paginationConstants', 'pagingParams'];

    function UsersController($scope, Nav, User, AlertService, ParseLinks, UserRolesService, paginationConstants, pagingParams) {
        var vm = this;

        vm.pagingParams = pagingParams;
        vm.authorities = UserRolesService.getAllowedAuthorities();
        vm.search = pagingParams.search ? JSON.parse(pagingParams.search) : {};
        vm.showFilter = !!(vm.search.login || vm.search.name || vm.search.authority || vm.search.school);

        vm.doSearch = doSearch;
        vm.resetSearch = resetSearch;

        load();

        $scope.$on('authenticationSuccess', function () {
            load();
        });

        function load() {
            User.query({
                page: pagingParams.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort(),
                search: vm.search
            }, onSuccess, onError);
        }

        function doSearch() {
            Nav.search(pagingParams, vm.search);
        }

        function onSuccess(data, headers) {
            var hiddenUsersSize = 0;
            for (var i in data) {
                if (data[i]['login'] === 'anonymoususer') {
                    data.splice(i, 1);
                    hiddenUsersSize++;
                }
            }
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count') - hiddenUsersSize;
            vm.users = data;
            vm.page = pagingParams.page;
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        function resetSearch() {
            vm.search = {};
            load();
        }

        function sort() {
            var result = [pagingParams.predicate + ',' + (pagingParams.ascending ? 'asc' : 'desc')];
            if (pagingParams.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }
    }
})();
