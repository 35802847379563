(function() {
    'use strict';

    angular
        .module('supplyApp')
        .controller('LessonLogEntryDetailController', LessonLogEntryDetailController);

    LessonLogEntryDetailController.$inject = ['$scope', '$state', '$rootScope', '$stateParams',  'Principal', 'Dialogs', 'LessonLog', 'previousState', 'entity'];

    function LessonLogEntryDetailController($scope, $state, $rootScope, $stateParams, Principal, Dialogs, LessonLog, previousState, entity) {
        var vm = this;

        vm.entity = entity;
        vm.previousState = previousState;

        init();

        function init() {
            vm.isTeacher = Principal.hasAnyAuthority(['ROLE_SCH_USER']);
            if (vm.isTeacher) {
                vm.canManage = Principal.current().login === entity.lesson.teacher.login;
            } else {
                vm.canManage = Principal.hasAnyAuthority(['ROLE_SCH_ADMIN', 'ROLE_ADMIN']);
            }
        }

        vm.delete = function () {
            Dialogs.warn("Запись о занятии и все соответсвующие оценки будут удалены").then(function () {
                LessonLog.delete({id: vm.entity.id}, function () {
                    $state.go('^');
                });
            });
        };

        var unsubscribe = $rootScope.$on('supplyApp:lessonLogUpdate', function(event, result) {
            vm.entity = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
