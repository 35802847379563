(function() {
    'use strict';
    angular
        .module('supplyApp.common')
        .factory('Bill', Bill);

    Bill.$inject = ['$resource'];

    function Bill ($resource) {
        var resourceUrl =  'api/bills/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
            },
            'forPupil': {
                method: 'GET',
                url: 'api/bills/pupil/:pupilId',
            },
            'update': { method:'PUT' },
            'refresh': { 
                method:'GET',
                url: resourceUrl + '/refresh',
            },
            'refreshAll': { 
                method:'GET',
                url: 'api/bills/refresh-now',
            },
            'exportNew': {
                method: 'GET',
                url: 'api/bills/export/new/:regionId',
            },
            'reExportAll': {
                method: 'GET',
                url: 'api/bills/reexport/:regionId',
            }

        });
    }
})();
