(function () {
    'use strict';

    angular
        .module('supplyApp.common')
        .factory('PaginationUtil', PaginationUtil);

    PaginationUtil.$inject = ['paginationConstants'];

    function PaginationUtil(paginationConstants) {

        var service = {
            parseAscending : parseAscending,
            parsePage : parsePage,
            parsePredicate : parsePredicate,
            buildPageParams: buildPageParams,
            buildPreviousState: buildPreviousState,
            sort : sort,
        };

        return service;

        function buildPreviousState($state, $stateParams, parentState) {
            if ($stateParams.prevState) {
                return angular.fromJson($stateParams.prevState);
            }
            return {
                name: $state.current.name || parentState,
                params: $state.params,
                url: $state.href($state.current.name, $state.params)
            };
        }

        function buildPageParams($stateParams) {
            return {
                page: parsePage($stateParams.page),
                sort: $stateParams.sort,
                predicate: parsePredicate($stateParams.sort),
                ascending: parseAscending($stateParams.sort),
                search: $stateParams.search,
                size: paginationConstants.itemsPerPage,
            };
        }

        function parseAscending (sort) {
            if (!sort) return null;
            var sortArray = sort.split(',');
            if (sortArray.length > 1){
                return sort.split(',').slice(-1)[0] === 'asc';
            } else {
                // default to true if no sort defined
                return true;
            }
        }

        // query params are strings, and need to be parsed
        function parsePage (page) {
            return parseInt(page);
        }

        // sort can be in the format `id,asc` or `id`
        function parsePredicate (sort) {
            if (!sort) return null;
            var sortArray = sort.split(',');
            if (sortArray.length > 1){
                sortArray.pop();
            }
            return sortArray.join(',');
        }

        function sort(pagingParams, defaultSort) {
            if (angular.isUndefined(defaultSort)) {
                defaultSort = 'id';
            }
            var result = [pagingParams.predicate + ',' + (pagingParams.ascending ? 'asc' : 'desc')];
            if (pagingParams.predicate !== defaultSort) {
                result.push(defaultSort);
            }
            return result;
        }
    }
})();
