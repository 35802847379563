(function () {
    'use strict';

    angular
        .module('supplyApp.common')
        .directive('tFilter', tFilter);

    tFilter.$inject = ['$timeout'];

    function tFilter($timeout) {
        var directive = {
            restrict: 'E',
            scope: {
                doSearch: '&onSearch',
                doReset: '&onReset',
                filterModel: "=model",
                placeholder: "@"
            },
            link: linkFunc,
            replace: true,
            template: '<span class="table-fitler input-group">' +
                '<input type="text" ng-model="filterModel" ng-keypress="onKey($event)" placeholder="{{placeholder}}"/>' +
                '<span class="input-group-btn"><span class="btn-x" ng-show="showClr" ng-click="clear()"></span></span>' +
                '</span>',
        };

        return directive;

        function linkFunc(scope, element, attrs) {
            scope.showClr = !!scope.filterModel;

            scope.clear = function () {
                scope.filterModel = null;
                scope.showClr = false;
                $timeout(function () {
                    scope.doReset();
                }, 200);
            };

            scope.onKey = function (keyEvent) {
                if (keyEvent.which === 13) {
                    scope.doSearch();
                }
            };

            scope.$watch('filterModel', function (newValue, oldValue) {
                if (newValue !== oldValue) {
                    scope.showClr = (!!newValue);
                }
            }, true);

        }

    }

})();
