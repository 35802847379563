(function() {
    'use strict';

    angular
        .module('supplyApp')
        .controller('PupilBalanceController', PupilBalanceController);

    PupilBalanceController.$inject = ['$uibModalInstance', 'Pupil', 'entity'];

    function PupilBalanceController($uibModalInstance, Pupil, entity) {
        var vm = this;

        vm.pupil = entity;
        vm.clear = clear;
        vm.save = save;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            Pupil.updateBalance({'id': vm.pupil.id, 'amount': vm.pupil.balance},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
