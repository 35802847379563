(function () {
    'use strict';

    angular
        .module('supplyApp.common')
        .service('UserRolesService', UserRolesService);

    UserRolesService.$inject = ['Principal'];

    function UserRolesService(Principal) {

        var ROLES = {
            USER: { name: 'Ученик', role: 'ROLE_USER'},
            SCH_USER : { name: 'Учитель', role: 'ROLE_SCH_USER'},
            SCH_ACC : { name: 'Зав производством', role: 'ROLE_SCH_ACC'},
            SCH_ADMIN : { name: 'Администратор школы', role: 'ROLE_SCH_ADMIN'},
            BASE_ACC: { name: 'Бухгалтерия Региона', role: 'ROLE_BASE_ACC'},
            BASE_ADMIN : { name: 'Администратор Региона', role: 'ROLE_BASE_ADMIN'},
            ADMIN: { name: 'СуперАдмин', role: 'ROLE_ADMIN'}
        };

        return {
            getAllowedAuthorities: getAllowedAuthorities,
            getAllAuthorities: getAllAuthorities,
            getAllAuthorityKeys : getAllAuthorityKeys,
            getAdminAuthorities : getAdminAuthorities,
            isTeacher : isTeacher,
            isSchoolAсс : isSchoolAсс,
            isBaseAcc : isBaseAcc,
            ROLES: ROLES,
        };

        function isTeacher() {
            return checkRole(ROLES.SCH_USER);
        }

        function isSchoolAсс() {
            return checkRole(ROLES.SCH_ACC);
        }

        function isBaseAcc() {
            return checkRole(ROLES.BASE_ACC);
        }

        function isSchoolAdmin() {

        }

        function isAdmin() {

        }

        function checkRole(roleObj) {
            return Principal.hasAnyAuthority([roleObj.role]);
        }

        function getAllAuthorities() {
            var authorities = [];
            for (var key in ROLES) {
                if (ROLES.hasOwnProperty(key)) {
                    authorities.push(ROLES[key]);
                }
            }
            return authorities;
        }

        function getAllAuthorityKeys() {
            var authorities = [];
            for (var key in ROLES) {
                if (ROLES.hasOwnProperty(key)) {
                    authorities.push(ROLES[key].role);
                }
            }
            return authorities;
        }


        function getAdminAuthorities() {
            if (Principal.hasAnyAuthority(['ROLE_ADMIN'])) {
                return [
                    ROLES.SCH_USER,
                    ROLES.SCH_ACC,
                    ROLES.SCH_ADMIN,
                    ROLES.BASE_ACC,
                    ROLES.BASE_ADMIN,
                    ROLES.ADMIN
                ];
            }
        }

        function getAllowedAuthorities() {
            if (Principal.hasAnyAuthority(['ROLE_ADMIN'])) {
                return [
                    ROLES.SCH_USER,
                    ROLES.SCH_ACC,
                    ROLES.SCH_ADMIN,
                    ROLES.BASE_ACC,
                    ROLES.BASE_ADMIN
                ];
            }

            if (Principal.hasAnyAuthority(['ROLE_SCH_ADMIN'])) {
                return [
                    ROLES.SCH_USER
                ];
            }

            if (Principal.hasAnyAuthority(['ROLE_BASE_ADMIN'])) {
                return [
                    ROLES.SCH_USER,
                    ROLES.SCH_ACC,
                    ROLES.SCH_ADMIN,
                    ROLES.BASE_ACC
                ];
            }
        }
    }
})();
