(function() {
    'use strict';

    angular
        .module('supplyApp.common')
        .factory('ReportConfig', ReportConfig);

    function ReportConfig() {
        var periodFilter, school;
        var service = {
            reset: init,
            setPeriod : function (val) {periodFilter = val;},
            getPeriod : function () {return periodFilter;},
            setSchool : function (val) {school = val;},
            getSchool : function () {return school;},
            filterConfig : {
                items : [
                    {key:"month", label: "За текущий месяц"},
                    {key:"prev_month", label : "За прошлый месяц"},
                    {key:"week", label : "За последние две недели"},
                ],
                custom: true,
            },
            validateRange: function(period, dateFrom, dateTo) {
                if (period === 'custom' && dateFrom) {
                    if (!dateTo) {
                        dateTo = new Date();
                    }
                    var diff = monthDiff(dateFrom, dateTo);
                    if (Math.abs(diff) > 2) {
                        return 'Период не должен превышать 2-x месяцев';
                    }
                }
                return undefined;
            }
        };

        function init() {
            periodFilter = {};
            periodFilter.period = 'month';
            school = null;
        }

        function monthDiff(d1, d2) {
            var months = (d2.getFullYear() - d1.getFullYear()) * 12;
            return months - d1.getMonth() + d2.getMonth();
        }

        init();
        return service;
    }


})();
