(function() {
    "use strict";

    angular
        .module("supplyApp")
        .controller("LessonLogEntryController", LessonLogEntryController);

    LessonLogEntryController.$inject = ["$scope", "$state", "$stateParams", "Modal", "Dialogs", "Principal", "AlertService", "LessonLog", "lessonLog"];

    function LessonLogEntryController($scope, $state, $stateParams, Modal, Dialogs, Principal, AlertService, LessonLog, lessonLog) {
        var vm = this;

        vm.busy = false;
        vm.dirty = false;
        vm.valid = true;
        vm.options = {
            doubleGrade: false
        };

        vm.save = save;
        vm.setMarkAbsent = setMarkAbsent;
        vm.setMarkSkip = setMarkSkip;
        vm.setMarkReleased = setMarkReleased;
        vm.onInputChange = onInputChange;
        vm.onGradeInputChange = onGradeInputChange;
        vm.isItemDirty = isItemDirty;

        vm.goBack = function() {
            if (vm.isTeacher) {
                $state.go('lesson-mark-user-classes');
            } else {
                $state.go('^');
            }
        };

        init(lessonLog);

        $scope.$on('authenticationSuccess', function () {
            vm.goBack();
        });

        function init(log) {
            vm.isTeacher = Principal.hasAnyAuthority(['ROLE_SCH_USER']);
            if (vm.isTeacher) {
                vm.canManage = Principal.current().login === log.lesson.teacher.login;
            } else {
                vm.canManage = Principal.hasAnyAuthority(['ROLE_SCH_ADMIN', 'ROLE_ADMIN']);
            }
            vm.entity = prepareEntityState(log);
        }

        function setMarkAbsent(item) {
            setMark(item, 'A');
        }

        function setMarkSkip(item) {
            setMark(item, 'S');
        }

        function setMarkReleased(item) {
            setMark(item, 'R');
        }

        function setMark(item, type) {
            if (item.mark) {
                if (item.mark.type === type) {
                    item.mark.type = null;
                    item.feedback = '';
                } else {
                    item.mark.type = type;
                }
            } else {
                item.mark = {
                    type: type
                };
            }

            if (item.mark.type !== null) {
                item.grade.value = null;
                item.gradeSecondary.value = null;
                item.feedback = getMarkFeedback(item.mark);
            }

            vm.dirty = isDirty();
        }

        function onInputChange() {
            vm.dirty = isDirty();
        }

        function onGradeInputChange(pupilGrade, $event) {
            var isValid = true;
            var textVal = pupilGrade.feedback.trim();
            if (textVal === '') {
                pupilGrade.grade.value = null;
                pupilGrade.gradeSecondary.value = null;
            }

            var grades = textVal.split('/');
            if (grades.length === 1) {
                isValid = validateGradeAndSet(pupilGrade.grade, grades[0]);
                pupilGrade.gradeSecondary.value = null;
            } else if (grades.length === 2) {
                isValid = validateGradeAndSet(pupilGrade.grade, grades[0]);
                isValid = isValid && validateGradeAndSet(pupilGrade.gradeSecondary, grades[1]);
            } else {
                isValid = false;
            }

            pupilGrade.valid = isValid;

            if (!vm.dirty) {
                vm.dirty = isItemDirty(pupilGrade);
            } else {
                vm.dirty = isDirty();
            }
            vm.valid = isFormValid(vm.entity);
        }

        function validateGradeAndSet(pupilGradeItem, val) {
            if (val === '') {
                pupilGradeItem.value = null;
                return true;
            }
            var grade = Number(val.trim());
            if (!isNaN(grade) && grade >=0 && grade <= 10) {
                pupilGradeItem.value = grade;
                return true;
            }
            return false;
        }

        function prepareEntityState(entity) {
            if (vm.scheduleForm) {
                vm.scheduleForm.$setPristine();
            }
            entity.originTitle = entity.title;
            entity.originHomework = entity.homework;

            initOriginGradesState(entity);
            refreshFeedback(entity);

            return entity;
        }

        function initOriginGradesState(entity) {
            var hasDoubleGrade = false;

            angular.forEach(entity.pupilGrades, function (item) {
                if (!item.mark) { item.mark = {type:null}; }
                if (!item.grade) { item.grade = {value:null}; }
                if (!item.gradeSecondary) { item.gradeSecondary = {value:null}; }

                item.originMark = item.mark.type;
                item.originGrade = item.grade.value;
                item.originGradeSecondary = item.gradeSecondary.value;
                item.originComment = item.comment;

                if (item.gradeSecondary.value !== null) {
                    hasDoubleGrade = true;
                }

                item.valid = true;
            });

            vm.options.doubleGrade = hasDoubleGrade;
        }

        function resetSecondGrades(entity) {
            angular.forEach(entity.pupilGrades, function (item) {
                item.gradeSecondary.value = null;
            });
        }

        function refreshFeedback(entity) {
            angular.forEach(entity.pupilGrades, function (item) {
                item.feedback = gradeItemFeedback(item);
            });
        }

        function gradeItemFeedback(item) {
            var feedback = item.grade.value !== null ? item.grade.value : '';
            if (item.mark.type !== null) {
                feedback += getMarkFeedback(item.mark);
            } else if (vm.options.doubleGrade) {
                feedback += '/';
                feedback += item.gradeSecondary.value !== null ? item.gradeSecondary.value : '';
            }
            return feedback;
        }

        function getMarkFeedback(mark) {
            var feedback = '';
            if (mark !== null && mark.type !== null) {
                if (mark.type === 'A') {
                    feedback = 'неув.';
                } else if (mark.type === 'S') {
                    feedback = 'уваж.';
                } else if (mark.type === 'R') {
                    feedback = 'осв.';
                } else {
                    feedback = '-';
                }
            }
            return feedback;
        }

        function isDirty() {
            return vm.entity.originTitle !== vm.entity.title
            || vm.entity.originHomework !== vm.entity.homework
            || vm.entity.pupilGrades.some(function (pupil) {
                return isPupilRecordDirty(pupil);
            });
        }

        function isItemDirty(item) {
            return item.originMark !== (item.mark && item.mark.type)
                || item.originGrade !== (item.grade && item.grade.value)
                || item.originGradeSecondary !== (item.gradeSecondary && item.gradeSecondary.value)
                || isPupilRecordCommentDirty(item);
        }

        function isPupilRecordDirty(pupil) {
            return pupil.originMark !== (pupil.mark && pupil.mark.type)
                || pupil.originGrade !== (pupil.grade && pupil.grade.value)
                || pupil.originGradeSecondary !== (pupil.gradeSecondary && pupil.gradeSecondary.value)
                || isPupilRecordCommentDirty(pupil);
        }

        function isPupilRecordCommentDirty(pupil) {
            return (
                pupil.mark.type !== null
                    || pupil.grade.value !== null
                    || pupil.gradeSecondary.value !== null)
                && (
                    pupil.originComment !== pupil.comment
                    && !(pupil.originComment === null && pupil.comment === ""));
        }

        function isFormValid(entity) {
            var hasInvalid = vm.entity.pupilGrades.some(function (pupil) {
                return pupil.valid === false;
            });
            return !hasInvalid;
        }

        function save () {
            vm.busy = true;
            if (vm.entity.id !== null) {
                LessonLog.updateGrades(vm.entity, onSaveSuccess, onSaveError);
            } else {
                LessonLog.saveGrades(vm.entity, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            vm.busy = false;
            vm.dirty = false;
            if (vm.entity.id === null) {
                $state.go('lesson-mark-log', {id:$stateParams.id, logId:result.id });
            } else {
                init(result);
            }
        }

        function onSaveError (error) {
            vm.busy = false;
            AlertService.error(error.data.message);
        }

        vm.gradePopup = function(pupilGrade) {
            return Modal.open(
                'app/ui/schedule/lesson-mark/lesson-log-grade.modal.html',
                'LessonGradeModalController',
                {
                    entity: pupilGrade,
                    options: vm.options
                },
                'sm'
            ).then(onGradePopupClose, onGradePopupClose);
        };

        function onGradePopupClose() {
            vm.dirty = isDirty();
            if (!vm.options.doubleGrade) {
                resetSecondGrades(vm.entity);
            }
            refreshFeedback(vm.entity);
        }

    }
})();
