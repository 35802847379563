(function() {
    'use strict';

    angular
        .module('supplyApp')
        .controller('LessonController', LessonController);

    LessonController.$inject = ['$scope', '$state', 'Nav', 'Lesson', 'Dialogs', 'Principal', 'AlertService', 'PaginationUtil', 'School', 'pagingParams'];

    function LessonController ($scope, $state, Nav, Lesson, Dialogs, Principal, AlertService, PaginationUtil, School, pagingParams) {
        var vm = this;

        vm.pagingParams = pagingParams;

        vm.filter = pagingParams.search ? angular.fromJson(pagingParams.search) : {};
        vm.showFilter = !!(vm.filter.schoolId || vm.filter.subjectId || vm.filter.teacherId  || vm.filter.clazzId || vm.filter.retired);
        vm.filtersLoaded = false;

        vm.doSearch = doSearch;
        vm.resetSearch = resetSearch;
        vm.deleteLesson = deleteLesson;

        $scope.$on('authenticationSuccess', function () {
            init();
        });

        $scope.$watch('vm.showFilter', function(newVal) {
            if (newVal && !vm.filtersLoaded) {
                loadFilters();
            }
        });

        $scope.$watch('vm.filter.schoolId', function(newVal) {
            if (newVal) {
                loadSchoolFilters(newVal);
            }
        });

        init();

        function init() {
            vm.canSelectSchool = Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_BASE_ACC', 'ROLE_BASE_ADMIN']);
            doLoad();
        }

        function doLoad() {
            vm.searching = true;
            Lesson.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: PaginationUtil.sort(pagingParams),
                search: vm.filter,
            }, onSuccess, onError);
        }

        function loadFilters() {
            if (vm.canSelectSchool) {
                vm.schools = School.all();
            } else {
                var schoolId = vm.filter.schoolId || Principal.current().school.id;
                loadSchoolFilters(schoolId);
            }
            vm.filtersLoaded = true;
        }

        function loadSchoolFilters(schoolId) {
            vm.clazzes = School.clazzes({id:schoolId});
            vm.teachers = School.teachers({id:schoolId});
            vm.subjects = School.subjects({id:schoolId});
        }

        function doSearch() {
            Nav.search(pagingParams, vm.filter);
        }

        function resetSearch() {
            vm.filter = {};
            vm.searching = false;
            doSearch();
        }

        function onSuccess(data, headers) {
            //vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.lessons = data;
            vm.page = pagingParams.page;
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        function deleteLesson(lesson) {
            Dialogs.warn("Если занятие еще не имеет оценок, то запись будет удалена. Иначе, занятие будет отправлено в архив").then(function () {
                Lesson.delete({id: lesson.id}, function () {
                    $state.go('lesson', null, { reload: 'lesson' });
                }, function (error) {
                    AlertService.error("Ошибка при удалении занятия: " + error.data.message);
                });
            });
        }

    }
})();
