(function () {
    "use strict";

    angular
        .module("supplyApp")
        .controller("SchoolFinReportsController", SchoolFinReportsController);

    SchoolFinReportsController.$inject = ['$scope', '$state', 'Report', 'Modal', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'entity'];

    function SchoolFinReportsController($scope, $state, Report, Modal, ParseLinks, AlertService, paginationConstants, pagingParams, entity) {
        var vm = this;

        vm.school = entity;
        vm.pagingParams = pagingParams;
        vm.items = [];

        loadAll();

        $scope.$on('authenticationSuccess', function () {
            loadAll();
        });

        function loadAll() {
            Report.schoolFin({
                id: vm.school.id,
                page: pagingParams.page - 1,
                size: paginationConstants.itemsPerPage
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.items = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        vm.showFinReport = function (reportItem) {
            return Modal.open(
                'app/ui/report/finance/fin-school-report-modal.html',
                'SchoolFinReportModalController',
                {
                    'school': vm.school,
                    'report': angular.fromJson(reportItem.value)
                }
            );
        };
    }
})();
