(function() {
    'use strict';
    angular
        .module('supplyApp.common')
        .factory('FoodCost', FoodCost);

    FoodCost.$inject = ['$resource', 'DateUtils'];

    function FoodCost ($resource, DateUtils) {
        var resourceUrl = "api/food/costs/:id";

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': { method: "GET" },
            'save': { method: "POST" },
            'update': { method: "PUT" },
            'forceUpdate': {
                url: resourceUrl + "/force",
                method: "PUT"
            },
            'joinTypes': {
                method: 'GET',
                url: 'api/food/costs/joinTypes',
                isArray: true
            }
        });
    }
})();
