(function () {
    'use strict';

    angular
        .module('supplyApp')
        .controller('AtdSchoolClassesController', AtdSchoolClassesController);

    AtdSchoolClassesController.$inject = ['$scope', '$state', '$stateParams', 'ParseLinks', 'AlertService', 'Principal', 'pagingParams', 'School'];

    function AtdSchoolClassesController($scope, $state, $stateParams, ParseLinks, AlertService, Principal, pagingParams, School) {
        var vm = this;

        vm.pagingParams = pagingParams;

        vm.filter = pagingParams.search ? JSON.parse(pagingParams.search) : {};
        vm.showFilter = !!(vm.filter.schools || vm.filter.name);

        vm.doSearch = doSearch;
        vm.resetSearch = resetSearch;

        init();

        $scope.$on('authenticationSuccess', function () {
            init();
        });

        function init() {
            vm.isRegionUser = Principal.hasAnyAuthority(['ROLE_BASE_ACC','ROLE_BASE_ADMIN','ROLE_ADMIN']);
            doSearch();
        }

        function doSearch() {
            School.clazzes({
                id: $stateParams.id,
                page: pagingParams.page - 1,
                size: pagingParams.itemsPerPage,
                sort: sort(),
                search: vm.filter
            }, onSuccess, onError);
            vm.searching = true;
        }

        function resetSearch() {
            vm.filter = {};
            doSearch();
        }

        function sort() {
            var result = [pagingParams.predicate + ',' + (pagingParams.ascending ? 'asc' : 'desc')];
            if (pagingParams.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.clazzes = data;
            vm.page = pagingParams.page;
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }
    }
})();
