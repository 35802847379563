(function () {
     'use strict';
    angular
        .module('supplyApp.config', []);

    
    angular
        .module('supplyApp.config')
        .config(['uibDatepickerConfig', 'uibDatepickerPopupConfig', function(uibDatepickerConfig, uibDatepickerPopupConfig) {
            
            uibDatepickerConfig.showWeeks = false;

            uibDatepickerPopupConfig.datepickerPopup= 'dd-MM-yyyy';
            uibDatepickerPopupConfig.closeText = 'Закрыть';
            uibDatepickerPopupConfig.clearText = 'Сброс';
            uibDatepickerPopupConfig.currentText = 'Сегодня';
        }
    ]);
 
     angular
        .module('supplyApp.config')
        .run(['$rootScope', '$uibModalStack', function ($rootScope, $uibModalStack) {
            /*
            If you are using ui-router, use $stateChangeStart method otherwise use $locationChangeStart
            StateChangeStart will trigger as soon as the user clicks browser back button or keyboard backspace and modal will be removed from modal stack
            */
            $rootScope.$on('$stateChangeStart', function (event) {
                var top = $uibModalStack.getTop();
                if (top) {
                    $uibModalStack.dismiss(top.key);
                }
            });
        }
    ]);

 })();