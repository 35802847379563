(function() {
    'use strict';

    angular
        .module('supplyApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('import-manual', {
            parent: 'import',
            url: '/m?page&sort&search',
            data: {
                authorities: ['ROLE_BASE_ADMIN', 'ROLE_ADMIN']
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            views: {
                'content@': {
                    templateUrl: 'app/ui/import/manual/imports.html',
                    controller: 'ImportsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return PaginationUtil.buildPageParams($stateParams);
                }],
                account: ['Principal', function (Principal) {
                    return Principal.identity(true);
                }]
            }
        }).
        state('import-manual.detail', {
            parent: 'import',
            url: '/m/{id}?page&sort&prevState',
            data: {
                authorities: ['ROLE_BASE_ADMIN', 'ROLE_ADMIN'],
                pageTitle: 'Детали загрузки'
            },
            views: {
                'content@': {
                    templateUrl: 'app/ui/import/manual/import-detail.html',
                    controller: 'ImportDetailController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                }
            },
            resolve: {
                entity: ['$stateParams', 'ImportHistory', function($stateParams, ImportHistory) {
                    return ImportHistory.get({id : $stateParams.id}).$promise;
                }],
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return PaginationUtil.buildPageParams($stateParams);
                }],
                previousState: ["$state", "$stateParams", function ($state, $stateParams) {
                    if($stateParams.prevState) {
                        return JSON.parse($stateParams.prevState);
                    }

                    var currentStateData = {
                        name: $state.current.name || 'import-manual',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };

                    if (currentStateData.name !== 'import-manual.detail') {
                        $stateParams.prevState = JSON.stringify({
                            name: currentStateData.name,
                            params: currentStateData.params
                        });
                    }
                    return currentStateData;
                }]
            }
        })
        .state('import-manual.delete', {
            parent: 'import-manual',
            url: '/delete/{id}',
            data: {
                authorities: ['ROLE_BASE_ADMIN', 'ROLE_ADMIN'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/ui/import/manual/import-delete-dialog.html',
                    controller: 'ImportHistoryDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['ImportHistory', function(ImportHistory) {
                            return ImportHistory.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('import-manual', null, { reload: 'import-manual' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }
})();
