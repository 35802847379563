(function () {
    'use strict';

    angular
        .module('supplyApp')
        .controller('SchoolController', SchoolController);

    SchoolController.$inject = ['$scope', '$state', 'School', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams'];

    function SchoolController($scope, $state, School, ParseLinks, AlertService, paginationConstants, pagingParams) {
        var vm = this;

        vm.pagingParams = pagingParams;
        vm.filter = pagingParams.search ? JSON.parse(pagingParams.search) : {};

        vm.resetSearch = resetSearch;
        vm.doSearch = loadAll;

        $scope.$on('authenticationSuccess', function () {
            loadAll();
        });

        loadAll();

        function loadAll() {
            School.query({
                page: pagingParams.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort(),
                search: vm.filter
            }, onSuccess, onError);
            function sort() {
                var result = [pagingParams.predicate + ',' + (pagingParams.ascending ? 'asc' : 'desc')];
                if (pagingParams.predicate !== 'num') {
                    result.push('num');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.schools = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function resetSearch() {
            vm.filter = {};
            loadAll();
        }

    }
})();
