(function() {
    'use strict';

    angular
        .module('supplyApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('clazz', {
            parent: 'entity',
            url: '/clazz?page&sort&search',
            data: {
                authorities: [],
                pageTitle: 'Список классов'
            },
            views: {
                'content@': {
                    templateUrl: 'app/ui/entity/clazz/clazzes.html',
                    controller: 'ClazzController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return PaginationUtil.buildPageParams($stateParams);
                }],
            }
        })
        .state('clazz-detail', {
            parent: 'entity',
            url: '/clazz/{id}?page&sort&prevState',
            data: {
                authorities: [],
                pageTitle: 'Класс'
            },
            views: {
                'content@': {
                    templateUrl: 'app/ui/entity/clazz/clazz-detail.html',
                    controller: 'ClazzDetailController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'guid,asc',
                    squash: true
                }
            },
            resolve: {
                entity: ['$stateParams', 'Clazz', function($stateParams, Clazz) {
                    return Clazz.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", '$stateParams', 'PaginationUtil', function ($state, $stateParams, PaginationUtil) {
                    return PaginationUtil.buildPreviousState($state, $stateParams, 'clazz');
                }],
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return PaginationUtil.buildPageParams($stateParams);
                }],
            }
        })
        .state('clazz-detail.pupil', {
            parent: 'clazz-detail',
            url: '/pupil/new',
            data: {
                authorities: ['ROLE_SCH_USER', 'ROLE_SCH_ADMIN', 'ROLE_ADMIN', 'ROLE_BASE_ADMIN'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', 'entity', function($stateParams, $state, $uibModal, entity) {
                $uibModal.open({
                    templateUrl: 'app/ui/entity/pupil/pupil-dialog.html',
                    controller: 'AddPupilDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        newPupil: function () {
                            return {
                                guid: null,
                                name: null,
                                address: null,
                                phone: null,
                                notes: null,
                                regDate: null,
                                updDate: null,
                                id: null,
                                clazz: entity
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('clazz-detail.edit', {
            parent: 'clazz-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_SCH_ADMIN', 'ROLE_SCH_ACC', 'ROLE_ADMIN', 'ROLE_BASE_ADMIN'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/ui/entity/clazz/clazz-dialog.html',
                    controller: 'ClazzDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Clazz', function(Clazz) {
                            return Clazz.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('clazz.new', {
            parent: 'clazz',
            url: '/new',
            data: {
                authorities: ['ROLE_SCH_ADMIN', 'ROLE_ADMIN', 'ROLE_BASE_ADMIN'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/ui/entity/clazz/clazz-dialog.html',
                    controller: 'ClazzDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Principal', function (Principal) {
                            return {
                                name: null,
                                updDate: null,
                                id: null,
                                school: Principal.current().school
                            };
                        }]
                    }
                }).result.then(function() {
                    $state.go('clazz', null, { reload: 'clazz' });
                }, function() {
                    $state.go('clazz');
                });
            }]
        })
        .state('clazz.edit', {
            parent: 'clazz',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_SCH_ADMIN', 'ROLE_SCH_ACC', 'ROLE_ADMIN', 'ROLE_BASE_ADMIN'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/ui/entity/clazz/clazz-dialog.html',
                    controller: 'ClazzDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Clazz', function(Clazz) {
                            return Clazz.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('clazz', null, { reload: 'clazz' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('clazz.delete', {
            parent: 'clazz',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/ui/entity/clazz/clazz-delete-dialog.html',
                    controller: 'ClazzDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Clazz', function(Clazz) {
                            return Clazz.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('clazz', null, { reload: 'clazz' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
