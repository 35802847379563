(function() {
    'use strict';

    angular
        .module('supplyApp.common')
        .controller('SidebarController', SidebarController);

    SidebarController.$inject = ['$state', 'Auth', 'Principal', 'ProfileService', 'LoginService', 'Clazz'];

    function SidebarController($state, Auth, Principal, ProfileService, LoginService, Clazz) {
        var vm = this;

        vm.isAuthenticated = Principal.isAuthenticated;
        vm.isNavbarCollapsed = true;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;

        vm.$state = $state;

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }
    }
})();
