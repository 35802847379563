(function () {
    'use strict';

    angular
        .module('supplyApp')
        .controller('SchoolTeachersImportController', SchoolTeachersImportController);

    SchoolTeachersImportController.$inject = ['$scope', 'Upload', 'entity', '$uibModalInstance'];

    function SchoolTeachersImportController($scope, Upload, entity, $uibModalInstance) {
        var vm = this;

        vm.files = null;
        vm.school = entity;
        vm.log = '';

        vm.close = close;

        function upload(files) {
            if (!files || files.$error) {
                return;
            }

            angular.forEach(files, function (file) {
                file.msg = '';
                Upload.upload({
                    url: 'api/schools/' + vm.school.id + '/teachers/upload',
                    data: {
                        file: file
                    }
                }).then(function (resp) {
                        if (resp.data) {
                            file.status = resp.data.importStatus;
                            file.messages = resp.data.message ? resp.data.message.split(';') : [];
                        }
                    },
                    function (response) {
                        if (response.status > 0) {
                            files.msg = response.status + ': ' + response.data;
                        }
                    },
                    function (evt) {
                        file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
                    });
            });
        }

        function close() {
            $uibModalInstance.close(true);
        }

        $scope.$watch('vm.files', function () {
            upload(vm.files);
        });
    }
})();
