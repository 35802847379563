(function () {
'use strict';

angular.module('supplyApp.common')
    .directive('helpLink', function () {
        return {
            restrict: 'E',
            scope: {
                url: '@',
            },
            replace: true,
            template: '<div class="minor-link-btn"> \
                    <a href="https://sites.google.com/view/crmsupply/{{url}}" target="_blank" class="minor-link"> \
                        <span class="glyphicon glyphicon-question-sign"></span> \
                        <span class="hidden-xs hidden-sm"> Помощь</span> \
                    </a> \
                </div>'
        }
    });



angular.module('supplyApp.common')
    .directive('balance', function () {
        return {
            restrict: 'EA',
            scope: {
                amount: '@',
            },
            replace: true,
            template: '<span class="blnc" ng-class="{\'debt\':amount<0}">{{amount ? amount : 0 | currency: "р."}}</span>'
        }
    });

})();