(function() {
    'use strict';
    angular
        .module('supplyApp.common')
        .factory('Cabinet', Cabinet);

    Cabinet.$inject = ['$resource', 'DateUtils'];

    function Cabinet ($resource, DateUtils) {
        var resourceUrl =  'api/pupils/:id';

        return $resource(resourceUrl, {}, {
            'getPupil': {
                url: 'api/cabinet/current/pupil',
                method: 'GET',
            },
            'getAttendance': {
                url: 'api/cabinet/current/attendance',
                method: 'GET',
            },
            'getJournal': {
                url: 'api/cabinet/current/journal',
                method: 'GET',
                isArray: true,
            },
            'getPayts': {
                url: 'api/cabinet/current/payts',
                method: 'GET',
                isArray: true,
            },
            'getUser': {
                url: 'api/cabinet/pupil/:id/user',
                method:'GET',
            },
            'create': {
                url: 'api/cabinet/pupil/:pupilId/user',
                method:'POST',
                params: {
                    pupilId: '@pupilId',
                }
            },
            'update': {
                url: 'api/cabinet/pupil/:pupilId/user',
                method:'PUT',
                params: {
                    pupilId: '@pupilId',
                }
            },
            'changePassword': {
                url: 'api/cabinet/pupil/:pupilId/user/change_password',
                method: 'POST',
                params: {
                    pupilId: '@pupilId',
                }
            },

        });
    }
})();
