(function () {
    'use strict';

    angular
        .module('supplyApp.common')
        .factory('notificationInterceptor', notificationInterceptor);

    notificationInterceptor.$inject = ['$q', 'AlertService'];

    function notificationInterceptor($q, AlertService) {
        var msgs = {};
        var service = {
            response: response
        };

        initTranslations(msgs);

        return service;

        function response(response) {
            var headers = Object.keys(response.headers()).filter(function (header) {
                return header.indexOf('app-alert', header.length - 'app-alert'.length) !== -1 || header.indexOf('app-params', header.length - 'app-params'.length) !== -1;
            }).sort();
            var alertKey = response.headers(headers[0]);
            if (angular.isString(alertKey) && !skipMessage(alertKey)) {
                AlertService.success(getTranslation(alertKey), {param: response.headers(headers[1])});
            }
            return response;
        }

        function initTranslations() {
            msgs['error.accessDenied'] = "Доступ запрещен. Авторизируйтесь";

            msgs['entity.income.created'] = "Платежи сохранены";
            msgs['entity.subject.created'] = "Предмет успешно добавлен";
            msgs['entity.lesson.created'] = "Занятие добавлено";

            msgs['entity.income.updated'] = "Платежи сохранены";

            msgs['entity.import.deleted'] = "Загрузка успешно удалена";
            msgs['entity.income.deleted'] = "Платежи удалены";
            msgs['entity.subject.deleted'] = "Предмет удален";
            msgs['entity.payt.deleted'] = "Платеж удален";
            msgs['entity.lesson.deleted'] = "Занятие удалено";
            msgs['entity.lessonLog.deleted'] = "Запись о занятии удалена";

            msgs['entity.regionSettings.created'] = "Настройки успешно сохранены";
            msgs['entity.regionSettings.updated'] = "Настройки успешно сохранены";

            msgs['entity.foodType.created'] = "Тип питания успешно добавлен";
            msgs['entity.foodType.updated'] = "Тип питания обновлён";
            msgs['entity.foodType.deleted'] = "Тип питания удалён";

            msgs['entity.foodCost.created'] = "Стоимость успешно добавлена";
            msgs['entity.foodCost.updated'] = "Стоимость успешно обновлена";

            msgs['entity.bill.updated'] = "Счет выставлен";
            msgs['entity.bill.created'] = "Счет выставлен";
            msgs['entity.bill.deleted'] = "Автовыставление счета отменено";

            msgs['entity.attendance.updated'] = "Данные успешно сохранены";
            msgs['entity.password.updated'] = "Пароль успешно обновлен";

            msgs['user.exists'] = "Пользователь с таким логином уже существует";
            msgs['user.email.exists'] = "Пользователь с таким емейлом уже существует";
        }

        function skipMessage(key) {
            return key === 'entity.import.created';
        }

        function getTranslation(key) {
            if (msgs.hasOwnProperty(key)) {
                return msgs[key];
            } else if (key.startsWith('entity.')) {
                if (key.endsWith('.deleted')) {
                    return "Запись удалена";
                } else if (key.endsWith('.updated')) {
                    return "Запись обновлена";
                } else if (key.endsWith('.created')) {
                    return "Запись успешно создана";
                }
            }
            return key;
        }
    }
})();
