(function() {
    'use strict';
    angular
        .module('supplyApp.common')
        .factory('Pupil', Pupil);

    Pupil.$inject = ['$resource', 'DateUtils'];

    function Pupil ($resource, DateUtils) {
        var resourceUrl =  'api/pupils/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
            },
            'details': {
                url: resourceUrl + '/details',
                method: 'GET',
            },
            'update': { method:'PUT' },
            'payts': {
                url: resourceUrl + '/payts',
                method: 'GET',
                isArray: true
            },
            'updateBalance': {
                url: 'api/pupils/:pupilId/balance',
                method:'PUT',
                params: {
                    pupilId: "@id",
                    amount: "@amount",
                }
            }
        });
    }
})();
