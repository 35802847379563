(function () {
    'use strict';

    angular
        .module('supplyApp.common')
        .service("Modal", Modal);

    Modal.$inject = ['$uibModal'];

    function Modal($uibModal) {
        return {
            open: function (pTemplateUrl, pControllerName, pEntity, size) {
                var modalInstance;
                if (!size) { size = 'md'; }
                return new Promise(function (onResolve, onReject) {
                    modalInstance = $uibModal.open({
                        animation: true,
                        templateUrl: pTemplateUrl,
                        controller: pControllerName,
                        controllerAs: 'vm',
                        size: size,
                        resolve: {
                            entity: function () {
                                return pEntity;
                            }
                        }
                    });
                    modalInstance.result.then(onOk, onClose);

                    function onOk() {
                        modalInstance = null;
                        onResolve();
                    }

                    function onClose() {
                        modalInstance = null;
                        onReject();
                    }
                });
            },

            openSimple: function (pTemplateUrl, pEntry) {
                var modalInstance = $uibModal.open({
                    animation: true,
                    templateUrl: pTemplateUrl,
                    size: 'md',
                    controllerAs: 'vm',
                    controller: ["$scope", "$uibModalInstance", function ($scope, $uibModalInstance) {
                        var vm = this;
                        vm.entity = pEntry;
                        vm.close = function close() {
                            $uibModalInstance.close('cancel');
                        };
                    }],
                });
                return modalInstance.result;
            },

            openInline: function (pTemplate, pEntry) {
                var modalInstance = $uibModal.open({
                    animation: true,
                    template: pTemplate,
                    size: 'md',
                    controllerAs: 'vm',
                    controller: ["$scope", "$uibModalInstance", function ($scope, $uibModalInstance) {
                        var vm = this;
                        vm.entity = pEntry;
                        vm.close = function close() {
                            $uibModalInstance.close('cancel');
                        };
                    }],
                });
                return modalInstance.result;
            },

            pupilAdded: function (pupil) {
                var template =
                    '<div class="modal-header"> ' +
                        '<h3 class="modal-title" id="modal-title">Ученик добавлен</h3>' +
                    '</div>' +
                    '<div class="modal-body" id="modal-body">' +
                        '<form class="row">' +
                            '<div class="col-lg-12"><p><span>Новый ученик успешно добавлен:</span></p>' +
                                '<table class="table">' +
                                    '<tr><th>Номер ЕРИП</th><td><span class="text-success">{{vm.entity.guid}}</span></td></tr>' +
                                    '<tr><td>Имя</td><td><span>{{vm.entity.name}}</span></td></tr>' +
                                '</table>' +
                            '</div>' +
                        '</form></div>' +
                    '<div class="modal-footer">' +
                        '<button class="btn btn-primary" type="button" ng-click="vm.close()">Продолжить</button>' +
                    '</div>';
                return this.openInline(template, pupil);
            }

        };
    }
})();
