(function() {
    'use strict';

    angular
        .module('supplyApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('school', {
            parent: 'entity',
            url: '/school?page&sort&search',
            data: {
                authorities: ['ROLE_BASE_ACC','ROLE_BASE_ADMIN','ROLE_ADMIN'],
                pageTitle: 'Список Школ'
            },
            views: {
                'content@': {
                    templateUrl: 'app/ui/entity/school/schools.html',
                    controller: 'SchoolController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'num,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return PaginationUtil.buildPageParams($stateParams);
                }],
            }
        })
        .state('school-detail', {
            parent: 'entity',
            url: '/school/{id}?page&sort&prevState',
            data: {
                authorities: [],
                pageTitle: 'Школа'
            },
            views: {
                'content@': {
                    templateUrl: 'app/ui/entity/school/school-detail.html',
                    controller: 'SchoolDetailController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'name,asc',
                    squash: true
                }
            },
            resolve: {
                entity: ['$stateParams', 'School', function($stateParams, School) {
                    return School.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", '$stateParams', 'PaginationUtil', function ($state, $stateParams, PaginationUtil) {
                    return PaginationUtil.buildPreviousState($state, $stateParams, 'school');
                }],
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return PaginationUtil.buildPageParams($stateParams);
                }],
            }
        })
        .state('school-detail.edit', {
            parent: 'school-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_SCH_ADMIN', 'ROLE_SCH_ACC', 'ROLE_BASE_ACC','ROLE_BASE_ADMIN','ROLE_ADMIN'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/ui/entity/school/school-dialog.html',
                    controller: 'SchoolDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['School', function(School) {
                            return School.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('school-detail.clazz', {
            parent: 'school-detail',
            url: '/clazz/new',
            data: {
                authorities: ['ROLE_SCH_ADMIN', 'ROLE_SCH_ACC', 'ROLE_BASE_ACC','ROLE_BASE_ADMIN','ROLE_ADMIN'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', 'entity', function($stateParams, $state, $uibModal, entity) {
                $uibModal.open({
                    templateUrl: 'app/ui/entity/clazz/clazz-dialog.html',
                    controller: 'AddClazzToSchoolDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        newClazz: function() {
                            return {
                                name: null,
                                updDate: null,
                                id: null,
                                school: entity
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('school.new', {
            parent: 'school',
            url: '/new',
            data: {
                authorities: ['ROLE_BASE_ADMIN','ROLE_ADMIN']

            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/ui/entity/school/school-dialog.html',
                    controller: 'SchoolDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Principal', function (Principal) {
                            return {
                                name: null,
                                updDate: null,
                                id: null,
                                school: Principal.current().school
                            };
                        }]
                    }
                }).result.then(function() {
                    $state.go('school', null, { reload: 'school' });
                }, function() {
                    $state.go('school');
                });
            }]
        })
        .state('school.edit', {
            parent: 'school',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_SCH_ADMIN', 'ROLE_SCH_ACC', 'ROLE_BASE_ACC','ROLE_BASE_ADMIN','ROLE_ADMIN'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/ui/entity/school/school-dialog.html',
                    controller: 'SchoolDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['School', function(School) {
                            return School.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('school', null, { reload: 'school' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('school.delete', {
            parent: 'school',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/ui/entity/school/school-delete-dialog.html',
                    controller: 'SchoolDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['School', function(School) {
                            return School.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('school', null, { reload: 'school' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
