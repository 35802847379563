(function () {
    'use strict';

    angular
        .module("supplyApp")
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('users', {
                parent: 'app',
                url: '/users',
                data: {
                    authorities: ['ROLE_SCH_ADMIN', 'ROLE_BASE_ADMIN', 'ROLE_ADMIN'],
                    pageTitle: 'Управление пользователями'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/ui/users/users.html',
                        controller: 'UsersController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return PaginationUtil.buildPageParams($stateParams);
                    }],
                }
            })
            .state('users.password', {
                parent: 'users',
                url: '/{login}/password',
                data: {
                    authorities: ['ROLE_SCH_ADMIN', 'ROLE_BASE_ADMIN', 'ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/common/components/login/user-passgen-dialog.html',
                        controller: 'UserPassgenController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['User', function (User) {
                                return User.get({login: $stateParams.login}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('^');
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('users.edit', {
                parent: 'users',
                url: '/{login}/edit',
                data: {
                    authorities: ['ROLE_SCH_ADMIN', 'ROLE_BASE_ADMIN', 'ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/ui/users/user-dialog.html',
                        controller: 'UserDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['User', function (User) {
                                return User.get({login: $stateParams.login}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('users', null, {reload: true});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('users.new', {
                parent: 'users',
                url: '/new',
                data: {
                    authorities: ['ROLE_SCH_ADMIN', 'ROLE_BASE_ADMIN', 'ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/ui/users/user-dialog.html',
                        controller: 'UserDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Principal', function (Principal) {

                                var entity = {
                                    id: null, login: null, name: null, email: null,
                                    activated: true, createdBy: null, createdDate: null,
                                    lastModifiedBy: null, lastModifiedDate: null, resetDate: null,
                                    resetKey: null, authorities: ['ROLE_SCH_USER']
                                };

                                Principal.identity().then(function(account) {
                                    entity.region = account.region;
                                });

                                return entity;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('users', null, {reload: true});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('users.delete', {
                parent: 'users',
                url: '/{login}/delete',
                data: {
                    authorities: ['ROLE_SCH_ADMIN', 'ROLE_BASE_ADMIN', 'ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/ui/users/delete-dialog.html',
                        controller: 'UserDeleteController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['User', function (User) {
                                return User.get({login: $stateParams.login});
                            }]
                        }
                    }).result.then(function () {
                        $state.go('users', null, {reload: true});
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }
})();
