(function() {
    'use strict';

    angular
        .module('supplyApp')
        .controller('FoodTypeDeleteController', FoodTypeDeleteController);

    FoodTypeDeleteController.$inject = ['$uibModalInstance', 'entity', 'FoodType'];

    function FoodTypeDeleteController ($uibModalInstance, entity, FoodType) {
        var vm = this;

        vm.foodType = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            FoodType.delete({id: id}, onSuccess, onError);
        }

        function onSuccess() {
            $uibModalInstance.close(true);
        }

        function onError() {
            vm.error = "Невозможно удалить данный тип питания, т.к. по нему уже осуществлялись платежи."
        }
    }
})();
