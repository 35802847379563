(function () {
    'use strict';

    angular
        .module('supplyApp')
        .service('InfographicsService', InfographicsService);

    function InfographicsService() {
        return {
            prepareChartModel: prepareChartModel
        };

        function prepareChartModel(startDate, endDate, paymentStats) {
            var monthNames = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь",
                "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"
            ];
            var counts = [];
            var amounts = [];
            var chartModel = {
                labels: [],
                data: [],
                series: ['Количество', 'Сумма'],
                datasetOverride: [
                    {
                        yAxisID: 'y-axis-1',
                        backgroundColor: 'rgba(0,0,0,0)',
                    },
                    {
                        yAxisID: 'y-axis-2',
                        //backgroundColor: 'rgba(0,0,0,0)'
                    }
                ],
                options: {
                    scales: {
                        yAxes: [{
                          id: 'y-axis-1',
                          stacked: true,
                          position: 'left',
                          gridLines: {
                            display: false,
                            drawBorder: false,
                          }
                        },
                        {
                          id: 'y-axis-2',
                          stacked: true,
                          position: 'right',
                          ticks: {
                            maxTicksLimit: 5,
                          },
                          gridLines: {
                            display: true,
                            drawBorder: false,
                          }
                        }],
                        xAxes: [{
                          gridLines: {
                            display: false,
                            drawBorder: false,
                          }
                        }]
                    }
                },
                colors: ['#fb9677', '#00bfc7', '#9675ce', '#03a9f3', '#feb45c', '#f74649']
            };
            var year = startDate.getFullYear();
            for (var month = startDate.getMonth(); year < endDate.getFullYear() || month < endDate.getMonth(); month++) {
                if (month + 1 == 13) {
                    year++;
                    month = 0;
                }
                chartModel.labels.push(monthNames[month]);
                var hasStats = paymentStats.some(function (monthStat) {
                    if (monthStat.month === month + 1) {
                        counts.push(monthStat.count);
                        amounts.push(monthStat.sum);
                    }
                    return monthStat.month === month + 1;
                });
                if (!hasStats) {
                    counts.push(0);
                    amounts.push(0);
                }
            }
            chartModel.data.push(counts);
            chartModel.data.push(amounts);

            return chartModel;
        }
    }
})();
