(function() {
    'use strict';
    angular
        .module('supplyApp.common')
        .factory('TeacherLesson', TeacherLesson);

    TeacherLesson.$inject = ['$resource'];

    function TeacherLesson ($resource) {
        var resourceUrl =  'api/teacher-lessons/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'all': { method: 'GET', isArray: true},
            'get': { method: 'GET' },
            'update': {
                method:'PUT',
                params: {
                    id: '@id',
                }}
        });
    }
})();
