(function () {
    'use strict';

    angular
        .module("supplyApp")
        .config(stateConfig);

    stateConfig.$inject = ["$stateProvider"];

    function stateConfig($stateProvider) {
        $stateProvider.state('autoimport', {
            parent: 'import',
            url: '/a?page&sort&search',
            data: {
                authorities: ['ROLE_BASE_ADMIN', 'ROLE_ADMIN']
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            views: {
                'content@': {
                    templateUrl: 'app/ui/import/auto/autoimports.html',
                    controller: 'AutoimportsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return PaginationUtil.buildPageParams($stateParams);
                }],
                account: ['Principal', function (Principal) {
                    return Principal.identity(true);
                }]
            }
        }).state('autoimport-detail', {
            parent: 'import',
            url: '/a/{id}?page&sort&prevState',
            data: {
                authorities: ['ROLE_BASE_ADMIN', 'ROLE_ADMIN'],
                pageTitle: 'Детали автозагрузки'
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
            },
            views: {
                'content@': {
                    templateUrl: 'app/ui/import/auto/autoimport-detail.html',
                    controller: 'AutoimportDetailsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'AutoimportHistory', function ($stateParams, AutoimportHistory) {
                    return AutoimportHistory.get({id: $stateParams.id}).$promise;
                }],
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return PaginationUtil.buildPageParams($stateParams);
                }],
                previousState: ["$state", '$stateParams', function ($state, $stateParams) {
                    if($stateParams.prevState) {
                        return JSON.parse($stateParams.prevState);
                    }

                    var currentStateData = {
                        name: $state.current.name || 'autoimport',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };

                    if (currentStateData.name !== 'autoimport-detail') {
                        $stateParams.prevState = JSON.stringify({
                            name: currentStateData.name,
                            params: currentStateData.params
                        });
                    }
                    return currentStateData;
                }]
            }
        }).state('autoimport.delete', {
            parent: 'autoimport',
            url: '/delete/{id}',
            data: {
                authorities: ['ROLE_BASE_ADMIN', 'ROLE_ADMIN'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/ui/import/auto/autoimport-delete-dialog.html',
                    controller: 'AutoimportHistoryDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['AutoimportHistory', function (AutoimportHistory) {
                            return AutoimportHistory.get({id: $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function () {
                    $state.go('autoimport', null, {reload: 'autoimport'});
                }, function () {
                    $state.go('^');
                });
            }]
        }).state('import-detail', {
            parent: 'import',
            url: '/d/{id}?page&sort&prevState',
            data: {
                authorities: ['ROLE_BASE_ADMIN', 'ROLE_ADMIN'],
                pageTitle: 'Детали загрузки'
            },
            views: {
                'content@': {
                    templateUrl: 'app/ui/import/manual/import-detail.html',
                    controller: 'ImportDetailController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                }
            },
            resolve: {
                entity: ['$stateParams', 'ImportHistory', function($stateParams, ImportHistory) {
                    return ImportHistory.get({id : $stateParams.id}).$promise;
                }],
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return PaginationUtil.buildPageParams($stateParams);
                }],
                previousState: ["$state", '$stateParams', function ($state, $stateParams) {
                    if($stateParams.prevState) {
                        return JSON.parse($stateParams.prevState);
                    }

                    var currentStateData = {
                        name: $state.current.name || 'autoimport',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };

                    if (currentStateData.name !== 'import-detail') {
                        $stateParams.prevState = JSON.stringify({
                            name: currentStateData.name,
                            params: currentStateData.params
                        });
                    }

                    return currentStateData;
                }]
            }
        }).state('import.delete', {
            parent: 'autoimport-detail',
            url: '/del/{detailId}',
            data: {
                authorities: ['ROLE_BASE_ADMIN', 'ROLE_ADMIN'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/ui/import/manual/import-delete-dialog.html',
                    controller: 'ImportHistoryDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['ImportHistory', function(ImportHistory) {
                            return ImportHistory.get({id : $stateParams.detailId}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }
})();
