(function () {
    'use strict';

    angular
        .module('supplyApp.common')
        .service('ClazzSettings', ClazzSettings);

    ClazzSettings.$inject = ['$resource'];

    function ClazzSettings($resource) {
        var resourceUrl = 'api/settings/clazz/';

        return $resource(resourceUrl, {}, {
            'getAtdSettings': {
              method: 'GET',
              url: resourceUrl + 'food/:id',
            },
            'saveAtdSettings': {
                method: 'PUT',
                url: resourceUrl + 'food',
            },
        });
    }
})();
