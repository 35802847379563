(function () {
    'use strict';

    angular
        .module('supplyApp')
        .controller('LessonGradeModalController', LessonGradeModalController);

    LessonGradeModalController.$inject = ['$scope', 'entity', '$uibModalInstance'];

    function LessonGradeModalController($scope, entity, $uibModalInstance) {
        var vm = this;
        vm.entity = entity.entity;
        vm.options = entity.options;
        vm.gradeFeedback = '';
        vm.secondGrade = false;
        vm.grades = [];

        $scope.$watch('vm.options.doubleGrade', function(newVal) {
            if (!newVal) {
                vm.entity.gradeSecondary.value = null;
            }
            updateFeedback();
        });

        init();

        function init() {
            for (var i = 0; i <= 10; i++) {
                vm.grades.push(i);
            }
            if (vm.options.doubleGrade && vm.entity.grade.value !== null && vm.entity.gradeSecondary.value === null) {
                vm.secondGrade = true;
            }
        }

        vm.close = function () {
            $uibModalInstance.close(true);
        };

        vm.setMarkAbsent = function(item) {
            setMark(item, 'A');
        };

        vm.setMarkSkip = function(item) {
            setMark(item, 'S');
        };

        vm.setMarkReleased = function(item) {
            setMark(item, 'R');
        };

        function setMark(item, type) {
            if (item.mark) {
                if (item.mark.type === type) {
                    item.mark.type = null;
                } else {
                    item.mark.type = type;
                }
            } else {
                item.mark = {
                    type: type
                };
            }
            if (item.mark.type !== null) {
                item.grade.value = null;
                item.gradeSecondary.value = null;
            }
            updateFeedback();
        }

        vm.setGrade = function (item, val) {
            if (vm.options.doubleGrade) {
                if (vm.secondGrade) {
                    setGradeSecondary(item, val);
                    vm.secondGrade = false;
                } else {
                    setGradePrimary(item, val);
                    vm.secondGrade = true;
                }
            } else {
                setGradePrimary(item, val);
            }
            updateFeedback();
        };

        vm.clearGrades = function(item) {
            item.grade.value = null;
            item.gradeSecondary.value = null;
            item.mark.type = null;
            updateFeedback();
        };

        function setGradePrimary(item, val) {
            item.grade.value = val;
            item.mark.type = null;
        }
        function setGradeSecondary(item, val) {
            item.gradeSecondary.value = val;
            item.mark.type = null;
        }

        function updateFeedback() {
            var feedback = '';
            if (vm.entity.mark.type !== null) {
                switch (vm.entity.mark.type) {
                case 'A':
                    feedback = 'неув.';
                    break;
                case 'S':
                    feedback = 'уваж.';
                    break;
                case 'R':
                    feedback = 'осв.';
                    break;
                default:
                    feedback = '';
                    break;
                }
            } else {
                if (vm.entity.grade.value !== null) {
                    feedback += vm.entity.grade.value;
                }
                if (vm.options.doubleGrade) {
                    feedback += ' / ';
                    if (vm.entity.gradeSecondary.value !== null) {
                        feedback += vm.entity.gradeSecondary.value;
                    }
                }

            }
            vm.gradeFeedback = feedback;
        }
    }
})();
