(function(){
    'use strict';

    angular
        .module('supplyApp.common')
        .filter('translateAuthorities', translateAuthoritiesFilter);

    translateAuthoritiesFilter.$inject = ['UserRolesService'];

    function translateAuthoritiesFilter(UserRolesService) {
        var authTranslations = UserRolesService.getAllAuthorities();

        return function(userAuthorities) {
            var authNames = [];
            userAuthorities.forEach(function(userAuth) {
                authTranslations.some(function(auth) {
                    if (auth.role === userAuth) {
                        authNames.push(auth.name);
                        return true;
                    }
                    return false;
                });
            });
            return authNames;
        };
    }
})();
