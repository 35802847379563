(function () {
    'use strict';

    angular
        .module('supplyApp.common')
        .factory('User', User);

    User.$inject = ['$resource'];

    function User ($resource) {
        var service = $resource('api/users/:login', {}, {
            'query': {method: 'GET', isArray: true},
            'all': {
                url: 'api/users/all',
                method: 'GET', isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'save': { method:'POST' },
            'update': { method:'PUT' },
            'delete':{ method:'DELETE'},
            'changePassword': {
                url: 'api/users/:login/change_password',
                method: 'POST'
            },
            'setRegion':{
                url: 'api/users/current/setRegion',
                method: 'POST',
                params: {
                    region: '@region'
                }
            },
            'createPupil': {
                url: 'api/users/pupil/:pupilId',
                method:'POST',
                params: {
                    pupilId: '@pupilId',
                }
            },
            'pupil': {
                url: 'api/users/pupil/:id',
                method:'GET',
            }
        });

        return service;
    }
})();
