(function () {
    'use strict';

    angular
        .module('supplyApp')
        .controller('AtdRoutingController', AtdRoutingController);

    AtdRoutingController.$inject = ['$scope', '$state', 'Principal', 'AlertService'];

    function AtdRoutingController($scope, $state, Principal, AlertService) {

        if (Principal.hasAnyAuthority(['ROLE_BASE_ACC','ROLE_BASE_ADMIN','ROLE_ADMIN'])) {
            $state.go('atd-schools');
        } else if (Principal.hasAnyAuthority(['ROLE_SCH_ADMIN','ROLE_SCH_ACC',])) {
            Principal.identity().then(function(user) {
                if (user.school) {
                    $state.go('atd-school', {id:user.school.id});
                } else {
                    $state.go('atd-schools');
                }
            });
        } else if (Principal.hasAnyAuthority(['ROLE_SCH_USER'])) {
            $state.go('atd-user-classes');
        }
    }

})();

