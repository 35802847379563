(function () {
    'use strict';

    angular
        .module('supplyApp')
        .controller('SchoolFinReportModalController', SchoolFinReportModalController);

    SchoolFinReportModalController.$inject = ['$scope', 'entity', '$uibModalInstance'];

    function SchoolFinReportModalController($scope, entity, $uibModalInstance) {
        var vm = this;
        vm.report = entity.report;
        vm.school = entity.school;
        vm.close = function () {
            $uibModalInstance.close(true);
        };
    }
})();
