(function() {
    'use strict';

    angular
        .module('supplyApp.common', [
            'ngStorage',
            'ngResource',
            'ngCookies',
            'ngAria',
            'ngCacheBuster',
            'ui.router',
        ]);
        
})();
