(function () {
    'use strict';

    angular
        .module('supplyApp.common')
        .factory('errorHandlerInterceptor', errorHandlerInterceptor);

    errorHandlerInterceptor.$inject = ['$q', '$rootScope', 'AlertService'];

    function errorHandlerInterceptor($q, $rootScope, AlertService) {
        var msgs = {};
        var service = {
            responseError: responseError
        };

        initTranslations(msgs);

        return service;

        function responseError(response) {
            var headers = Object.keys(response.headers()).filter(function (header) {
                return header.indexOf('app-error', header.length - 'app-alert'.length) !== -1 ||
                    header.indexOf('app-params', header.length - 'app-params'.length) !== -1 ||
                    header.indexOf('app-errorkey', header.length - 'app-errorKey'.length) !== -1;
            }).sort();

            var errorKey = response.headers(headers[1]);
            if (response.status < 0) {
                AlertService.error('Связь с сервером потеряна');
            }
            if (!(response.status === 401 && (response.data === '' || (response.data.path && response.data.path.indexOf('/api/account') === 0 )))) {
                if (angular.isString(errorKey) && hasTranslation(errorKey)) {
                    $rootScope.$emit('supplyApp.httpError', getTranslation(errorKey));
                } else {
                    $rootScope.$emit('supplyApp.httpError', response);
                }
            }
            return $q.reject(response);
        }

        function initTranslations() {
            msgs['pupil.guid.exists'] = "Ученик с таким ЕРИП номером уже существует в данном регионе";
            msgs['pupil.guid.invalid'] = "Не правильный ЕРИП номер";
            msgs['pupil.name.exists'] = "Ученик с таким именем уже существует в школе";

            msgs['pupil.retire.dept'] = "Нельзя отправить в архив ученика с долгом или остатком на балансе. Обратитесь в КШП";
            msgs['pupil.retire.checks'] = "Нельзя отправить в архив ученика с отметками о будущем посещении. Обратитесь в КШП";

            msgs['user.exists'] = "Пользователь с таким логином уже существует";
            msgs['user.email.exists'] = "Пользователь с таким емейлом уже существует";
            msgs['region.promoted'] = "Регион уже был переведен на новый учебный год";

            msgs['lesson.exists'] = "Такое занятие уже существует";
            msgs['teacherLesson.exists'] = "Такая пара предмет-учитель уже существует";
        }

        function hasTranslation(key) {
            return msgs.hasOwnProperty(key);
        }

        function getTranslation(key) {
            return msgs[key];
        }
    }
})();
