(function() {
  "use strict";

  angular
    .module("supplyApp")
    .controller("StatsReportController", StatsReportController);

  StatsReportController.$inject = ['$scope', 'Stats', 'AlertService', 'InfographicsService', '$state'];

  function StatsReportController($scope, Stats, AlertService, InfographicsService, $state) {
    var vm = this;

    vm.sumOfPayments = 0;

    vm.showAllPayments = function() {
        $state.go("incomes", {});
    };
    vm.showEripPayments = function() {
        $state.go("allpayt", {});
    };

    loadStats();
    $scope.$on('authenticationSuccess', function () {
        loadStats();
    });

    function loadStats() {
        var date = new Date();
        var startDate = getDate(-5);
        var endDate = getDate(+1);

        Stats.getEripMonthStats({
            year: date.getFullYear(),
            month: date.getMonth() + 1
        }, function(data) {
            vm.sumOfPayments = data.sum;
        }, onError);

        Stats.getExpenseMonthStats({
            year: date.getFullYear(),
            month: date.getMonth() + 1
        }, function(data) {
            vm.sumOfCharges = data.sum;
        }, onError);

        Stats.getEripMonthStatsByPeriod({
            startDate: startDate.toUTCString(),
            endDate: endDate.toUTCString()
        }, onPeriodStatsSuccess, onError);

        function onPeriodStatsSuccess(data) {
            vm.chartModel = InfographicsService.prepareChartModel(startDate, endDate, data);
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        function getDate(monthShift) {
            var date = new Date();
            if (monthShift > 0) {
                date.setMonth(date.getMonth() + monthShift);
            } else {
                date.setMonth(date.getMonth() + monthShift, 1);
            }
            return date;
        }
    }
  }
})();
