(function () {
    'use strict';

    angular.module('supplyApp.common')
        .directive('infoboxWidget', infobox);

    function infobox() {
        return {
            restrict: 'E',
            scope: {
                label: '@',
                text: '@',
                icon: '@',
                number: '@'
            },
            replace: true,
            templateUrl: 'app/common/components/widget/infobox-widget.html'
        }
    }
})();
