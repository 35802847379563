(function () {
    angular
        .module('supplyApp')
        .controller('IncomeEditController', IncomeEditController);

    IncomeEditController.$inject = ['$scope', '$state', 'AlertService', 'IncomesModelService', 'Income', 'school', 'income', 'foodTypes'];

    function IncomeEditController($scope, $state, AlertService, IncomesModelService, Income, school, income, foodTypes) {
        var vm = this;

        vm.school = school;
        vm.rowDefs = foodTypes;
        vm.income = income;
        vm.month = $state.params.month;
        vm.year = $state.params.year;
        vm.isNewIncome = income.id === null;
        vm.isBusy = false;

        vm.tabs = [];

        init();

        function init() {
            vm.tabs = [
                {
                    title: 'Наличный', rows: IncomesModelService.buildTab("CACHE", vm.rowDefs), type: "CACHE",
                    isEmpty: true
                },
                {
                    title: 'Безналичный', rows: IncomesModelService.buildTab("NONCACHE", vm.rowDefs), type: "NONCACHE",
                    isEmpty: true
                },
            ];
        }

        $scope.$watch("vm.income.subsidy", function () {
            checkIsFormEmpty();
        });

        $scope.$watch("vm.income.cachebox", function () {
            checkIsFormEmpty();
        });

        $scope.$watch("vm.tabs", function () {
            checkIsFormEmpty();
        }, true);

        if (income.details.length > 0) {
            IncomesModelService.bindIncomeView(vm.tabs, income);
            vm.isNewIncome = false;
        }

        vm.delete = function () {
            vm.isBusy = true;
            Income.delete({id: income.id}, onDeleteSuccess, onError);

            function onDeleteSuccess() {
                vm.isBusy = false;
                $state.go('school-income', {id: vm.school.id, year: vm.year, month: vm.month}, {reload: true});
            }
        };

        vm.save = function () {
            vm.isBusy = true;
            var income = IncomesModelService.parseIncome(vm.income, vm.tabs, vm.school);

            if (income.id == null) {
                Income.save(income, onSaveSuccess, onError);
            } else {
                Income.update(income, onSaveSuccess, onError);
            }

            function onSaveSuccess() {
                vm.isBusy = false;
                $state.go('school-income', {id: vm.school.id, year: vm.year, month: vm.month}, {reload: true});
            }
        };

        function onError(error) {
            vm.isBusy = false;
            AlertService.error(error.data.message);
        }

        function checkIsFormEmpty() {
            var filledTabExists = vm.tabs.some(function (tab) {
                return !tab.isEmpty;
            });

            var subsidyField = $scope.form['subsidy'];
            var cacheboxField = $scope.form['cachebox'];

            var hasAddtionalFieldFilled = !!vm.income.subsidy || !!vm.income.cachebox;
            subsidyField.$setValidity('subsidy', (vm.income.subsidy > 0) || hasAddtionalFieldFilled || filledTabExists);
            cacheboxField.$setValidity('cachebox', (vm.income.cachebox > 0) || hasAddtionalFieldFilled || filledTabExists);
        }
    }
})();
