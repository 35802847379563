(function() {
    'use strict';

    angular
        .module('supplyApp.common')
        .controller('FooterController', FooterController);

    FooterController.$inject = ['$cookies', 'GAService', 'VERSION'];

    function FooterController($cookies, GAService, VERSION) {
        var consentCookieKey = 'userConsent';
        var vm = this;

        vm.VERSION = VERSION;
        vm.showConsent = !checkConsent();

        vm.consentAll = function() {
            setConsent(true);
        };
        vm.consentRequired = function() {
            setConsent(false);
        };

        function setConsent(consentAll) {
            vm.showConsent = false;
            var now = new Date();
            var expiry = new Date(now.setMonth(now.getMonth() + 6));
            $cookies.put(consentCookieKey, consentAll ? 'all' : 'required', {
                expires: expiry
            });
            if (consentAll) {
                GAService.grandConsent();
            }
        }
    
        function getConsent() {
            return $cookies.get(consentCookieKey);
        }

        function checkConsent() {
            var consent = getConsent();
            if (!consent) {
                return false;
            } else if (consent === 'all')  {
                GAService.grandConsent();
            }
            return true;
        }

    }
})();
