(function () {
    'use strict';

    angular
        .module('supplyApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('pupil', {
            parent: 'entity',
            url: '/pupil?page&sort&search',
            data: {
                authorities: [],
                pageTitle: "Список учеников"
            },
            views: {
                'content@': {
                    templateUrl: 'app/ui/entity/pupil/pupils.html',
                    controller: 'PupilController',
                    controllerAs: 'vm'
                },
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return PaginationUtil.buildPageParams($stateParams);
                }],
            }

        }).state('pupil-detail', {
            parent: 'entity',
            url: '/pupil/{id}?page&sort&prevState',
            data: {
                authorities: [],
                pageTitle: "Карточка ученика"
            },
            views: {
                'content@': {
                    templateUrl: 'app/ui/entity/pupil/pupil-detail.html',
                    controller: 'PupilDetailController',
                    controllerAs: 'vm'
                },
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'date,desc',
                    squash: true
                }
            },
            resolve: {
                entity: ['$stateParams', 'Pupil', function ($stateParams, Pupil) {
                    return Pupil.details({id: $stateParams.id}).$promise;
                }],
                previousState: ["$state", '$stateParams', function ($state, $stateParams) {
                    if ($stateParams.prevState) {
                        return JSON.parse($stateParams.prevState);
                    }

                    var currentStateData = {
                        name: $state.current.name || 'pupil',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params),
                    };

                    if (currentStateData.name !== 'pupil-detail' &&
                        currentStateData.name !== 'pupil-detail.cabinet' &&
                        currentStateData.name !== 'pupil-detail.pwd') {
                        $stateParams.prevState = JSON.stringify({
                            name: currentStateData.name,
                            params: currentStateData.params
                        });
                    }
                    return currentStateData;
                }],
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return PaginationUtil.buildPageParams($stateParams);
                }],
            }
        }).state('pupil-detail.edit', {
            parent: 'pupil-detail',
            url: '/detail/edit',
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/ui/entity/pupil/pupil-dialog.html',
                    controller: 'PupilDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Pupil', function (Pupil) {
                            return Pupil.get({id: $stateParams.id}).$promise;
                        }],
                        currSchool: ['Principal', function (Principal) {
                            return Principal.current().school;
                        }],
                    }
                }).result.then(function () {
                    $state.go('^', {}, {reload: false});
                }, function () {
                    $state.go('^');
                });
            }]
        }).state('pupil-detail.balance', {
            parent: 'pupil-detail',
            url: '/detail/balance',
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/ui/entity/pupil/pupil-balance-dialog.html',
                    controller: 'PupilBalanceController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: ['Pupil', function (Pupil) {
                            return Pupil.get({id: $stateParams.id}).$promise;
                        }],
                    }
                }).result.then(function () {
                    $state.go('^', {}, {reload: true});
                }, function () {
                    $state.go('^');
                });
            }]
        })
            .state('pupil-detail.cabinet', {
                parent: 'pupil-detail',
                url: '/cabinet',
                data: {
                    authorities: []
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/ui/entity/pupil/pupil-cabinet-dialog.html',
                        controller: 'PupilCabinetController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Pupil', function (Pupil) {
                                return Pupil.get({id: $stateParams.id}).$promise;
                            }],
                            user: ['Cabinet', function (Cabinet) {
                                return Cabinet.getUser({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {reload: true});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('pupil-detail.password', {
                parent: 'pupil-detail',
                url: '/{login}/pwd',
                data: {
                    authorities: []
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/common/components/login/user-passgen-dialog.html',
                        controller: 'CabinetPassgenController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            login: function () {
                                return $stateParams.login;
                            },
                            pupilId: function () {
                                return $stateParams.id;
                            }
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {reload: true});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('pupil.new', {
                parent: 'pupil',
                url: '/new',
                data: {
                    authorities: ['ROLE_SCH_USER', 'ROLE_SCH_ADMIN', 'ROLE_BASE_ADMIN', 'ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/ui/entity/pupil/pupil-dialog.html',
                        controller: 'PupilDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    guid: null,
                                    name: null,
                                    address: null,
                                    phone: null,
                                    notes: null,
                                    regDate: null,
                                    updDate: null,
                                    id: null
                                };
                            },
                            currSchool: ['Principal', function (Principal) {
                                return Principal.current().school;
                            }],
                        }
                    }).result.then(function () {
                        $state.go('pupil', null, {reload: 'pupil'});
                    }, function () {
                        $state.go('pupil');
                    });
                }]
            })
            .state('pupil.edit', {
                parent: 'pupil',
                url: '/{id}/edit',
                data: {
                    authorities: []
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/ui/entity/pupil/pupil-dialog.html',
                        controller: 'PupilDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Pupil', function (Pupil) {
                                return Pupil.get({id: $stateParams.id}).$promise;
                            }],
                            currSchool: ['Principal', function (Principal) {
                                return Principal.current().school;
                            }],
                        }
                    }).result.then(function () {
                        $state.go('pupil', null, {reload: 'pupil'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('pupil.delete', {
                parent: 'pupil',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/ui/entity/pupil/pupil-delete-dialog.html',
                        controller: 'PupilDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Pupil', function (Pupil) {
                                return Pupil.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('pupil', null, {reload: 'pupil'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('pupil-cabinet', {
                parent: 'pupil',
                url: '/cabinet/{id}/{login}',
                data: {
                    authorities: []
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/ui/entity/pupil/pupil-cabinet-dialog.html',
                        controller: 'PupilCabinetController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Pupil', function (Pupil) {
                                return Pupil.get({id: $stateParams.id}).$promise;
                            }],
                            user: ['Cabinet', function (Cabinet) {
                                return Cabinet.getUser({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('pupil', null, {reload: 'pupil'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('pupil.password', {
                parent: 'pupil',
                url: '/{pupilId}/password/{login}',
                data: {
                    authorities: []
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/common/components/login/user-passgen-dialog.html',
                        controller: 'CabinetPassgenController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            login: function () {
                                return $stateParams.login;
                            },
                            pupilId: function () {
                                return $stateParams.pupilId;
                            }
                        }
                    }).result.then(function () {
                        $state.go('^');
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }
})();
