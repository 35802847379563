(function () {
    'use strict';

    angular
        .module('supplyApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('income', {
                parent: 'app',
                abstract: true,
                url: '/i',
            })
            .state('incomes', {
                parent: 'income',
                url: '/list?page&search',
                data: {
                    authorities: [],
                    pageTitle: 'Поступления по школам'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/ui/income/income-list.html',
                        controller: 'IncomeController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return PaginationUtil.buildPageParams($stateParams);
                    }],
                }
            })
            .state('school-income', {
                parent: 'income',
                url: '/school/{id}/{year}/{month}',
                data: {
                    authorities: [],
                    pageTitle: 'Поступления по школе'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/ui/income/school-income.html',
                        controller: 'SchoolIncomeController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    school: ['$stateParams', 'School', function ($stateParams, School) {
                        return School.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", '$stateParams', 'PaginationUtil', function ($state, $stateParams, PaginationUtil) {
                        return PaginationUtil.buildPreviousState($state, $stateParams, 'income');
                    }],
                    settings: ['$stateParams', 'IncomeSettings', function ($stateParams, IncomeSettings) {
                        return IncomeSettings.getColumnSettingsBySchool($stateParams.id).$promise;
                    }]
                }
            })
            .state('income-edit', {
                parent: 'school-income',
                url: '/edit/{incomeId}',
                data: {
                    authorities: [],
                    pageTitle: 'Добавление платежей'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/ui/income/income-detail.html',
                        controller: 'IncomeEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    foodTypes: ['FoodType', function (FoodType) {
                        return FoodType.query({}).$promise;
                    }],
                    income: ['$stateParams', 'Income', function ($stateParams, Income) {
                        return Income.getIncomeWithDetails({id: $stateParams.incomeId}).$promise;
                    }]
                }
            })
            .state('school-income.new', {
                parent: 'school-income',
                url: '/new/{date}',
                data: {
                    authorities: [],
                    pageTitle: 'Добавление платежей'
                },
                params: {
                    date: {
                        type: 'date',
                        value: null,
                    },
                },
                views: {
                    'content@': {
                        templateUrl: 'app/ui/income/income-detail.html',
                        controller: 'IncomeEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    foodTypes: ['FoodType', function (FoodType) {
                        return FoodType.query({}).$promise;
                    }],
                    school: ['$stateParams', 'School', function ($stateParams, School) {
                        return School.get({id: $stateParams.id}).$promise;
                    }],
                    income: ['$stateParams', function ($stateParams) {
                        return {
                            id: null,
                            date: $stateParams.date,
                            school: $stateParams.id,
                            subsidy: 0,
                            cachebox: 0,
                            details: [],
                        };
                    }]
                }
            });
    }
})();
