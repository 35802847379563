(function() {
    'use strict';

    angular
        .module('supplyApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('lesson-teacher', {
            parent: 'entity',
            url: '/lesson-teacher?page&sort&search',
            data: {
                authorities: [],
                pageTitle: 'Расписание занятий'
            },
            views: {
                'content@': {
                    templateUrl: 'app/ui/schedule/lesson-teacher/lesson-teacher.html',
                    controller: 'LessonTeacherController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return PaginationUtil.buildPageParams($stateParams);
                }],
            }
        })
        .state('lesson-teacher-detail', {
            parent: 'entity',
            url: '/lesson-teacher/{id}',
            data: {
                authorities: [],
                pageTitle: 'Занятие'
            },
            views: {
                'content@': {
                    templateUrl: 'app/ui/schedule/lesson-teacher/lesson-teacher-detail.html',
                    controller: 'LessonTeacherDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'TeacherLesson', function($stateParams, TeacherLesson) {
                    return TeacherLesson.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", '$stateParams', 'PaginationUtil', function ($state, $stateParams, PaginationUtil) {
                    return PaginationUtil.buildPreviousState($state, $stateParams, 'lesson-teacher');
                }],
            }
        })
        .state('lesson-teacher-detail.edit', {
            parent: 'lesson-teacher-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_SCH_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/ui/schedule/lesson-teacher/lesson-teacher-dialog.html',
                    controller: 'LessonTeacherDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['TeacherLesson', function(TeacherLesson) {
                            return TeacherLesson.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('lesson-teacher.new', {
            parent: 'lesson-teacher',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_SCH_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', 'Principal', function($stateParams, $state, $uibModal, Principal) {
                $uibModal.open({
                    templateUrl: 'app/ui/schedule/lesson-teacher/lesson-teacher-dialog.html',
                    controller: 'LessonTeacherDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                id: null,
                            };
                        },
                    }
                }).result.then(function() {
                    $state.go('lesson-teacher', null, { reload: 'lesson-teacher' });
                }, function() {
                    $state.go('lesson-teacher');
                });
            }]
        })
        .state('lesson-teacher.edit', {
            parent: 'lesson-teacher',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_SCH_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/ui/schedule/lesson-teacher/lesson-teacher-dialog.html',
                    controller: 'LessonTeacherDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['TeacherLesson', function(TeacherLesson) {
                            return TeacherLesson.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('lesson-teacher', null, { reload: 'lesson-teacher' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
