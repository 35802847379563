(function () {
    'use strict';

    angular
        .module('supplyApp.common')
        .directive('toggleSwitch', toggleSwitch);

    function toggleSwitch() {
        return {
            restrict: 'E',
            replace: 'true',
            scope: {
                modelVariable: '='
            },
            template: "<label class='switch'>" +
            "<input type='checkbox' ng-model='modelVariable'>" +
            "<span class='slider round'></span>" +
            "</label>"
        };
    }
})();
