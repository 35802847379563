(function() {
    'use strict';

    angular
        .module('supplyApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('allpayt', {
            parent: 'report',
            url: '/all?page&sort&search',
            data: {
                authorities: [],
                pageTitle: 'Все ЕРИП платежи'
            },
            views: {
                'content@': {
                    templateUrl: 'app/ui/report/all/allpayts.html',
                    controller: 'AllPaytsController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return PaginationUtil.buildPageParams($stateParams);
                }],
            }
        })
    }

})();
