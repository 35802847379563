(function () {
    'use strict';

    angular
        .module('supplyApp')
        .service('AtdSettingsService', AtdSettingsService);

    AtdSettingsService.$inject = ['$uibModal', 'AlertService', 'ClazzSettings'];

    function AtdSettingsService($uibModal, AlertService, ClazzSettings) {
        var modalInstance = null;
        var service = {
            openModal: openSettingsModal,
        };

        return service;

        function openSettingsModal(clazzId, onModalApply) {
            modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'app/ui/attendance/attendance-settings.html',
                controller: ['$uibModalInstance', function($uibModalInstance) {
                    var vm = this;

                    vm.settings = ClazzSettings.getAtdSettings({id : clazzId});

                    vm.cancel = function () {
                        $uibModalInstance.dismiss('cancel');
                    };

                    vm.save = function () {
                        ClazzSettings.saveAtdSettings(vm.settings, onSuccess, onError);
                        function onSuccess() {
                            $uibModalInstance.dismiss('save');
                        }
                        function onError(error) {
                            $uibModalInstance.dismiss('error');
                            if (error && error.data) {
                                AlertService.error(error.data.message);
                            }
                        }
                    };

                }],
                controllerAs: 'vm',
                //resolve: {
                //}
            });
            modalInstance.result.then(onModalApply, onModalApply);
        }

    }
})();
