(function() {
    'use strict';

    angular
        .module('supplyApp')
        .controller('PaytsByClassDetailController', PaytsByClassDetailController);

    PaytsByClassDetailController.$inject = ['$scope', '$state', 'Report', 'Principal', 'ReportConfig', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'entity'];

    function PaytsByClassDetailController ($scope, $state, Report, Principal, ReportConfig, ParseLinks, AlertService, paginationConstants, pagingParams, entity) {
        var vm = this;

        vm.pagingParams = pagingParams;
        vm.entity = entity;

        vm.search = search;
        vm.resetSearch = doReset;
        vm.filter = {};
        vm.filterConfig = ReportConfig.filterConfig;

        vm.showSchoolLink = Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_BASE_ACC', 'ROLE_BASE_ADMIN']);

        init();

        function init() {
            if (!vm.entity || !vm.entity.id) {
                $state.go('clazzpayt');
                return;
            }
            vm.filter = ReportConfig.getPeriod();
            search();
        }

        function search () {
            Report.clazz({
                id: entity.id,
                page: pagingParams.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort(),
                search: vm.filter,
            }, onSuccess, onError);
            function sort() {
                var result = [pagingParams.predicate + ',' + (pagingParams.ascending ? 'asc' : 'desc')];
                if (pagingParams.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.report = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function doReset() {
            vm.report = null;
            ReportConfig.reset();
            init();
        }
    }
})();
