(function() {
    'use strict';

    angular
        .module('supplyApp')
        .controller('LessonTeacherController', LessonTeacherController);

    LessonTeacherController.$inject = ['$scope', '$state', 'Nav', 'Dialogs', 'TeacherLesson', 'Principal', 'AlertService', 'PaginationUtil', 'School', 'pagingParams'];

    function LessonTeacherController ($scope, $state, Nav, Dialogs, TeacherLesson, Principal, AlertService, PaginationUtil, School, pagingParams) {
        var vm = this;

        vm.pagingParams = pagingParams;

        vm.filter = pagingParams.search ? angular.fromJson(pagingParams.search) : {};
        vm.showFilter = !!(vm.filter.schoolId || vm.filter.subjectId || vm.filter.teacherId);
        vm.filtersLoaded = false;

        vm.doSearch = doSearch;
        vm.resetSearch = resetSearch;

        $scope.$on('authenticationSuccess', function (){
            init();
        });

        $scope.$watch('vm.showFilter', function(newVal) {
            if (newVal && !vm.filtersLoaded) {
                loadFilters();
            }
        });

        $scope.$watch('vm.filter.schoolId', function(newVal) {
            if (newVal) {
                loadSchoolFilters(newVal);
            }
        });

        init();

        function init() {
            vm.canSelectSchool = Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_BASE_ADMIN']);
            doLoad();
        }

        function doLoad() {
            vm.searching = false;
            TeacherLesson.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: PaginationUtil.sort(pagingParams),
                search: vm.filter,
            }, onSuccess, onError);
        }

        function loadFilters() {
            if (vm.canSelectSchool) {
                vm.schools = School.all();
            } else {
                var schoolId = vm.filter.schoolId || Principal.current().school.id;
                loadSchoolFilters(schoolId);
            }
            vm.filtersLoaded = true;
        }

        function loadSchoolFilters(schoolId) {
            vm.teachers = School.teachers({id:schoolId});
            vm.subjects = School.subjects({id:schoolId});
        }

        function doSearch() {
            vm.searching = true;
            Nav.search(pagingParams, vm.filter);
        }

        function resetSearch() {
            vm.filter = {};
            vm.searching = false;
            doSearch();
        }

        function onSuccess(data, headers) {
            //vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.entities = data;
            vm.page = pagingParams.page;
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        vm.deleteEntity = function (entity) {
            Dialogs.warn("Пара предмет-учитель будет удалена").then(function () {
                TeacherLesson.delete({id: entity.id}, function () {
                    $state.go('lesson-teacher', null, { reload: 'lesson-teacher' });
                }, function (error) {
                    AlertService.error("Ошибка при удалении записи: " + error.data.message);
                });
            });
        };

    }
})();
