(function() {
  "use strict";

  angular
    .module("supplyApp")
    .controller("RegionPrefsController", RegionPrefsController);

  RegionPrefsController.$inject = ["$scope", "$state", "$http", "currentUser", "Mng", "Dialogs", "AlertService"];

  function RegionPrefsController($scope, $state, $http, currentUser, Mng, Dialogs, AlertService) {
    var vm = this;

    vm.region = currentUser.region;
    vm.busy = false;

    vm.summerTime = isSummerTime();
    vm.hardLockTime = isHardLockTime();
    vm.asOfDate = new Date();

    function isSummerTime() {
        var month = new Date().getMonth();
        return month > 4 && month < 8;
    }

    function isHardLockTime() {
        var hour = new Date().getHours();
        return hour >= 12;
    }

    vm.promote = function() {
        Dialogs.warn("Все классы во всех школах области будут переименованы. \
            Операция не может быть отменена. \
            Продолжить?").then(function() {
                vm.busy = true;
                Mng.promote({id:vm.region.id}, function() {
                  vm.busy = false;
                  Dialogs.msg("Перевод успешно выполнен");
                }, function() {
                  vm.busy = false;
                });
            });
    }

    vm.withdraw = function() {
        vm.busy = true;
        return $http.get('api/attendance/apply-now').then(function (response) {
            Dialogs.msg("Списание выполнено успешно");
            vm.busy = false;
        });
    }

    vm.buildSchoolsFinReport = function() {
        vm.busy = true;
        Mng.buildSchoolFinReportForAll({}, vm.asOfDate, function() {
          vm.busy = false;
          Dialogs.msg("Выполнено");
        }, function() {
          vm.busy = false;
        });
    }

  }

})();
