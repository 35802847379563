(function() {
    'use strict';

    angular
        .module('supplyApp')
        .controller('ClazzDialogController', ClazzDialogController);

    ClazzDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'Principal', 'entity', 'Clazz', 'School'];

    function ClazzDialogController ($timeout, $scope, $stateParams, $uibModalInstance, Principal, entity, Clazz, School) {
        var vm = this;

        vm.clazz = entity;
        vm.clear = clear;
        vm.save = save;
        vm.users = [];

        vm.hasAdminAuth = Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_BASE_ADMIN']);

        if (!entity.id) {
            vm.canEditSchool = !!vm.hasAdminAuth;

            if (vm.canEditSchool) {
                vm.schools = School.all();

                $scope.$watch("vm.clazz.school", function(newVal) {
                    if (newVal && newVal.id) {
                        vm.users = School.teachers({id:newVal.id});
                    }
                });
            }
        }

        if (entity.school) {
            vm.users = School.teachers({id:entity.school.id});
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.clazz.id !== null) {
                Clazz.update(vm.clazz, onSaveSuccess, onSaveError);
            } else {
                Clazz.save(vm.clazz, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('supplyApp:clazzUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

    }
})();
