(function() {
    'use strict';
    angular
        .module('supplyApp.common')
        .factory('Attendance', Attendance);

    Attendance.$inject = ['$resource', 'DateUtils'];

    function Attendance ($resource, DateUtils) {
        var resourceUrl = "api/attendance/:id";

        return $resource(resourceUrl, {}, {
            'get': { method: 'GET' },
            'update': { method: "PUT" },
            'updateFromBatch': {
                method: "PUT",
                url: 'api/attendance/batch'
            },
            'byClazz': {
                method: 'GET',
                url: 'api/attendance/clazz/:clazzId',
            },
        });
    }
})();
