(function() {
    'use strict';
    angular
        .module('supplyApp.common')
        .factory('Subject', Subject);

    Subject.$inject = ['$resource'];

    function Subject ($resource) {
        var resourceUrl =  'api/subjects/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'all': { method: 'GET', isArray: true},
            'get': { method: 'GET' },
            'update': {
                method:'PUT',
                params: {
                    id: '@id',
                }},
            'schools': {
                url: 'api/subjects/:id/schools',
                method: 'GET',
                isArray: true
            }
        });
    }
})();
