(function () {
    'use strict';

    angular
        .module('supplyApp.common')
        .directive('weekPicker', weekPicker);

    weekPicker.$inject = ['WeekModel'];

    function weekPicker(WeekModel) {
        return {
            restrict: 'E',
            scope: {
                onChange: '&',
            },
            templateUrl: "app/common/components/widget/week-picker.html",
            link: linkFunk,
            replace: true,
        };

        function linkFunk(scope, element, attrs) {
            scope.prevWeek = prevWeek;
            scope.nextWeek = nextWeek;
            scope.today = today;

            init();

            function prevWeek() {
                goTo(scope.startDate.getDate() - 7);
            }

            function nextWeek() {
                goTo(scope.startDate.getDate() + 7, scope.lastDate.getDate() + 7);
            }

            function today() {
                WeekModel.initWeek(new Date());
                init();
                scope.onChange();
            }

            function goTo(startDate, lastDate) {
                scope.startDate.setDate(startDate);
                WeekModel.initWeek(scope.startDate);
                init();
                scope.onChange(scope.startDate, scope.lastDate);
            }

            function init() {
                var monthNames = ["Янв", "Фев", "Мар", "Апр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"];

                scope.startDate = new Date(WeekModel.from());
                scope.lastDate = new Date(WeekModel.to());

                scope.label = scope.startDate.getDate() + " " +
                    monthNames[scope.startDate.getMonth()] + " - " +
                    scope.lastDate.getDate() + " " +
                    monthNames[scope.lastDate.getMonth()];
            }
        }
    }
})();
