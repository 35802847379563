(function() {
    'use strict';
    angular
        .module('supplyApp.common')
        .factory('Clazz', Clazz);

    Clazz.$inject = ['$resource', 'DateUtils'];

    function Clazz ($resource, DateUtils) {
        var resourceUrl =  'api/clazzes/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': { method: 'GET'},
            'update': { method:'PUT' },
            'pupils': {
                url: 'api/clazzes/:id/pupils',
                method: 'GET',
                isArray: true
            },
            'retire': {
                url: 'api/clazzes/:id/retire',
                method: 'PUT',
                params: {
                    id: '@id',
                }
            }
        });
    }
})();
