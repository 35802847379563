(function() {
    'use strict';

    angular
        .module('supplyApp')
        .controller('LessonDetailController', LessonDetailController);

    LessonDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity'];

    function LessonDetailController($scope, $rootScope, $stateParams, previousState, entity) {
        var vm = this;

        vm.lesson = entity;
        vm.previousState = previousState;

        var unsubscribe = $rootScope.$on('supplyApp:lessonUpdate', function(event, result) {
            vm.lesson = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
