(function() {
    'use strict';

    angular
        .module('supplyApp.common')
        .directive('tableTotals', tableTotals);

    function tableTotals () {
        var directive = {
            restrict: 'E',
            transclude: true,
            scope: {
                report: '=report',
            },
            replace: true,
            template: '<div class="table-totals">За период: <b>{{report.effectiveFrom | date:"shortDate":"+03"}}</b> - <b>{{report.effectiveTo | date:"shortDate":"+03"}}</b> ' +
                ' --- Итого: <b>{{report.total.sum | currency:" "}}</b> руб.</div>'

        };

        return directive;
    };

    angular
        .module('supplyApp.common')
        .directive('reportDetails', reportDetails);

    function reportDetails () {
        var directive = {
            restrict: 'E',
            transclude: true,
            scope: {
                report: '=report',
                showSchoolLink: '=schoolLink'
            },
            replace: true,
            templateUrl: 'app/common/components/form/report-details.html',
        };

        return directive;
    };

})();
