(function () {
    "use strict";

    angular
        .module("supplyApp")
        .controller("AttendanceController", AttendanceController);

    AttendanceController.$inject = ["$scope", "$state", "Attendance", "AttendanceService", "AtdSettingsService", "AtdModel", "WeekModel", "Principal", "AlertService", "clazz", "previousState"];

    function AttendanceController($scope, $state, Attendance, AttendanceService, AtdSettingsService, AtdModel, WeekModel, Principal, AlertService, clazz, previousState) {
        var vm = this;

        vm.editingPupil = null;
        vm.previousState = previousState;
        vm.clazz = clazz;
        vm.pupils = [];
        vm.busy = false;
        vm.dirty = false;
        vm.collapsed = true;
        vm.openSettingsModal = function () {
            AtdSettingsService.openModal(vm.clazz.id, function (callback) {
                if (callback === "save") {
                    $state.reload();
                }
            });
        };

        vm.doSearch = doSearch;
        vm.resetSearch = resetSearch;

        init();

        $scope.$on('authenticationSuccess', function () {
            init();
        });

        function init() {
            vm.isAdmin = Principal.hasAnyAuthority(['ROLE_SCH_ACC', 'ROLE_ADMIN']) && (new Date().getHours() < 21);
            vm.canManageAtd = Principal.hasAnyAuthority(['ROLE_SCH_ADMIN', 'ROLE_SCH_ACC', 'ROLE_ADMIN']) && (new Date().getHours() < 21);
            vm.weekData = WeekModel.init();
            doSearch();
        }

        function doSearch() {
            vm.busy = true;
            Attendance.byClazz({
                clazzId: clazz.id,
                search: {
                    dateFrom: WeekModel.yyyymmdd(WeekModel.from()),
                    dateTo: WeekModel.yyyymmdd(WeekModel.to())
                }
            },
            onSuccess,
            onError
            );
        }

        function resetSearch() {
            doSearch();
        }

        vm.save = function () {
            vm.busy = true;
            var modified = AtdModel.filterModified();
            if (modified.length > 0) {
                AttendanceService.saveInBatch(modified, onAllSaved, onError);
            } else {
                vm.busy = false;
            }
        };

        vm.checkCol = function ($event, dayNum) {
            var checked = $event.target.checked;
            AtdModel.checkAllDay(dayNum, checked);
            vm.dirty = AtdModel.isDirty();
        };

        vm.checkDay = function ($event, pupil, attendance, dayNum) {
            var checked = $event.target.checked;
            AtdModel.checkDay(pupil, attendance, dayNum, checked);
            vm.dirty = AtdModel.isDirty();
        };

        vm.checkPupilDay = function ($event, pupil, dayNum) {
            var checked = $event.target.checked;
            AtdModel.checkPupilDay(pupil, dayNum, checked);
            vm.dirty = AtdModel.isDirty();
        };

        vm.checkRow = function ($event, pupil, attendance) {
            var checked = $event.target.checked;
            AtdModel.checkRow(pupil, attendance, checked);
            vm.dirty = AtdModel.isDirty();
        };

        vm.editPupil = function (pupil) {
            vm.editingPupil = pupil.pupilId;
            AtdModel.unlockAllDays(pupil);
        };

        vm.submitEditPupil = function (pupil) {
            vm.editingPupil = null;
            vm.busy = true;
            var modified = pupil.atdRows.some(function (row) {
                return row.details.some(function (detail) {
                    return detail.origin !== detail.checked;
                });
            });
            if (modified) {
                Attendance.update(pupil, resetSearch, onError);
            } else {
                AlertService.success('Данные успешно сохранены');
                AtdModel.lockAllDays(pupil);
            }
            vm.busy = false;
        };

        vm.togglePupil = function (pupil) {
            pupil.expanded = !pupil.expanded;
            vm.collapsed = !!pupil.expanded;
        };

        vm.collapseAll = function () {
            AtdModel.toggleCollapseAll(false);
            vm.collapsed = true;
        };

        vm.expandAll = function () {
            AtdModel.toggleCollapseAll(true);
            vm.collapsed = false;
        };

        function onSuccess(data, headers) {
            vm.weekData = WeekModel.init(WeekModel.from());
            vm.pupils = AtdModel.init(data);
            vm.editingPupil = null;
            vm.busy = false;
            vm.dirty = false;
        }

        function onError(error) {
            AlertService.error(error.data.message);
            vm.busy = false;
            vm.dirty = false;
        }

        function onAllSaved() {
            AlertService.success('Данные успешно сохранены');
            doSearch();
        }

    }
})();
