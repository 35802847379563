(function() {
    'use strict';
    angular
        .module('supplyApp.common')
        .factory('Region', Region);

    Region.$inject = ['$resource'];

    function Region ($resource) {
        var resourceUrl =  'api/regions/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'all': { method: 'GET', isArray: true},
            'get': { method: 'GET' },
            'update': { method:'PUT' },
            'schools': {
                url: 'api/regions/:id/schools',
                method: 'GET',
                isArray: true
            }
        });
    }
})();
