(function() {
    'use strict';
    angular
        .module('supplyApp.common')
        .factory('CostCenter', CostCenter);

    CostCenter.$inject = ['$resource'];

    function CostCenter ($resource) {
        var resourceUrl =  'api/cost-centers/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': { method: 'GET'},
            'update': {
                method:'PUT',
                params: {
                    id: '@id',
                }
            },
        });
    }
})();
