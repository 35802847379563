(function() {
    'use strict';

    angular
        .module('supplyApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('mng', {
            parent: 'app',
            url: '/mng',
            data: {
                authorities: ['ROLE_BASE_ADMIN', 'ROLE_ADMIN'],
                pageTitle: "Настройки Региона"
            },
            views: {
                "content@": {
                  templateUrl: "app/ui/mng/region-prefs.html",
                  controller: "RegionPrefsController",
                  controllerAs: "vm"
                }
            },
            resolve: {
                currentUser: ['Principal', function(Principal) {
                    return Principal.identity(true);
                }]
            }
        });
    }
})();
