(function () {
    'use strict';

    angular
        .module('supplyApp.common')
        .directive('fixedHeaderTable', fixedHeader);

    function fixedHeader() {
        return {
            restrict: 'A',
            link: function (scope, elem) {
                elem.addClass('fixed-header-table-wrapper');
                var $thead = elem.children('table').children('thead');

                if ($thead.length) {
                    elem.bind('scroll', function () {
                        var translate = "translate(0," + elem.scrollTop() + "px)";
                        $thead.css({
                            transform: translate
                        });
                    });
                }
            }
        }
    }
})();
