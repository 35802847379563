(function() {
    'use strict';

    angular
        .module('supplyApp')
        .controller('PupilDetailController', PupilDetailController);

    PupilDetailController.$inject = ['$scope', '$state', '$rootScope', '$timeout', 'AlertService', 'previousState', 'pagingParams', 'Pupil', 'Payt', 'Bill', 'Principal', 'entity'];

    function PupilDetailController($scope, $state, $rootScope, $timeout, AlertService, previousState, pagingParams, Pupil, Payt, Bill, Principal, entity) {
        var vm = this;

        vm.pupil = entity;
        vm.previousState = previousState;

        pagingParams.itemsPerPage = 10;
        vm.pagingParams = pagingParams;
        vm.busy = false;

        var isTeacher = Principal.hasAnyAuthority(['ROLE_SCH_USER']);
        var isAdmin = Principal.hasAnyAuthority(['ROLE_ADMIN']);
        var today = new Date();

        loadPayts();

        $scope.$on('authenticationSuccess', function () {
            loadPayts();
        });

        vm.rejectPayt = function(id) {
            vm.busy = true;
            Payt.reject({id:id}, function () {
                vm.busy = false;
                $state.reload();
            });
        };

        vm.canRejectPayt = function(payt) {
            if (!payt.marked) return false;
            if (isAdmin) return true;

            var timeDiff = today - new Date(payt.date);
            var dayDiff = timeDiff / (1000 * 3600 * 24);
            return dayDiff <= 1;
        };

        vm.refreshBill = function(id) {
            vm.busy = true;
            Bill.refresh({id:id}, function () {
                vm.busy = false;
                $state.reload();
            });
        };

        function loadPayts () {
            vm.busy = true;
            Pupil.payts({
                id: entity.id,
                page: pagingParams.page - 1,
                size: pagingParams.itemsPerPage,
                sort: sort(),
            }, onSuccess, onError);
            function onSuccess(data, headers) {
                //vm.links = ParseLinks.parse(headers('link'));
                vm.busy = false;
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.payts = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                vm.busy = false;
                AlertService.error(error.data.message);
            }
        }

        function sort() {
            var result = [pagingParams.predicate + ',' + (pagingParams.ascending ? 'asc' : 'desc')];
            if (pagingParams.predicate !== 'date') {
                result.push('date');
            }
            return result;
        }

        var unsubscribe = $rootScope.$on('supplyApp:pupilUpdate', function(event, result) {
            if (isTeacher && vm.pupil.clazz.id != result.clazz.id) {
                $timeout(function () {
                    $state.go(vm.previousState.name, vm.previousState.params, {reload:true,inherit:false});
                });
            }
            vm.pupil = result;
        });

        $scope.$on('$destroy', unsubscribe);
    }
})();
