(function() {
  "use strict";

  angular
    .module("supplyApp")
    .service("AtdModel", AtdModel);

  AtdModel.$inject = ['Attendance', 'WeekModel'];

    /**
     * Wrapper over Attendence model
     *
     * Pupil: {
     * - pupilId
     * - guid
     * - name
     * - balance
     * - balanceEst
     * + expanded
     * - atdRows: [
     *      - type : {
     *          - id:
     *          - name:
     *          - active:
     *       }
     *      - details: [
     *          - active: boolean
     *          - checked: boolean
     *          - cost: number
     *          - processed: boolean
     *          - date:
     *          - logId:
     *          + origin: boolean
     *       ]
     * + days: [
     *      + checked: boolean
     *      + active: boolean
     *      + isToday: boolean
     *]
   */
  function AtdModel(Attendance, WeekModel) {

    var service = {
        pupils: [],
        allowGlobalCheck: false,

        init: function (data) {
            service.pupils = [];
            if (!data) return;

            service.pupils = data.logs;
            WeekModel.setAllowGlobal(data.foodConf);

            service.pupils.forEach(function(pupil) {
                pupil.atdRows.forEach(function(atd) {
                    initAtdDetail(atd.details);
                });
                pupil.expanded = false;
                initGlobalChecks(pupil);
            });
            return service.pupils;
        },

        filterModified: function() {
            var modified = service.pupils.filter(function(pupil) {
                return pupil.atdRows.some(function(row) {
                    return row.details.some(function(detail) {
                        return detail.origin !== detail.checked;
                    });
                });
            });
            return modified;
        },

        isDirty: function() {
            return service.pupils.some(function(pupil) {
                return pupil.atdRows.some(function(row) {
                    return row.details.some(function(detail) {
                        return detail.origin !== detail.checked;
                    });
                });
            });
        },

        checkAllDay: function(dayNum, val) {
            service.pupils.forEach(function(pupil) {
                service.checkPupilDay(pupil, dayNum, val);
            });
            WeekModel.check(dayNum, val);
        },

        checkPupilDay: function (pupil, dayNum, value) {
            var day = pupil.days[dayNum];
            pupil.atdRows.forEach(function(atd) {
                applyCheck(pupil, atd.details[dayNum], value);
            });
            day.checked = value;

            if (value) {
                WeekModel.check(dayNum, true);
            } else {
                WeekModel.check(dayNum, hasCheckedDay(dayNum));
            }

            validateDay(day, pupil);
            pupil.expanded = true;
        },

        checkRow: function (pupil, attendance, value) {
            for (var i=0; i<5; i++) {
                service.checkDay(pupil, attendance, i, value);
            }
        },

        checkDay: function (pupil, attendance, dayNum, value) {
            if (attendance.details[dayNum] && attendance.details[dayNum].active) {
                applyCheck(pupil, attendance.details[dayNum], value);


                var day = pupil.days[dayNum];

                if (value) {
                    day.checked = true;
                    WeekModel.check(dayNum, true);
                } else {
                    day.checked = hasCheckedDayForPupil(pupil, dayNum);
                    WeekModel.check(dayNum, hasCheckedDay(dayNum));
                }

                validateDay(day, pupil);
            }
        },

        unlockAllDays: function (pupil) {
            pupil.atdRows.forEach(function(row) {
                row.details.forEach(function(detail) {
                    detail.activeOrig = detail.active;
                    detail.active = detail.cost !== null;
                });
            });
            pupil.days.forEach(function(day) {
                day.active = true;
            });
            pupil.expanded = true;
        },

        lockAllDays: function (pupil) {
            pupil.atdRows.forEach(function(row) {
                for (var i=0; i<row.details.length; i++) {
                  var detail = row.details[i];
                  var day = pupil.days[i];
                  detail.active = !!detail.activeOrig;
                  day.active = detail.active;
                }
            });
        },

        toggleCollapseAll: function(expand) {
            service.pupils.forEach(function(pupil) {
                pupil.expanded = expand;
            });
        }
    };

    return service;

    function hasCheckedDayForPupil(pupil, dayNum) {
        return pupil.atdRows.some(function(atd) {
            return atd.details[dayNum].checked;
        });
    }

    function hasCheckedDay(dayNum) {
        return service.pupils.some(function(pupil) {
            return hasCheckedDayForPupil(pupil, dayNum);
        });
    }


    function initAtdDetail(details) {
        for (var i=0; i<details.length; i++) {
            details[i].origin = details[i].checked;
            details[i].isToday = WeekModel.isToday(i);
        }
    }

    /*
    function checkAllWithoutLogs(attendanceDetails) {
      attendanceDetails.forEach(function(detail) {
        if (!detail.logId && detail.active) {
          detail.checked = true;
        }
      });
    }*/

    function applyCheck(pupil, log, val) {
        if (log.checked !== val && pupil.balanceEst !== null) {
            if (val) {
                pupil.balanceEst = pupil.balanceEst - log.cost;
            } else {
                pupil.balanceEst = pupil.balanceEst + log.cost;
            }
        }
        log.checked = val;
    }


    function initGlobalChecks(pupil) {
        pupil.days = [];
        for (var i=0; i<7; i++) {
            pupil.days.push({
                checked: false,
                isToday: WeekModel.isToday(i),
                active: false,
                hasError: false
            });
        }

        pupil.atdRows.forEach(function(atd) {
            for (var i=0; i<atd.details.length; i++) {
                var day = pupil.days[i];
                if (atd.details[i].active) {
                    day.active = true;
                    WeekModel.active(i, true);
                }
                if (atd.details[i].checked) {
                    day.checked = true;
                    WeekModel.check(i, true);
                }
                validateDay(day, pupil);
            }
        });
    }

    function validateDay(day, pupil) {
        day.hasError = (pupil.balance <= 0) && day.active && day.checked;
    }

  }

})();
