(function() {
    'use strict';

    angular
        .module('supplyApp.common')
        .constant('paginationConstants', {
            'itemsPerPage': 20,
            'itemsPerPageSmall':10
        });
})();
