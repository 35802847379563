(function () {
    'use strict';

    angular
        .module('supplyApp')
        .controller('IncomeController', IncomeController);

    IncomeController.$inject = ['$scope', '$state', 'pagingParams', 'AlertService', 'Income', 'School', 'IncomesModelService', 'paginationConstants'];

    function IncomeController($scope, $state, pagingParams, AlertService, Income, School, IncomesModelService, paginationConstants) {
        var vm = this;

        vm.incomes = [];
        vm.load = loadAll;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.date = new Date();

        vm.datePickerOptions = {
            showWeeks: false,
            minMode: "month"
        };

        loadAll();

        $scope.$on('authenticationSuccess', function () {
            loadAll();
        });

        function loadAll() {
            if (!vm.date) {
                vm.date = new Date();
            }
            Income.getTotalIncomes({
                month: vm.date.getMonth() + 1,
                year: vm.date.getFullYear(),
                page: pagingParams.page - 1,
                size: pagingParams.size
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.incomes = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
    }
})();
