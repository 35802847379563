(function() {
    'use strict';

    angular
        .module('supplyApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('attendance', {
            parent: 'app',
            url: '/at',
            views: {
                'content@': {
                    template: '<div class="row"><div class="col-md-12 box">Загрузка...</div></div>',
                    controller: 'AtdRoutingController',
                    controllerAs: 'vm',
                }
            },
            data: {},
            resolve: {

            }
        })
        .state('atd-schools', {
            parent: 'attendance',
            url: '/s?page&sort?search',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/ui/attendance/atd-schools.html',
                    controller: 'AtdSchoolsController',
                    controllerAs: 'vm'
                },
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return PaginationUtil.buildPageParams($stateParams);
                }],
            }
        })
        .state('atd-school', {
            parent: 'atd-schools',
            url: '/{id}',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/ui/attendance/atd-classes.html',
                    controller: 'AtdSchoolClassesController',
                    controllerAs: 'vm'
                },
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return PaginationUtil.buildPageParams($stateParams);
                }],
            }
        })


        /**
         * Select User-specific classes
         */
        .state('atd-user-classes', {
            parent: 'attendance',
            url: '/c?page&sort&search',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/ui/attendance/atd-classes.html',
                    controller: 'AtdUserClassesController',
                    controllerAs: 'vm'
                },
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return PaginationUtil.buildPageParams($stateParams);
                }],
            }
        })
        .state('atd-view', {
            parent: 'attendance',
            url: '/c/{id}',
            data: {
                authorities: [],
                pageTitle: 'Посещаемость'
            },
            views: {
                'content@': {
                    templateUrl: 'app/ui/attendance/attendance.html',
                    controller: 'AttendanceController',
                    controllerAs: 'vm'
                },
            },
            resolve: {
                clazz: ['$stateParams', 'Clazz', function($stateParams, Clazz){
                    return Clazz.get({id:$stateParams.id}).$promise;
                }],
                previousState: ["$state", '$stateParams', 'PaginationUtil', function ($state, $stateParams, PaginationUtil) {
                    return PaginationUtil.buildPreviousState($state, $stateParams, 'attendance');
                }],
            }
        })
        ;
    }
})();
