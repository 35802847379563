(function () {
    'use strict';

    angular
        .module('supplyApp')
        .controller('ClazzRetireController', ClazzRetireController);

    ClazzRetireController.$inject = ['$scope', 'entity', 'Clazz', '$uibModalInstance'];

    function ClazzRetireController($scope, entity, Clazz, $uibModalInstance) {
        var vm = this;
        vm.clazz = entity;

        vm.clear = clear;
        vm.proceed = proceed;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function proceed() {
            Clazz.retire({id:entity.id}, function() {
                $uibModalInstance.close();
            });
        }

    }
})();
