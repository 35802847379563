(function() {
    'use strict';

    angular
        .module('supplyApp')
        .factory('AtdReportConfig', AtdReportConfig);

    function AtdReportConfig() {
        var service = {
            reset: reset,
            period: {},
            school: null,
            filterConfig : {
                items : [
                    {key:"today", label: "Сегодня"},
                    {key:"tomorrow", label: "Завтра (предварительно)"},
                    {key:"week", label: "За текущую неделю"},
                    {key:"month", label: "За текущий месяц"},
                ],
                custom: true,
            },
        };

        reset();
        return service;

        function reset() {
            service.school = null;
            service.period = {
                period : "today"
            };
        }
    }


})();
