(function () {
    'use strict';

    angular
        .module('supplyApp')
        .service('IncomeSettings', IncomeSettings);

    IncomeSettings.$inject = ['RegionSettings'];

    function IncomeSettings(RegionSettings) {
        var service = {
            getColumnSettingsBySchool: getColumnSettingsBySchool,
            getColumnSettingsByRegion: getColumnSettingsByRegion,
            parseSettings: parseSettings,
            update: update
        };

        function getColumnSettingsBySchool(schoolId, onSuccess, onError) {
            return RegionSettings.getSchoolSettings({confKey: 'REPORT_COLUMNS', value: schoolId}, onSuccess, onError);
        }

        function getColumnSettingsByRegion(regionId, onSuccess, onError) {
            return RegionSettings.getRegionSettings({confKey: 'REPORT_COLUMNS', value: regionId}, onSuccess, onError);
        }

        function update(settingsData, onSuccess, onError) {
            RegionSettings.update(prepareToSave(settingsData), onSuccess, onError);
        }

        function prepareToSave(settingsModel) {
            var saveModel = angular.copy(settingsModel);
            saveModel.value = angular.toJson(settingsModel.value);
            return saveModel;
        }

        function parseSettings(currentSettings) {
            var defaultModel = [
                {label: 'Платное', name: "paySum", show: true},
                {label: 'Дотации', name: "subsidy", show: true},
                {label: 'Касса', name: "cachebox", show: true},
                {label: 'Нал', name: "cache", show: true},
                {label: 'Безнал', name: "nonCache", show: true},
                {label: 'ЕРИП', name: "erip", show: true},
                {label: 'По типу питания', name: "mealType", show: true}
            ];

            if (currentSettings.value === null) {
                currentSettings.value = defaultModel;
            } else {
                if (angular.isString(currentSettings.value)) {
                    currentSettings.value = angular.fromJson(currentSettings.value);
                }
            }

            return currentSettings;
        }

        return service;
    }
})();
