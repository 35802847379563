(function () {
    'use strict';

    angular
        .module('supplyApp.common')
        .directive('reportFilter', reportFilter);

    reportFilter.$inject = ['ReportConfig'];

    function reportFilter(ReportConfig) {
        var directive = {
            restrict: 'E',
            transclude: true,
            scope: {
                doSearch: '&onSearch',
                doReset: '&onReset',
                filterModel: '=model',
                filterConfig: '=config',
                isSearchDisabled: '=disabled'
            },
            link: linkFunc,
            replace: true,
            templateUrl: 'app/common/components/form/report-filter.html',
        };

        return directive;

        function linkFunc(scope, element, attrs) {
            scope.isSearchValid = true;

            scope.onChange = function(newValue) {
                scope.isSearchValid = false;

                if (newValue && newValue !== 'custom') {
                    scope.filterModel.fromDate = null;
                    scope.filterModel.toDate = null;
                }

                scope.isSearchValid = validate();
            };

            scope.$watch('filterModel.fromDate', function (newValue, oldValue) {
                if (newValue && newValue !== oldValue) {
                    scope.onChange();
                }
            });

            scope.$watch('filterModel.toDate', function (newValue, oldValue) {
                if (newValue && newValue !== oldValue) {
                    scope.onChange();
                }
            });

            function validate() {
                return scope.filterModel.period !== 'custom' || scope.filterModel.fromDate !== null;
            }
        }

    }
})();
