(function() {
  "use strict";

  angular
    .module("supplyApp")
    .service("FoodTypesService", FoodTypesService);

  function FoodTypesService() {
    var entityTypes = {
        FOOD_TYPE: "foodType",
        FOOD_COST: "foodCost"
    };

    return {
      saveOrUpdateAll: saveOrUpdateAll,
      filterChanged: filterChanged,
      prepareData: prepareData,
      entityTypes: entityTypes
    };

    function prepareData(foodTypes, selectedDate) {
      foodTypes.forEach(function(t) {
        if (t.cost) {
          if (!t.cost.amount) {
            t.cost.amount = 0;
          }
        } else {
          t.cost = {
            'amount': 0,
            'date': selectedDate,
          }
        }
      });
      return foodTypes;
    }

    function saveOrUpdateAll(entities, entityType, cache, service, onSuccess, onError) {
      var changed = filterChanged(entities, cache);

      if (changed.length > 0) {
        performSaveOrUpdateAll(changed, entityType, service, onSuccess, onError);
      } else {
        onSuccess();
      }
    }

    function filterChanged(entities, cache) {
      var changed = [];
      entities.forEach(function(entity) {
        if (entryChanged(entity, cache)) {
          changed.push(entity);
        }
      });
      return changed;
    }

    function entryChanged(entry, cache) {
      return !cache.some(function(cached) {
        return angular.equals(cached, entry);
      });
    }

    function performSaveOrUpdateAll(entities, entityType, service, onSuccess, onError) {
      var numForProcess = entities.length;
      var numOfProcessed = 0;

      entities.forEach(function(entity) {
        var entityForSave = angular.copy(entity);
        if(entityType === entityTypes.FOOD_COST) {
            entityForSave = toFoodCostTypeDto(entity);
        }

        if (entity.id) {
          service.update(entityForSave, onSaved, onError);
        } else {
          service.save(entityForSave, onSaved, onError);
        }
      });

      function onSaved() {
        if (++numOfProcessed === numForProcess) {
          onSuccess();
        }
      }
    }

    function toFoodCostTypeDto(foodType) {
        var typeWithoutCost = angular.copy(foodType);
        delete typeWithoutCost.cost;

        var costWithType = angular.copy(foodType.cost);
        costWithType.type = typeWithoutCost;

        return costWithType;
    }
  }
})();
