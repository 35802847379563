(function () {
    'use strict';

    angular
        .module('supplyApp')
        .controller('IncomeSettingsModalController', IncomeSettingsModalController);

    IncomeSettingsModalController.$inject = ['currentSettings', '$uibModalInstance', 'IncomeSettings', 'AlertService'];

    function IncomeSettingsModalController(currentSettings, $uibModalInstance, IncomeSettings, AlertService) {
        var vm = this;

        vm.settings = IncomeSettings.parseSettings(currentSettings);

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        vm.save = function () {
            IncomeSettings.update(vm.settings, onSuccess, onError);

            function onSuccess() {
                $uibModalInstance.dismiss('save');
            }

            function onError(error) {
                $uibModalInstance.dismiss('error');
                AlertService.error(error.data.message);
            }

        };
    }
})();
