(function() {
    'use strict';

    angular
        .module('supplyApp')
        .controller('PupilDialogController', PupilDialogController);

    PupilDialogController.$inject = ['$timeout', '$scope', 'Principal','$stateParams', '$uibModalInstance', 'entity', 'currSchool', 'Pupil', 'School', 'ByUser'];

    function PupilDialogController ($timeout, $scope, Principal, $stateParams, $uibModalInstance, entity, currSchool, Pupil, School, ByUser) {
        var vm = this;

        vm.pupil = entity;
        vm.clear = clear;
        vm.save = save;
        vm.isValid = isValid;

        vm.school = null;
        vm.schools = [];
        vm.clazzes = [];

        vm.isNew = !entity.id;
        
        var isRegionUser = Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_BASE_ADMIN']);
        var isTeacher = Principal.hasAnyAuthority(['ROLE_SCH_USER']);

        vm.canEditGuid =  Principal.hasAnyAuthority(['ROLE_ADMIN']) || 
                        (vm.isNew && Principal.hasAnyAuthority(['ROLE_BASE_ADMIN']));

        vm.canEdit = Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_SCH_USER', 'ROLE_SCH_ADMIN', 'ROLE_BASE_ADMIN']);
        vm.canEditName = isRegionUser || (vm.isNew && vm.canEdit);

        vm.canSelectSchool = vm.isNew && !currSchool && isRegionUser;
        vm.canSelectClass = true;

        if (vm.canSelectSchool) {
            vm.schools = School.all();
            $scope.$watch('vm.school', function(newVal) {
                if (newVal && newVal.id) {
                    vm.clazzes = School.allClazzes({id:newVal.id});
                }
            });
        } else if (currSchool) {
            vm.clazzes = School.allClazzes({id:currSchool.id});
        } else if (entity.clazz && entity.clazz.school) {
            vm.clazzes = School.allClazzes({id:entity.clazz.school.id});
        }

        if (vm.isNew && isTeacher) {
            ByUser.classes({
                page: 0,
                size: 10,
                sort: null,
                search: null
            }, function (data) {
                if (data && data.length && data.length>0) {
                    vm.pupil.clazz = data[0];
                    vm.canSelectClass = false;
                }
            });
        }

        $timeout(function (){
            angular.element('.form-group #field_name').focus();
        });

        function isValid() {
            return !!vm.pupil.name && !!vm.pupil.clazz;
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.pupil.id !== null) {
                Pupil.update(vm.pupil, onSaveSuccess, onSaveError);
            } else {
                Pupil.save(vm.pupil, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            if (vm.isNew) {            
                $scope.$emit('supplyApp:pupilCreate', result);
            } else {
                $scope.$emit('supplyApp:pupilUpdate', result);
            }
            $uibModalInstance.close(result);
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
