(function() {
    'use strict';

    angular
        .module('supplyApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('fin-school-report', {
            parent: 'report',
            url: '/fin/sch/{id}',
            data: {
              authorities: ['ROLE_BASE_ACC','ROLE_BASE_ADMIN','ROLE_ADMIN'],
              pageTitle: 'Финансовый отчет по школе'
            },
            views: {
                'content@': {
                    templateUrl: 'app/ui/report/finance/fin-school-reports.html',
                    controller: 'SchoolFinReportsController',
                    controllerAs: 'vm'
                },
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                }
            },
            resolve: {
                entity: ['$stateParams', 'School', function($stateParams, School) {
                    return School.get({id : $stateParams.id}).$promise;
                }],
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return PaginationUtil.buildPageParams($stateParams);
                }],
            }
        })
        ;
    }

})();
