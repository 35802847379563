(function() {
    'use strict';
    angular
        .module('supplyApp.common')
        .factory('Report', Report);

    Report.$inject = ['$resource', 'DateUtils'];

    function Report ($resource, DateUtils) {
        var resourceUrl =  'api/report/erip/:id';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                url: 'api/report/erip/all',
            },
            'clazzes': {
                method: 'GET',
                url: 'api/report/erip/school/:id/clazzes',
            },
            'clazz': {
                method: 'GET',
                url: 'api/report/erip/clazz/:id',
            },
            'schools' : {
                method: 'GET',
                url: 'api/report/erip/schools',
            },
            'school' : {
                method: 'GET',
                url: 'api/report/erip/school/:id',
            },
            'attendance' : {
                method: 'GET',
                url: 'api/report/atd/school/:id',
            },
            'schoolFin': {
                method: 'GET',
                url: 'api/report/fin/school/:id',
                isArray: true
            }
        });
    }
})();
