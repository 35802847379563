(function () {
    'use strict';

    angular
        .module('supplyApp')
        .service('SchoolIncomeService', SchoolIncomeService);

    SchoolIncomeService.$inject = ['$uibModal'];

    function SchoolIncomeService($uibModal) {
        var service = {
            getDetailsForRender: getDetailsForRender,
            openSettingsModal: openSettingsModal,
            needToDisplay: needToDisplay,
            needToRender: needToRender,
            getRowStyle: getRowStyle,
            countWeekResultRowspan: countWeekResultRowspan
        };

        var modalInstance = null;

        return service;

        function openSettingsModal(regionId, onModalApply) {
            modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'app/ui/income/income-settings-modal.html',
                controller: 'IncomeSettingsModalController',
                controllerAs: 'vm',
                resolve: {
                    currentSettings: ['IncomeSettings', function (IncomeSettings) {
                        return IncomeSettings.getColumnSettingsByRegion(regionId).$promise;
                    }]
                }
            });
            modalInstance.result.then(onModalApply, onModalApply);
        }

        function getDetailsForRender(reportRows) {
            var rowDefsToRender = [];

            reportRows.forEach(function (row) {
                if (!row.empty) {
                    row.details.forEach(function (detail) {
                        if (!detail.empty) {
                            if (!contains(rowDefsToRender, detail.rowDef)) {
                                rowDefsToRender.push(detail.rowDef);
                            }
                        }
                    });
                }
            });
            rowDefsToRender.sort(function(a,b) {return a.id - b.id;});
            return rowDefsToRender.map(function(item) {return item.name;});
        }

        function needToDisplay(columnName, settings) {
            return settings.value.some(function (column) {
                return column.name === columnName && column.show;
            });
        }

        function needToRender(detailName, entryDefs) {
            return contains(entryDefs, detailName);
        }

        function getRowStyle(reportRow) {
            if (reportRow.type == 'MONTH_SUM' || reportRow.type == 'WEEK_SUM') {
                return "success";
            }
            var date = reportRow.date;
            var now = new Date();
            var today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
            var incomeDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());

            if (incomeDate.valueOf() == today.valueOf()) {
                return "info";
            }

            if (date.getDay() == 0 || date.getDay() == 6) {
                return "warning";
            }

        }

        function countWeekResultRowspan(settings) {
            var result = 0;

            settings.value.forEach(function (entry) {
                if (entry.show && entry.name !== 'erip') {
                    result++;
                }
            });

            return result;
        }

        function contains(array, value) {
            return array.some(function (entry) {
                if (entry.id) {
                    return entry.id === value.id;
                }
                return entry === value;
            });
        }

    }
})();
