(function () {
    'use strict';
    angular
        .module('supplyApp.common')
        .factory('School', School);

    School.$inject = ['$resource'];

    function School($resource) {
        var resourceUrl = 'api/schools/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'all': {
                url: 'api/schools/all',
                method: 'GET',
                isArray: true
            },
/*            'list': {
                url: 'api/schools/list',
                method: 'GET',
                isArray: true
            },*/
            'get': {method: 'GET'},
            'update': {method: 'PUT'},
            'clazzes': {
                url: 'api/schools/:id/clazzes',
                method: 'GET',
                isArray: true
            },
            'allClazzes': {
                url: 'api/schools/:id/clazzes/all',
                method: 'GET',
                isArray: true
            },
            'teachers': {
                url: 'api/schools/:id/teachers',
                method: 'GET',
                isArray: true
            },
            'subjects': {
                url: 'api/schools/:id/subjects',
                method: 'GET',
                isArray: true
            },
            'lessons': {
                url: 'api/schools/:id/lessons',
                method: 'GET',
                isArray: true
            },
        });
    }
})();
