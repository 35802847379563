(function() {
  "use strict";

  angular
    .module("supplyApp")
    .service("AttendanceService", AttendanceService);

  AttendanceService.$inject = ['Attendance'];

  function AttendanceService(Attendance) {

    return {
      saveInBatch: saveInBatch,
    };

    function saveInBatch(pupils, onSuccess, onError) {
      var numForProcess = pupils.length;
      var numOfProcessed = 0;

      pupils.forEach(function(pupil) {
        Attendance.updateFromBatch(pupil, onSaved, onError);
      });

      function onSaved() {
        if (++numOfProcessed === numForProcess) {
          onSuccess();
        }
      }
    }

  }

})();
