(function () {
    'use strict';

    angular
        .module('supplyApp')
        .controller('LessonUserClassesController', LessonUserClassesController);

    LessonUserClassesController.$inject = ['$scope', '$state', 'WeekModel', 'Principal', 'AlertService', 'ByUser', 'previousState'];

    function LessonUserClassesController($scope, $state, WeekModel, Principal, AlertService, ByUser, previousState) {
        var vm = this;

        vm.lessonRows = [];
        vm.busy = false;
        vm.previousState = previousState;

        vm.doSearch = doSearch;
        vm.resetSearch = resetSearch;

        init();

        $scope.$on('authenticationSuccess', function () {
            init();
        });

        function init() {
            vm.canManage = Principal.hasAnyAuthority(['ROLE_SCH_ADMIN', 'ROLE_SCH_USER', 'ROLE_ADMIN']);
            vm.isTeacher = Principal.hasAnyAuthority(['ROLE_SCH_USER']);
            vm.weekData = WeekModel.init();
            doSearch();
        }

        function doSearch() {
            vm.busy = true;
            ByUser.lessons({
                search: {
                    dateFrom: WeekModel.yyyymmdd(WeekModel.from()),
                    dateTo: WeekModel.yyyymmdd(WeekModel.to())
                }
            },
            onSuccess,
            onError
            );
        }

        function resetSearch() {
            doSearch();
        }

        vm.viewLog = function (log) {

        };

        function onSuccess(data, headers) {
            vm.weekData = WeekModel.init(WeekModel.from());
            vm.lessonRows = initLessonLogs(data, vm.weekData);
            vm.busy = false;
        }

        function onError(error) {
            AlertService.error(error.data.message);
            vm.busy = false;
        }

        function initLessonLogs(data, weekData) {
            var rows = data.rows;
            angular.forEach(rows, function (row) {
                row.lessonId = row.lesson.id;
                for (var i = 0; i < row.logs.length; i++) {
                    row.logs[i].isToday = WeekModel.isToday(i);
                    row.logs[i].isFuture = WeekModel.isFuture(i);
                }
            });
            return rows;
        }
    }
})();
