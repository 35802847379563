(function() {
    'use strict';

    angular
        .module('supplyApp.common')
        .factory('Dialogs', Dialogs);

    Dialogs.$inject = ['$uibModal'];

    function Dialogs($uibModal) {
        var factory = {
            confirm: dialogConfirm,
            warn: dialogWarn,
            msg: dialogNotify,
            //pupilAdded: pupilAddedDialog,
        };
        return factory;

        function dialogConfirm(message, title) {
            return dialog(message, title, 'sm');
        }

        function dialogWarn(message, title) {
            return dialog(message, title, 'md', {style:'btn-danger'});
        }

        function dialogNotify(message, title) {
            return dialog(message, 'Сообщение', 'sm', {title:'Закрыть'});
        }

        function dialog(dlgMessage, dlgTitle, dlgSize, dlgNextBtn) {
            var modal = $uibModal.open({
                size: dlgSize,
                template: '<div class="modal-header">' +
                                '<h4 class="modal-title" ng-bind="dlgTitle"></h4>' +
                            '</div>' +
                            '<div class="modal-body" ng-bind="dlgMessage"></div>' +
                            '<div class="modal-footer">' +
                                '<button class="btn btn-default" ng-click="modal.dismiss()">' +
                                    '<span class="glyphicon glyphicon-ban-circle"></span>&nbsp;<span>Отмена</span></button>' +
                                '<button class="btn" ng-class="dlgNextBtn.style" ng-click="modal.close()">' +
                                    '<span class="glyphicon" ng-class="dlgNextBtn.icon"></span>&nbsp;<span ng-bind="dlgNextBtn.title"></span>' +
                                '</button>' +
                            '</div>',
                controller: ["$scope", "$uibModalInstance", function ($scope, $uibModalInstance) {
                    $scope.modal = $uibModalInstance;

                    if (angular.isObject(dlgMessage)) {
                        angular.extend($scope, dlgMessage);
                    } else {
                        $scope.dlgMessage = dlgMessage;
                        $scope.dlgTitle = angular.isUndefined(dlgTitle) ? 'Вы уверены что хотите продолжить?' : dlgTitle;
                    }

                    $scope.dlgNextBtn = {title:"Продолжить", style:"btn-primary", icon:"glyphicon-play"};

                    if (angular.isObject(dlgNextBtn)) {
                        angular.extend($scope.dlgNextBtn, dlgNextBtn);
                    }
                }]
            });

            return modal.result;
        }

    }
})();
