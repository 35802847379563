(function () {
    'use strict';
    angular
        .module('supplyApp.common')
        .factory('Stats', Stats);

    Stats.$inject = ['$resource', 'DateUtils'];

    function Stats($resource, DateUtils) {
        var resourceUrl = 'api/stats';

        return $resource(resourceUrl, {}, {
            'getEripMonthStats': {
                url: resourceUrl + '/erip/:year/:month',
                method: 'GET',
            },
            'getExpenseMonthStats': {
                url: resourceUrl + '/expense/:year/:month',
                method: 'GET',
            },
            'getEripMonthStatsByPeriod': {
                url: resourceUrl + '/erip/period/:startDate/:endDate',
                method: 'GET', 
                isArray: true
            },
        });
    }
})();
