(function() {
    'use strict';

    angular
        .module('supplyApp')
        .controller('AddPupilDialogController', AddPupilDialogController);

    AddPupilDialogController.$inject = ['$timeout', '$scope', 'Principal','$stateParams', '$uibModalInstance', 'newPupil', 'Pupil'];

    function AddPupilDialogController ($timeout, $scope, Principal, $stateParams, $uibModalInstance, newPupil, Pupil) {
        var vm = this;

        vm.pupil = newPupil;
        vm.school = newPupil.clazz.school;
        vm.clear = clear;
        vm.save = save;
        vm.isValid = isValid;

        vm.canEdit = true;
        vm.canSelectSchool = false;
        vm.canSelectClass = false;
        vm.canEdit = true;

        var isRegionUser = Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_BASE_ADMIN']);
        //var isTeacher = Principal.hasAnyAuthority(['ROLE_SCH_USER']);

        vm.canEditGuid =  Principal.hasAnyAuthority(['ROLE_ADMIN']) || 
                        (vm.isNew && Principal.hasAnyAuthority(['ROLE_BASE_ADMIN']));

        vm.canEdit = Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_SCH_USER', 'ROLE_SCH_ADMIN', 'ROLE_BASE_ADMIN']);
        vm.canEditName = isRegionUser || (vm.isNew && vm.canEdit);

        $timeout(function (){
            angular.element('.form-group #field_name').focus();
        });

        function isValid() {
            return !!vm.pupil.name && !!vm.pupil.clazz;
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.pupil.id == null) {
                Pupil.save(vm.pupil, onSaveSuccess, onSaveError);
            } else {
                throw "Pupil should not have ID";
            }
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            $scope.$emit('supplyApp:pupilCreate', result);
            $uibModalInstance.close(result);
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
