(function() {
    'use strict';

    angular
        .module('supplyApp')
        .controller('SchoolDetailController', SchoolDetailController);

    SchoolDetailController.$inject = ['$scope', '$state', '$timeout', '$rootScope', 'AlertService', 'Modal', 'previousState', 'ParseLinks', 'pagingParams', 'entity', 'School'];

    function SchoolDetailController($scope, $state, $timeout, $rootScope, AlertService, Modal, previousState, ParseLinks, pagingParams, entity, School) {
        var vm = this;

        vm.school = entity;
        vm.previousState = previousState;
        vm.openImportModal = openImportModal;

        pagingParams.itemsPerPage = 10;
        vm.pagingParams = pagingParams;

        loadClazzes();

        $scope.$on('authenticationSuccess', function () {
            loadClazzes();
        });

        function openImportModal() {
            return Modal.open(
                'app/ui/entity/school/users-import-modal.html',
                'SchoolTeachersImportController',
                vm.school
            ).then(function() {
                // do nothing
            });
        }

        function loadClazzes () {
            School.clazzes({
                id: entity.id,
                page: pagingParams.page - 1,
                size: pagingParams.itemsPerPage,
                sort: sort(),
            }, onSuccess, onError);
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.clazzes = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function sort() {
            var result = [pagingParams.predicate + ',' + (pagingParams.ascending ? 'asc' : 'desc')];
            if (pagingParams.predicate !== 'name') {
                result.push('name');
            }
            return result;
        }

        var unsubscribeSchUpd = $rootScope.$on('supplyApp:schoolUpdate', function(event, result) {
            vm.school = result;
        });
        var unsubscribeСlzzAdd = $rootScope.$on('supplyApp:clazzCreate', function(event, result) {
            $timeout(function (){
                $state.reload();
            });
        });

        $scope.$on('$destroy', unsubscribeSchUpd);
        $scope.$on('$destroy', unsubscribeСlzzAdd);
    }
})();
