(function () {
    'use strict';

    angular
        .module('supplyApp')
        .service('AutoimportSettingsService', AutoimportSettingsService);

    AutoimportSettingsService.$inject = ['$uibModal', 'RegionSettings'];

    function AutoimportSettingsService($uibModal, RegionSettings) {
        var service = {
            openModal: openModal,
            getFtpSettings: getFtpSettings,
            update: update
        };

        var modalInstance = null;

        return service;

        function getFtpSettings(regionId, onSuccess, onError) {
            RegionSettings.getRegionSettings({confKey:'FTP_SETTINGS',value: regionId}, onSuccess, onError);
        }

        function update(settingsData, onSuccess, onError) {
            RegionSettings.update(prepareSaveModel(settingsData), onSuccess, onError);
        }

        function prepareSaveModel(settings) {
            var saveModel = angular.copy(settings);
            saveModel.value = angular.toJson(settings.value);
            return saveModel;
        }

        function openModal() {
            modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'app/ui/import/auto/autoimport-settings-modal.html',
                controller: 'AutoimportSettingsController',
                controllerAs: 'vm',
                size: 'sm',
                resolve: {
                    account: ['Principal', function (Principal) {
                        return Principal.identity(true);
                    }]
                }
            });
            modalInstance.result.then(resetModal, resetModal);
        }

        function resetModal() {
            modalInstance = null;
        }
    }
})();
