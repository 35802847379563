(function () {
    'use strict';

    angular
        .module('supplyApp.common')
        .service('IncomesModelService', IncomesModelService);

    IncomesModelService.$inject = ['$http', 'Income'];

    function IncomesModelService($http, Income) {
        return {
            buildTab: buildTabRows,
            parseIncome: parseIncome,
            getTotalIncomes: getTotalIncomes,
            getSchoolIncomes: getSchoolIncomes,
            bindIncomeView: bindIncomeView
        };

        function buildTabRows(type, rowDefs) {
            var rows = [];
            rowDefs.sort(sortById).forEach(function (rowDef) {
                rows.push({title: rowDef.name, rowDef: rowDef.id, num: 0, price: 0, summary: 0})
            });

            return rows;
        }

        function sortById(first, second) {
            return first.id - second.id;
        }

        function bindIncomeView(tabsModel, income) {
            income.details.forEach(function (detail) {
                fillIncomeDetail(tabsModel, detail);
            });
        }

        function fillIncomeDetail(tabsModel, incomeDetail) {
            tabsModel.some(function (tab) {
                var tabHasRow = false;
                if (tab.type == incomeDetail.type) {
                    tabHasRow = fillRow(tab.rows, incomeDetail.name, incomeDetail.count, incomeDetail.amount);
                }
                return tab.type == incomeDetail.type && tabHasRow;
            })
        }

        function fillRow(rows, name, count, amount) {
            return rows.some(function (row) {
                if (row.title == name) {
                    row.num = count;
                    row.summary = amount;
                    row.price = amount / count;
                }
                return row.title == name;
            });
        }

        function getSchoolIncomes(schoolId, month) {
            return $http.get('/incomes/' + schoolId + '/' + month);
        }

        function getTotalIncomes(month, year) {
            return $http.get('/api/incomes/totals/' + year + '/' + month);
        }

        function parseIncome(source, tabs, school) {
            var income = {
                id: source.id,
                cache: 0,
                nonCache: 0,
                erip: 0,
                subsidy: source.subsidy,
                cachebox: source.cachebox,
                school: {id: school.id},
                details: [],
                date: source.date
            };

            tabs.forEach(function (tab) {
                tab.rows.forEach(function (row) {
                    if (!!row.num || !!row.summary) {
                        income.details.push({
                            type: tab.type,
                            rowDef: row.rowDef,
                            count: row.num,
                            amount: row.summary,
                        });
                    }
                });
            });

            return income;
        }
    }
})();
