(function () {
    'use strict';

    angular
        .module('supplyApp.common')
        .factory('PassGen', PassGen);

    PassGen.$inject = ['$resource'];

    function PassGen ($resource) {
        var fund = 'abcdefghijklmnoprstuvwxyz0123456789@#$';

        var service = {
            next : function(num) {
                var result = "";
                for (var i=0; i<num; i++) {
                    result += fund.charAt(Math.floor(Math.random() * fund.length));
                }
                return result;
            }
        };
        return service;
    }
})();
