(function () {
    'use strict';

    angular
        .module('supplyApp.common')
        .directive('incomesInputForm', incomesInputForm);

    function incomesInputForm() {
        return {
            restrict: 'E',
            scope: {
                rows: '<',
                form: '=',
                income: '=',
                tabTitle: '@',
                isEmpty: '='
            },
            replace: true,
            templateUrl: 'app/common/components/form/incomes-form.html',
            link: function (scope) {
                calculateSummary();

                scope.$watch('rows', function (newValues, oldValues) {
                    if (!angular.equals(newValues, oldValues)) {
                        calculateSummary();
                    }
                }, true);

                function presize(num) {
                    return Math.round(num * 100) / 100;
                }

                function calculateSummary() {
                    scope.result = 0;

                    scope.rows.forEach(function (row) {
                        scope.result += row.summary;
                    });

                    scope.result = presize(scope.result);
                    scope.isEmpty = scope.result == 0;
                }
            }
        };
    }
})();
