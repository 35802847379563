(function() {
    'use strict';

    angular
        .module('supplyApp')
        .controller('AddClazzToSchoolDialogController', AddClazzToSchoolDialogController);

    AddClazzToSchoolDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'Principal', 'newClazz', 'Clazz', 'School'];

    function AddClazzToSchoolDialogController ($timeout, $scope, $stateParams, $uibModalInstance, Principal, newClazz, Clazz, School) {
        var vm = this;

        vm.clazz = newClazz;
        vm.clear = clear;
        vm.save = save;
        vm.users = [];
        vm.canEditSchool = false;

        if (!vm.clazz.school) {
            throw "No school is defined for new clazz";
        }

        vm.users = School.teachers({id:vm.clazz.school.id});

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.clazz.id == null) {
                Clazz.save(vm.clazz, onSaveSuccess, onSaveError);
            } else {
                throw "Clazz should not have ID";
            }
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            $scope.$emit('supplyApp:clazzCreate', result);
            $uibModalInstance.close(result);
        }

        function onSaveError () {
            vm.isSaving = false;
        }

    }
})();
